import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
} from "@chakra-ui/react";
import { HiOutlineSearch } from "react-icons/hi";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { GoX } from "react-icons/go";

import { useIsAdmin } from "src/common/useIsAdmin";

export const UnitListFilterBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const { propertyOrganizationId } = useParams();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [name, setName] = useState<string>(searchParams.get("name") || "");

  const onSubmit = useCallback(
    (newSp: URLSearchParams) => {
      newSp.delete("pageNumber");
      searchParams.sort();
      newSp.sort();

      if (searchParams.toString() !== newSp.toString()) {
        navigate(`?${newSp.toString()}`);
      }
    },
    [searchParams]
  );

  const handleSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      const newSp = new URLSearchParams(location.search);

      if (name) {
        newSp.set("name", name);
      } else {
        newSp.delete("name");
      }

      onSubmit(newSp);
    },
    [location.search, name, searchParams]
  );

  const clearSearch = () => {
    const newSp = new URLSearchParams(location.search);
    newSp.delete("name");
    setName("");

    onSubmit(newSp);
  };

  return (
    <Flex flexDirection="row">
      <form onSubmit={handleSubmit}>
        <InputGroup width={503} marginRight="16px">
          <InputLeftElement pointerEvents="none" color="gray.300">
            <HiOutlineSearch />
          </InputLeftElement>
          <Input
            value={name}
            onChange={({ currentTarget: { value } }) => setName(value)}
            onBlur={handleSubmit}
            placeholder="Search by Unit or Unit Group Name..."
          />
          <InputRightElement>
            <Button variant="ghost" onClick={clearSearch}>
              <GoX />
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
      {isAdmin ? (
        <ButtonGroup gap="8px">
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units/add`}
          >
            <Button colorScheme="brand.blueGreen">Add Unit</Button>
          </Link>

          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units/unit-groups/add`}
          >
            <Button colorScheme="brand.blue">Add Unit Group</Button>
          </Link>
        </ButtonGroup>
      ) : null}
    </Flex>
  );
};
