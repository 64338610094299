import React, { useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Outlet } from "react-router-dom";

import { GateHawkUser } from "src/common/types";
import { Loading } from "src/common/Loading";
import { useKy } from "src/common/ky";

export const GATEHAWK_USER_QUERY_KEY = "GATEHAWK_USER";
export const GateHawkUserLoader = () => {
  const ky = useKy();
  const query = useQuery(
    GATEHAWK_USER_QUERY_KEY,
    () => ky.get(`staff-users/self`).json<GateHawkUser | false>(),
    { staleTime: Infinity }
  );

  if (query.isLoading || !query.isSuccess) {
    return <Loading />;
  }

  return <Outlet />;
};

export const useGateHawkUserRole = () => {
  const queryClient = useQueryClient();

  const gateHawkUser = queryClient.getQueryData(GATEHAWK_USER_QUERY_KEY) as
    | GateHawkUser
    | false;

  return useMemo(() => {
    return gateHawkUser ? gateHawkUser.role : null;
  }, []);
};
