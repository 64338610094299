import React, { FC } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  CloseButton,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Link,
  NumberInput,
  NumberInputField,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { Wrapper } from "@googlemaps/react-wrapper";
import useGeolocation from "react-hook-geolocation";
import { pick } from "lodash";

import { MapMarker } from "src/common/MapMarker";
import { IntercomActivationUpdateDefaultMobileIntercomSchema } from "src/routes/IntercomUnits/schema";
import { HTTPError, useKy } from "src/common/ky";
import { Loading } from "src/common/Loading";
import { noop } from "src/common/util";
import { IntercomActivation } from "src/common/types";
import { getProductActivationsQueryKey } from "src/common/queries";
import config from "src/common/config";
import { ViewIntercomUnitLayout } from "src/routes/IntercomUnits/ViewIntercomUnit/ViewIntercomUnitLayout";
import { handleHookFormHTTPError } from "src/common/form";

type UpdateDefaultMobileIntercomValues = Pick<
  IntercomActivation,
  keyof typeof IntercomActivationUpdateDefaultMobileIntercomSchema
>;

const EditDefaultMobileIntercomToPropertySchema = Yup.object().shape(
  IntercomActivationUpdateDefaultMobileIntercomSchema
);

/**
 * This component handles editing of the default mobile intercom (virtual intercom) settings.
 */
export const EditDefaultMobileIntercom: FC = () => {
  const { activationId, propertyOrganizationId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const position = useGeolocation({
    enableHighAccuracy: true,
    maximumAge: 15000,
    timeout: 12000,
  });

  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();
  const ky = useKy();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, isValid },
    setError,
    getValues,
    watch,
  } = useForm<UpdateDefaultMobileIntercomValues>({
    defaultValues: pick(currentActivation, [
      "lat",
      "long",
      "enableDMI",
      "enableDMIUA",
      "locationThresholdFeet",
    ]),
    resolver: yupResolver(EditDefaultMobileIntercomToPropertySchema),
  });

  const updateIntercom = useMutation<
    void,
    HTTPError,
    UpdateDefaultMobileIntercomValues
  >(
    async (values: UpdateDefaultMobileIntercomValues) => {
      await ky.patch(
        `product-unit-activations/${activationId}/default-mobile-intercom`,
        {
          json: values,
        }
      );
    },
    {
      onError: handleHookFormHTTPError(setError, getValues, toast),
      onSuccess: async () => {
        navigate(
          `/property-organizations/${propertyOrganizationId}/intercom-units`
        );
        await queryClient.invalidateQueries(
          getProductActivationsQueryKey(propertyOrganizationId)
        );
        toast({
          description: "Virtual Intercom settings successfully updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const [dmiEnabled, lat, long, locationThresholdFeet] = watch([
    "enableDMI",
    "lat",
    "long",
    "locationThresholdFeet",
  ]);

  if (!currentActivation) {
    return <Loading />;
  }

  return (
    <ViewIntercomUnitLayout
      title="Virtual Intercom"
      subtitle="Adjust settings related to the virtual intercom"
      additionalContent={
        isSubmitted && !isValid ? (
          <Container maxW="7xl">
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                Please fix any errors in the form and try again.
              </AlertDescription>
              <CloseButton position="absolute" right="8px" top="8px" />
            </Alert>
          </Container>
        ) : undefined
      }
    >
      <form
        onSubmit={handleSubmit((values) =>
          updateIntercom.mutateAsync(values).catch(noop)
        )}
      >
        <Grid templateColumns={"repeat(4 1fr)"} gap={6}>
          <GridItem colSpan={4}>
            <Flex flexDirection="row" flexWrap="wrap" alignItems="start">
              <Flex flexDirection="column" gap={4} flexBasis="40%">
                <Controller
                  name="enableDMI"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <Flex flexDirection="row" gap={2}>
                        <FormLabel htmlFor="enableDMI" mb="0">
                          Enable Virtual Intercom
                        </FormLabel>
                        <Switch
                          id="enableDMI"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                      </Flex>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                      {!dmiEnabled && (
                        <FormHelperText sx={{ gridColumn: "2 span" }}>
                          Users will no longer be able to view the directory or
                          make calls through the Virtual Intercom.
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  name="enableDMIUA"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <Flex flexDirection="row" gap={2}>
                        <FormLabel htmlFor="enableDMIUA" mb="0">
                          Enable Mobile Device Check
                        </FormLabel>
                        <Switch
                          isDisabled={!dmiEnabled}
                          id="enableDMIUA"
                          isChecked={field.value}
                          onChange={field.onChange}
                        />
                      </Flex>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                      <FormHelperText sx={{ gridColumn: "2 span" }}>
                        This check increases security by only allowing mobile
                        device users to access the Virtual Intercom.
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Controller
                  name="lat"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="lat" mb="0">
                        Latitude
                      </FormLabel>
                      <NumberInput
                        {...field}
                        value={field.value === null ? "" : field.value}
                        min={-90}
                        max={90}
                        id="lat"
                      >
                        <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
                      </NumberInput>
                      {position?.latitude && (
                        <Text as="i">Your latitude: {position?.latitude}</Text>
                      )}
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                      <FormHelperText>
                        {
                          "Please input this entry point's exact location associated to this Virtual Intercom."
                        }
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <Controller
                  name="long"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="long" mb="0">
                        Longitude
                      </FormLabel>
                      <NumberInput
                        {...field}
                        value={field.value === null ? "" : field.value}
                        min={-180}
                        max={180}
                        id="long"
                      >
                        <NumberInputField pattern="(-)?[0-9]*(.[0-9]+)?" />
                      </NumberInput>
                      {position?.longitude && (
                        <Text as="i">
                          Your longitude: {position?.longitude}
                        </Text>
                      )}
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="locationThresholdFeet"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="locationThresholdFeet" mb="0">
                        Maximum Distance from Intercom
                      </FormLabel>
                      <NumberInput {...field} id="locationThresholdFeet">
                        <NumberInputField />
                      </NumberInput>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                      <FormHelperText>
                        Please set the radius (in feet) that the user has to be
                        within in order to use the Virtual Intercom. Recommended
                        distance 30ft.
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Flex>
              <Box
                height={300}
                width={300}
                marginLeft="auto"
                marginRight="auto"
              >
                <Wrapper apiKey={config.googleMapsKey}>
                  <MapMarker
                    zoom={18}
                    lat={lat}
                    long={long}
                    radius={locationThresholdFeet}
                  />
                </Wrapper>
              </Box>
            </Flex>
          </GridItem>

          <GridItem />
          <GridItem />
          <GridItem />

          <GridItem colSpan={4}>
            <Divider />
          </GridItem>

          <GridItem colSpan={4}>
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to={`/property-organizations/${propertyOrganizationId}/intercom-units`}
              >
                <Button>Cancel</Button>
              </Link>
              <Button
                type="submit"
                colorScheme="brand.blue"
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </ButtonGroup>
          </GridItem>
        </Grid>
      </form>
    </ViewIntercomUnitLayout>
  );
};
