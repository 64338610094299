import React, { useMemo } from "react";
import * as Yup from "yup";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { intersection } from "lodash";

import { AccessProfile, AccessProfileDMPProperties } from "src/common/types";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import {
  useGetAccessProfileQuery,
  useGetEntryGroupsQuery,
  useGetEntrySchedulesQuery,
} from "src/routes/AccessControl/queries";
import { useUpdateAccessProfile } from "src/routes/AccessControl/hooks";
import { Loading } from "src/common/Loading";
import { PageContent } from "src/layout/PageContent";
import { noop } from "src/common/util";

export interface UpdateAccessProfileValues {
  displayName: string;
  entryGroupIds: string[];
  entryScheduleIds: string[];
  dmpProperties: AccessProfileDMPProperties | undefined;
}

export const EditAccessProfileValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  entryGroupIds: Yup.array().of(Yup.string().required().label("Entry Groups")),
  entryScheduleIds: Yup.array().of(
    Yup.string().required().label("Entry Schedules")
  ),
});

export const EditProfile = () => {
  const { propertyOrganizationId } = useParams();
  const entryGroupsQuery = useGetEntryGroupsQuery();
  const entrySchedulesQuery = useGetEntrySchedulesQuery();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
    setError,
    getValues,
  } = useForm<UpdateAccessProfileValues>({
    defaultValues: {
      displayName: "",
      entryGroupIds: [],
      entryScheduleIds: [],
      dmpProperties: {
        sensorReset: false,
      },
    },
    resolver: yupResolver(EditAccessProfileValidationSchema),
  });

  const validEntryGroupIds = useMemo(
    () => (entryGroupsQuery.data ?? []).map((eg) => eg.entryGroupId),
    [entryGroupsQuery.data]
  );
  const validEntryScheduleIds = useMemo(
    () => (entrySchedulesQuery.data ?? []).map((eg) => eg.entryScheduleId),
    [entrySchedulesQuery.data]
  );

  const getAccessProfileQuery = useGetAccessProfileQuery(
    (accessProfile: AccessProfile) => {
      if (!isDirty) {
        reset({
          displayName: accessProfile.displayName || "",
          entryGroupIds: accessProfile.entryGroupIds || [],
          entryScheduleIds: accessProfile.entryScheduleIds || [],
          dmpProperties: accessProfile.dmpProperties ?? { sensorReset: false },
        });
      }
    }
  );

  const { updateAccessProfileMutation } = useUpdateAccessProfile(
    setError,
    getValues
  );

  if (
    getAccessProfileQuery.isLoading ||
    !getAccessProfileQuery.isSuccess ||
    entryGroupsQuery.isLoading ||
    !entryGroupsQuery.isSuccess ||
    entrySchedulesQuery.isLoading ||
    !entrySchedulesQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/access-control`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Access Profile</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter access profile details below
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            updateAccessProfileMutation.mutateAsync(data).catch(noop)
          )}
        >
          <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
            <GridItem>
              <Controller
                name="displayName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="displayName">Display Name</FormLabel>
                    <Input
                      {...field}
                      id="displayName"
                      placeholder="Display Name"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
            <GridItem>
              <Controller
                name={"dmpProperties.sensorReset"}
                control={control}
                render={({ field: { value, ...field }, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <Flex alignItems={"center"}>
                      <FormLabel htmlFor="fireDoorRelease" mb={0}>
                        Fire Door Release
                      </FormLabel>
                      <Switch
                        isChecked={value}
                        {...field}
                        id="fireDoorRelease"
                      />
                    </Flex>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <Controller
                name={"entryGroupIds"}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="entryGroupIds">Entry Groups</FormLabel>
                    <Stack spacing={5}>
                      {entryGroupsQuery.data?.length ? (
                        <CheckboxGroup value={field.value}>
                          {entryGroupsQuery.data.map((entryGroup) => (
                            <Checkbox
                              key={entryGroup.entryGroupId}
                              value={entryGroup.entryGroupId}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const value = event.target.value;

                                field.onChange(
                                  isChecked
                                    ? intersection(validEntryGroupIds, [
                                        ...field.value,
                                        value,
                                      ])
                                    : intersection(
                                        validEntryGroupIds,
                                        field.value
                                      ).filter((id) => id !== value)
                                );
                              }}
                            >
                              {entryGroup.displayName}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      ) : (
                        <Text>No Entry Groups</Text>
                      )}
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </Stack>
                  </FormControl>
                )}
              />
            </GridItem>
            <GridItem>
              <Divider />
            </GridItem>
            <GridItem>
              <Controller
                name={"entryScheduleIds"}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="entryScheduleIds">
                      Entry Schedules
                    </FormLabel>
                    <Stack spacing={5}>
                      {entrySchedulesQuery.data?.length ? (
                        <CheckboxGroup value={field.value}>
                          {entrySchedulesQuery.data.map((entrySchedule) => (
                            <Checkbox
                              key={entrySchedule.entryScheduleId}
                              value={entrySchedule.entryScheduleId}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const value = event.target.value;

                                field.onChange(
                                  isChecked
                                    ? intersection(validEntryScheduleIds, [
                                        ...field.value,
                                        value,
                                      ])
                                    : intersection(
                                        validEntryScheduleIds,
                                        field.value
                                      ).filter((id) => id !== value)
                                );
                              }}
                            >
                              {entrySchedule.displayName}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      ) : (
                        <Text>No Entry Schedules</Text>
                      )}
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </Stack>
                  </FormControl>
                )}
              />
            </GridItem>
          </Grid>
          <Grid gap={4} mt={4}>
            <GridItem>
              <Divider />
            </GridItem>
            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/property-organizations/${propertyOrganizationId}/access-control`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  colorScheme={"brand.blue"}
                >
                  Save
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};
