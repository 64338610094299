import React from "react";
import { Divider, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { PageContent } from "src/layout/PageContent";
import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { useCurrentPropertyOrganization } from "src/common/queries";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const Dealer = () => {
  const { propertyOrganizationId } = useParams();

  const currentPropertyOrganization = useCurrentPropertyOrganization(
    propertyOrganizationId as string
  );

  if (!currentPropertyOrganization) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Dealer Organization Details</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Company Information</Heading>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <GridItem>
            <Text>Company Name</Text>
          </GridItem>
          <GridItem>
            <Text>
              {currentPropertyOrganization.dealerOrganization?.displayName ||
                "-"}
            </Text>
          </GridItem>

          <GridItem>
            <Text>Company Phone Number</Text>
          </GridItem>
          <GridItem>
            <Text>
              {formatPhoneNumber(
                currentPropertyOrganization.dealerOrganization?.phone
              )}
            </Text>
          </GridItem>

          <GridItem>
            <Text>Company Website</Text>
          </GridItem>
          <GridItem>
            <Text>
              {currentPropertyOrganization.dealerOrganization?.website || "-"}
            </Text>
          </GridItem>
        </Grid>
      </PageContent>
    </>
  );
};
