import React, { useCallback, useMemo } from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
  useBoolean,
  Box,
  Select,
} from "@chakra-ui/react";
import { HiOutlineFilter } from "react-icons/hi";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

import { useProductActivationsQuery } from "src/common/queries";

interface AccessLogsFilterPopoverProps {
  onSubmit(searchParams: URLSearchParams): void;
}

type FilterFormValues = {
  endTime: string;
  startTime: string;
  intercom: string;
};

export const AccessLogsFilterPopover = ({
  onSubmit,
}: AccessLogsFilterPopoverProps) => {
  const [isEditing, setIsEditing] = useBoolean();
  const location = useLocation();
  const sp = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const { control, handleSubmit } = useForm<FilterFormValues>({
    defaultValues: {
      startTime: sp.get("startTime")
        ? format(new Date(sp.get("startTime") || ""), "yyyy-MM-dd'T'HH:mm")
        : "",
      endTime: sp.get("endTime")
        ? format(new Date(sp.get("endTime") || ""), "yyyy-MM-dd'T'HH:mm")
        : "",
      intercom: sp.get("intercom") || "",
    },
  });

  const parseParameters = useCallback(
    (data: FilterFormValues) => {
      const newSp = new URLSearchParams(location.search);

      for (const [key, value] of Object.entries(data)) {
        if (value) {
          let queryValue = value;

          if (["endTime", "startTime"].includes(key)) {
            queryValue = new Date(value).toISOString();
          }

          newSp.set(key, queryValue as string);
        } else {
          newSp.delete(key);
        }
      }

      onSubmit(newSp);
      setIsEditing.off();
    },
    [location.search]
  );

  const intercomsQuery = useProductActivationsQuery();

  return (
    <Popover
      isOpen={isEditing}
      onOpen={setIsEditing.on}
      onClose={setIsEditing.off}
      closeOnBlur={false}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>
        <Button leftIcon={<HiOutlineFilter />}>Filters</Button>
      </PopoverTrigger>

      <PopoverContent display="flex">
        <PopoverHeader>Filters</PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          <form onSubmit={handleSubmit(parseParameters)}>
            <VStack align="start" gap="6">
              <Box width="100%">
                <Controller
                  name="startTime"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="startTime">Start Time</FormLabel>
                      <Input {...field} id="startTime" type="datetime-local" />
                    </FormControl>
                  )}
                />
              </Box>

              <Box width="100%">
                <Controller
                  name="endTime"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="endTime">End Time</FormLabel>
                      <Input {...field} id="endTime" type="datetime-local" />
                    </FormControl>
                  )}
                />
              </Box>

              <Box width="100%">
                <Controller
                  name="intercom"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel htmlFor="intercom">Intercom</FormLabel>
                      <Select
                        {...field}
                        id="intercomModelId"
                        isDisabled={intercomsQuery.isLoading}
                      >
                        <option value=""></option>
                        {intercomsQuery.data?.map((activation) => (
                          <option
                            value={activation.name}
                            key={activation.intercomActivationId}
                          >
                            {activation.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>

              <Box width="100%">
                <ButtonGroup display="flex" justifyContent="flex-end">
                  <Button type="button" onClick={setIsEditing.off}>
                    Cancel
                  </Button>
                  <Button type="submit" colorScheme="brand.blue">
                    Apply Filters
                  </Button>
                </ButtonGroup>
              </Box>
            </VStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
