import React from "react";
import { Button, Text } from "@chakra-ui/react";

import { EntryScheduleDayOfWeek } from "src/common/types";
import { ENTRY_SCHEDULE_DAYS_OF_WEEK_NAMES } from "src/routes/AccessControl/constants";

type DayButtonProps = {
  isActive: boolean;
  dayOfWeek: EntryScheduleDayOfWeek;
  onClick: () => void;
};

export const DayButton = ({ isActive, dayOfWeek, onClick }: DayButtonProps) => {
  return (
    <Button
      colorScheme="brand.blue"
      variant={isActive ? "solid" : "outline"}
      h="56px"
      w="56px"
      borderRadius="50%"
      borderWidth="2px"
      onClick={onClick}
    >
      <Text fontWeight={700} textTransform="uppercase">
        {ENTRY_SCHEDULE_DAYS_OF_WEEK_NAMES[dayOfWeek].slice(0, 3)}
      </Text>
    </Button>
  );
};
