import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { HiOutlineSearch } from "react-icons/hi";
import { GoX } from "react-icons/go";

import { TenantGuestsListFilterPopover } from "src/routes/TenantGuestsList/TenantGuestsListFilterPopover";

export const TenantGuestsListFilterBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [tenantOrGuestName, setTenantOrGuestName] = useState<string>(
    searchParams.get("tenantOrGuestName") || ""
  );

  const onSubmit = useCallback(
    (newSp: URLSearchParams) => {
      newSp.delete("pageNumber");
      searchParams.sort();
      newSp.sort();

      if (searchParams.toString() !== newSp.toString()) {
        navigate(`?${newSp.toString()}`);
      }
    },
    [searchParams]
  );

  const handleSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      const newSp = new URLSearchParams(location.search);

      if (tenantOrGuestName) {
        newSp.set("tenantOrGuestName", tenantOrGuestName);
      } else {
        newSp.delete("tenantOrGuestName");
      }

      onSubmit(newSp);
    },
    [location.search, tenantOrGuestName, searchParams]
  );

  const clearSearch = () => {
    const newSp = new URLSearchParams(location.search);
    newSp.delete("tenantOrGuestName");
    setTenantOrGuestName("");

    onSubmit(newSp);
  };

  return (
    <Flex flexDirection="row">
      <form onSubmit={handleSubmit}>
        <InputGroup width={503} marginRight="16px">
          <InputLeftElement pointerEvents="none" color="gray.300">
            <HiOutlineSearch />
          </InputLeftElement>
          <Input
            value={tenantOrGuestName}
            onChange={({ currentTarget: { value } }) =>
              setTenantOrGuestName(value)
            }
            onBlur={handleSubmit}
            placeholder="Search by Tenant or Guest name..."
          />
          <InputRightElement>
            <Button variant="ghost" onClick={clearSearch}>
              <GoX />
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
      <ButtonGroup gap="8px">
        <TenantGuestsListFilterPopover onSubmit={onSubmit} />
      </ButtonGroup>
    </Flex>
  );
};
