import React from "react";
import { Divider, Grid, GridItem, Heading, Text } from "@chakra-ui/react";

import { PageContent } from "src/layout/PageContent";
import { PropertyOrganization } from "src/common/types";
import { formatPhoneNumber } from "src/common/phone-numbers";

type Props = {
  propertyOrganization: PropertyOrganization;
};
export const PropertyOwnerContactDetails = ({
  propertyOrganization,
}: Props) => {
  return (
    <PageContent>
      <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
        <GridItem colSpan={2}>
          <Heading size="md">Property Owner Contact Details</Heading>
        </GridItem>

        <GridItem colSpan={2}>
          <Divider />
        </GridItem>

        <GridItem>
          <Text>Property Owner First Name</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.ownerFirstName}</Text>
        </GridItem>

        <GridItem>
          <Text>Property Owner Last Name</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.ownerLastName}</Text>
        </GridItem>

        <GridItem>
          <Text>Property Owner Billing Address</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.ownerAddress}</Text>
        </GridItem>

        <GridItem>
          <Text>Property Owner City</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.ownerCity}</Text>
        </GridItem>

        <GridItem>
          <Text>Property Owner State</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.ownerState}</Text>
        </GridItem>

        <GridItem>
          <Text>Property Owner Zip Code</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.ownerZip}</Text>
        </GridItem>

        <GridItem>
          <Text>Property Owner Email</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.ownerEmail}</Text>
        </GridItem>

        <GridItem>
          <Text>Property Owner Phone</Text>
        </GridItem>
        <GridItem>
          <Text>{formatPhoneNumber(propertyOrganization.ownerPhone)}</Text>
        </GridItem>
      </Grid>
    </PageContent>
  );
};
