import {
  FieldValues,
  UseFormGetValues,
  UseFormSetError,
} from "react-hook-form";
import { CreateToastFnReturn } from "@chakra-ui/react";
import { HTTPError } from "ky";
import { has } from "lodash";

import { ValidationError } from "src/common/types";

export async function categorizeHookFormValidationErrors<
  TFieldValues extends FieldValues = FieldValues
>(
  validationErrors: ValidationError<TFieldValues>[],
  getValues: UseFormGetValues<TFieldValues>
) {
  const values = getValues();
  return validationErrors.reduce(
    (errors, error) => {
      if (has(values, error.property)) {
        errors.formValidationErrors.push(error);
      } else {
        errors.otherValidationErrors.push(error);
      }

      return errors;
    },
    {
      formValidationErrors: [] as ValidationError<TFieldValues>[],
      otherValidationErrors: [] as ValidationError<TFieldValues>[],
    }
  );
}

export function handleHookFormHTTPError<
  TFieldValues extends FieldValues = FieldValues
>(
  setError: UseFormSetError<TFieldValues>,
  getValues: UseFormGetValues<TFieldValues>,
  toast: CreateToastFnReturn
) {
  return async (error: HTTPError) => {
    const {
      validationErrors,
      message,
    }: {
      message?: string;
      validationErrors?: ValidationError<TFieldValues>[];
    } = await error.response.json();

    if (error.response.status < 500 && validationErrors?.length) {
      const { formValidationErrors, otherValidationErrors } =
        await categorizeHookFormValidationErrors<TFieldValues>(
          validationErrors ?? [],
          getValues
        );

      formValidationErrors.forEach((error) =>
        error.messages.forEach((message) =>
          setError(
            error.property as Parameters<UseFormSetError<TFieldValues>>[0],
            { message }
          )
        )
      );

      if (otherValidationErrors.length) {
        toast({
          title: "Validation error",
          containerStyle: {
            whiteSpace: "pre",
          },
          description: otherValidationErrors
            .flatMap((e) => e.messages)
            .join(`\n`),
          status: "error",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Something went wrong",
        description: message ?? "Please try again.",
        status: "error",
        isClosable: true,
      });
    }
  };
}
