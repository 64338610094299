import React, { useMemo } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { HiEye, HiEyeOff, HiOutlineDotsVertical } from "react-icons/hi";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { Loading } from "src/common/Loading";
import {
  useGetTenantQuery,
  useGetTenantVirtualAccessCredentialQuery,
} from "src/routes/ViewTenant/queries";
import { Tenant } from "src/common/types";
import { HiddenAccessCode } from "src/routes/ViewTenant/HiddenAccessCode";
import { ResetPassword } from "src/common/icons/ResetPassword";
import { useResetTenantVirtualAccessCredential } from "src/routes/ViewTenant/hooks";
import { useGetAccessProfilesQuery } from "src/routes/AccessProfile/queries";
import { useFlags } from "src/common/useFlags";
import { useHasAccessControl } from "src/common/useHasAccessControl";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const ViewTenantPageContent = ({
  tenant,
  onResetAccessCode,
}: {
  tenant: Tenant;
  onResetAccessCode: () => void;
}) => {
  const [isViewingAccessCode, setIsViewingAccessCode] = React.useState(false);
  const accessCredentialQuery =
    useGetTenantVirtualAccessCredentialQuery(isViewingAccessCode);
  const flags = useFlags();
  const { propertyOrganizationId } = useParams();
  const getAccessProfilesQuery = flags.accessControl
    ? useGetAccessProfilesQuery()
    : null;
  const hasAccessControl = useHasAccessControl(propertyOrganizationId);

  if (flags.accessControl) {
    if (
      getAccessProfilesQuery?.isLoading ||
      !getAccessProfilesQuery?.isSuccess
    ) {
      return <Loading />;
    }
  }

  return (
    <Grid templateColumns={"1fr 2fr"} gap={4}>
      <GridItem>
        <Text>First Name</Text>
      </GridItem>
      <GridItem>
        <Text fontWeight={600}>{tenant.user.firstName}</Text>
      </GridItem>

      <GridItem>
        <Text>Last Name</Text>
      </GridItem>
      <GridItem>
        <Text fontWeight={600}>{tenant.user.lastName}</Text>
      </GridItem>

      <GridItem>
        <Text>Phone Number</Text>
      </GridItem>
      <GridItem>
        <Text fontWeight={600}>{formatPhoneNumber(tenant.user.phone)}</Text>
      </GridItem>

      <GridItem>
        <Text>Email</Text>
      </GridItem>
      <GridItem>
        <Text fontWeight={600}>{tenant.user.email}</Text>
      </GridItem>

      <GridItem>
        <Text>Access Code</Text>
      </GridItem>
      <GridItem>
        <Box
          display="flex"
          flex={1}
          alignItems={"center"}
          justifyContent="space-between"
        >
          {isViewingAccessCode &&
          accessCredentialQuery.isSuccess &&
          !accessCredentialQuery.isLoading ? (
            <Text fontWeight={600}>{accessCredentialQuery.data.code}</Text>
          ) : (
            <HiddenAccessCode />
          )}
          <Flex alignItems="center" justifyContent="flex-end">
            <Button
              aria-label={`${
                isViewingAccessCode ? "Hide" : "View"
              } Access Code`}
              size="xs"
              onClick={() => setIsViewingAccessCode(!isViewingAccessCode)}
              leftIcon={isViewingAccessCode ? <HiEyeOff /> : <HiEye />}
            >
              {isViewingAccessCode ? "Hide" : "View"} Access Code
            </Button>
            <Menu>
              <MenuButton
                as={IconButton}
                height={"100%"}
                aria-label="Actions"
                icon={<HiOutlineDotsVertical />}
                variant="clear"
              >
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem
                  justifyContent="space-between"
                  onClick={onResetAccessCode}
                >
                  Reset Access Code
                  <ResetPassword />
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
      </GridItem>
      {flags.accessControl && hasAccessControl ? (
        <>
          <GridItem>
            <Text>Access Profile</Text>
          </GridItem>
          <GridItem>
            {tenant.accessProfileIds?.length &&
            getAccessProfilesQuery?.data?.length ? (
              getAccessProfilesQuery.data
                .filter((accessProfile) =>
                  tenant.accessProfileIds.includes(
                    accessProfile.accessProfileId
                  )
                )
                .map((accessProfile) => (
                  <Text fontWeight={600} key={accessProfile.accessProfileId}>
                    {accessProfile.displayName}
                  </Text>
                ))
            ) : (
              <Text fontWeight={600}>None</Text>
            )}
          </GridItem>
        </>
      ) : null}
    </Grid>
  );
};

export const ViewTenant = () => {
  const { propertyOrganizationId, unitId } = useParams();

  const tenantQuery = useGetTenantQuery();

  const {
    isResettingVirtualAccessCredential,
    resetTenantVirtualAccessCredentialMutation,
    setResettingForTenantId,
  } = useResetTenantVirtualAccessCredential();

  const backUrl = useMemo(
    () =>
      `/property-organizations/${propertyOrganizationId}/units${
        unitId ? `/${unitId}` : `/tenants`
      }`,
    [propertyOrganizationId, unitId]
  );

  const content =
    tenantQuery.isLoading || !tenantQuery.isSuccess ? (
      <Loading />
    ) : (
      <ViewTenantPageContent
        tenant={tenantQuery.data}
        onResetAccessCode={() =>
          setResettingForTenantId(tenantQuery.data.tenantId)
        }
      />
    );

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link as={RouterLink} to={backUrl}>
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>
          {tenantQuery.isSuccess &&
            `${tenantQuery.data.user.firstName} ${tenantQuery.data.user.lastName}`}
        </PageHeaderTitle>
      </PageHeader>
      <PageContent>{content}</PageContent>
      <Modal
        isOpen={isResettingVirtualAccessCredential}
        onClose={() => {
          setResettingForTenantId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Access Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? This will immediately reset the tenant&apos;s access
            code and email them about the change.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setResettingForTenantId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() =>
                resetTenantVirtualAccessCredentialMutation.mutate()
              }
              isDisabled={resetTenantVirtualAccessCredentialMutation.isLoading}
            >
              {resetTenantVirtualAccessCredentialMutation.isLoading
                ? "Resetting..."
                : "Accept"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
