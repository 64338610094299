import React from "react";
import {
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { HiQuestionMarkCircle } from "react-icons/hi";

import theme from "src/common/theme";
import { PageContent } from "src/layout/PageContent";
import { PropertyOrganization } from "src/common/types";
import { formatPhoneNumber } from "src/common/phone-numbers";

type Props = {
  propertyOrganization: PropertyOrganization;
};
export const CompanyInformation = ({ propertyOrganization }: Props) => {
  return (
    <PageContent>
      <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
        <GridItem colSpan={2}>
          <Heading size="md">Company Information</Heading>
        </GridItem>

        <GridItem colSpan={2}>
          <Divider />
        </GridItem>

        <GridItem>
          <Text>Company Name</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.name}</Text>
        </GridItem>

        <GridItem>
          <Text>Street Address</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.address}</Text>
        </GridItem>

        <GridItem>
          <Text>City</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.city}</Text>
        </GridItem>

        <GridItem>
          <Text>State</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.state}</Text>
        </GridItem>

        <GridItem>
          <Text>ZIP Code</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.zip}</Text>
        </GridItem>

        <GridItem>
          <HStack>
            <Text>Phone Number</Text>
            <Tooltip
              label="If the “Call a Manager” feature is enabled on your intercom,
            incoming calls will be routed to this number."
            >
              <Center>
                <Icon
                  color={theme.colors.gray[500]}
                  as={HiQuestionMarkCircle}
                />
              </Center>
            </Tooltip>
          </HStack>
        </GridItem>
        <GridItem>
          <Text>{formatPhoneNumber(propertyOrganization.phone)}</Text>
        </GridItem>

        <GridItem>
          <Text>Email</Text>
        </GridItem>
        <GridItem>
          <Text>{propertyOrganization.email}</Text>
        </GridItem>
      </Grid>
    </PageContent>
  );
};
