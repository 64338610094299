import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";

import { isSupportedValue } from "src/common/util";
import { IntercomActivation, ScreenSize } from "src/common/types";
import { IntercomSplashScreenPreview } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview";
import useIntercomDetails from "src/routes/IntercomUnits/ViewIntercomUnit/useIntercomDetails";

type CurrentSplashScreenProps = {
  intercomActivation: IntercomActivation;
  onClick: () => void;
};

export const CurrentSplashScreen = ({
  intercomActivation,
  onClick,
}: CurrentSplashScreenProps) => {
  const { screenSize } = useIntercomDetails();

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="bold" fontSize="lg">
          Current Screen
        </Text>
        <Button
          colorScheme="brand.blue"
          isDisabled={!isSupportedValue(screenSize, ScreenSize)}
          onClick={onClick}
        >
          Edit Splash Screen
        </Button>
      </Flex>
      <Flex direction="column" alignItems="center" sx={{ py: 30 }}>
        <IntercomSplashScreenPreview
          screenSize={screenSize as ScreenSize}
          intercomActivation={intercomActivation}
        />
      </Flex>
    </>
  );
};
