import { Image, ImageProps } from "@chakra-ui/react";
import React from "react";

import { LogoBackground, ScreenSize } from "src/common/types";
import { config } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/previewConfig";

export const PreviewLogo = ({
  screenSize,
  logoUrl,
  logoBackground,
}: ImageProps & {
  screenSize: ScreenSize;
  logoUrl?: string | null;
  logoBackground: LogoBackground;
}) => {
  if (!logoUrl) {
    return null;
  }

  const { logoHeight, logoWidth, logoMargin } = config[screenSize];
  const backgroundColor =
    logoBackground !== LogoBackground.TRANSPARENT
      ? logoBackground.toLowerCase()
      : undefined;

  return (
    <Image
      position="absolute"
      src={logoUrl}
      height={logoHeight}
      width={logoWidth}
      backgroundColor={backgroundColor}
      alt="Company Logo"
      m={logoMargin}
      bottom="0"
      right="0"
    />
  );
};
