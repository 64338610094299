import * as Yup from "yup";

export const AddEntryScheduleValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  propertyOrganizationId: Yup.string()
    .required()
    .label("Property Organization"),
  schedule: Yup.array()
    .of(
      Yup.object({
        dayOfWeek: Yup.number().min(0).max(6).required().label("Day of Week"),
        startTime: Yup.string().required(),
        endTime: Yup.string().required(),
      })
    )
    .label("Entry Schedule"),
});

export const EditEntryScheduleValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  schedule: Yup.array()
    .of(
      Yup.object({
        dayOfWeek: Yup.number().min(0).max(6).required().label("Day of Week"),
        startTime: Yup.string().required(),
        endTime: Yup.string().required(),
      })
    )
    .label("Entry Schedule"),
});
