import React from "react";
import { Divider, Grid, GridItem, Heading, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { Loading } from "src/common/Loading";
import {
  useGetUnitQuery,
  useGetUnitUnitGroupQuery,
} from "src/routes/Unit/queries";
import { TenantList } from "src/routes/Unit/UnitDetail/TenantList";
import { UnitDetailFilterBar } from "src/routes/Unit/UnitDetail/UnitDetailFilterBar";
import { UnitGroupCard } from "src/routes/UnitGroups/UnitGroupCard";

export const UnitDetail = () => {
  const { propertyOrganizationId } = useParams();
  const query = useGetUnitQuery();
  const unitGroupQuery = useGetUnitUnitGroupQuery();

  if (!propertyOrganizationId) {
    return null;
  }

  if (
    !query.isSuccess ||
    query.isLoading ||
    !unitGroupQuery.isSuccess ||
    unitGroupQuery.isLoading
  ) {
    return <Loading />;
  }

  const unit = query.data;

  return (
    <>
      <PageHeader buttons={<UnitDetailFilterBar />}>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>{unit.unitName}</PageHeaderTitle>
        <PageHeaderSubtitle>View Unit and Tenants</PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <Grid templateColumns={"1fr 2fr"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Unit Group</Heading>
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <GridItem>
            {unitGroupQuery.data.unitGroup ? (
              <UnitGroupCard
                withMenu={false}
                unitGroup={unitGroupQuery.data.unitGroup}
              />
            ) : (
              <Text>No unit group</Text>
            )}
          </GridItem>
        </Grid>
      </PageContent>
      <PageContent>
        <TenantList />
      </PageContent>
    </>
  );
};
