import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { HTTPError } from "ky";
import { useMutation } from "react-query";
import { intersection } from "lodash";

import { AccessProfileDMPProperties } from "src/common/types";
import { useKy } from "src/common/ky";
import { PageContent } from "src/layout/PageContent";
import {
  useGetEntryGroupsQuery,
  useGetEntrySchedulesQuery,
} from "src/routes/AccessControl/queries";
import { handleHookFormHTTPError } from "src/common/form";
import { Loading } from "src/common/Loading";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { noop } from "src/common/util";

interface AddAccessProfileValues {
  displayName: string;
  propertyOrganizationId: string;
  entryGroupIds: string[];
  entryScheduleIds: string[];
  dmpProperties: AccessProfileDMPProperties | undefined;
}

const AddAccessProfileValidationSchema = Yup.object().shape({
  displayName: Yup.string().required().label("Display Name"),
  entryGroupIds: Yup.array().of(Yup.string().required().label("Entry Groups")),
  entryScheduleIds: Yup.array().of(
    Yup.string().required().label("Entry Schedules")
  ),
});

export const AddProfile = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const ky = useKy();
  const entryGroupsQuery = useGetEntryGroupsQuery();
  const entrySchedulesQuery = useGetEntrySchedulesQuery();
  const { propertyOrganizationId } = useParams();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    getValues,
  } = useForm<AddAccessProfileValues>({
    defaultValues: {
      displayName: "",
      propertyOrganizationId,
      entryGroupIds: [],
      entryScheduleIds: [],
      dmpProperties: {
        sensorReset: false,
      },
    },
    resolver: yupResolver(AddAccessProfileValidationSchema),
  });

  const addAccessProfile = useMutation<void, HTTPError, AddAccessProfileValues>(
    async (values: AddAccessProfileValues) => {
      await ky.post("access-control/profile", {
        json: values,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: "Access Profile Added",
          status: "success",
          description: "The access profile was added successfully.",
          isClosable: true,
        });
        navigate(
          `/property-organizations/${propertyOrganizationId}/access-control/profiles`
        );
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const validEntryGroupIds = useMemo(
    () =>
      (entryGroupsQuery.data ?? []).map(
        (entryGroup) => entryGroup.entryGroupId
      ),
    [entryGroupsQuery.data]
  );
  const validEntryScheduleIds = useMemo(
    () => (entrySchedulesQuery.data ?? []).map((eg) => eg.entryScheduleId),
    [entrySchedulesQuery.data]
  );

  if (
    entryGroupsQuery.isLoading ||
    !entryGroupsQuery.isSuccess ||
    entrySchedulesQuery.isLoading ||
    !entrySchedulesQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/access-control`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Access Profile</PageHeaderTitle>
        <PageHeaderSubtitle>
          Add an access profile to the property.
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit(async (values: AddAccessProfileValues) =>
            addAccessProfile.mutateAsync(values).catch(noop)
          )}
        >
          <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
            <GridItem>
              <Controller
                name={"displayName"}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error} isRequired>
                    <FormLabel htmlFor="displayName">Display Name</FormLabel>
                    <Input {...field} id="displayName" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
            <GridItem>
              <Controller
                name={"dmpProperties.sensorReset"}
                control={control}
                render={({ field: { value, ...field }, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <Flex alignItems={"center"}>
                      <FormLabel htmlFor="fireDoorRelease" mb={0}>
                        Fire Door Release
                      </FormLabel>
                      <Switch
                        isChecked={value}
                        {...field}
                        id="fireDoorRelease"
                      />
                    </Flex>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
            <Divider />
            <GridItem>
              <Controller
                name={"entryGroupIds"}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="entryGroupIds">Entry Groups</FormLabel>
                    <Stack spacing={5}>
                      {entryGroupsQuery.data?.length ? (
                        <CheckboxGroup defaultValue={field.value}>
                          {entryGroupsQuery.data.map((entryGroup) => (
                            <Checkbox
                              key={entryGroup.entryGroupId}
                              value={entryGroup.entryGroupId}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const value = event.target.value;

                                field.onChange(
                                  isChecked
                                    ? intersection(validEntryGroupIds, [
                                        ...field.value,
                                        value,
                                      ])
                                    : intersection(
                                        validEntryGroupIds,
                                        field.value
                                      ).filter((id) => id !== value)
                                );
                              }}
                            >
                              {entryGroup.displayName}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      ) : (
                        <Text>No Entry Groups</Text>
                      )}
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </Stack>
                  </FormControl>
                )}
              />
            </GridItem>
            <GridItem>
              <Divider />
            </GridItem>
            <GridItem>
              <Controller
                name={"entryScheduleIds"}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="entryScheduleIds">
                      Entry Schedules
                    </FormLabel>
                    <Stack spacing={5}>
                      {entrySchedulesQuery.data?.length ? (
                        <CheckboxGroup value={field.value}>
                          {entrySchedulesQuery.data.map((entrySchedule) => (
                            <Checkbox
                              key={entrySchedule.entryScheduleId}
                              value={entrySchedule.entryScheduleId}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const value = event.target.value;

                                field.onChange(
                                  isChecked
                                    ? intersection(validEntryScheduleIds, [
                                        ...field.value,
                                        value,
                                      ])
                                    : intersection(
                                        validEntryScheduleIds,
                                        field.value
                                      ).filter((id) => id !== value)
                                );
                              }}
                            >
                              {entrySchedule.displayName}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      ) : (
                        <Text>No Entry Schedules</Text>
                      )}
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </Stack>
                  </FormControl>
                )}
              />
            </GridItem>
          </Grid>
          <Grid gap={4} mt={4}>
            <GridItem>
              <Divider />
            </GridItem>
            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/property-organizations/${propertyOrganizationId}/access-control`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  colorScheme="brand.blue"
                >
                  Save
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};
