import React from "react";
import { createIcon } from "@chakra-ui/react";

export const Dot = createIcon({
  displayName: "Dot",
  viewBox: "0 0 8 8",
  defaultProps: {
    width: "8px",
    height: "8px",
  },
  path: (
    <path
      d="M4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4C0 5.06087 0.421427 6.07828 1.17157 6.82843C1.92172 7.57857 2.93913 8 4 8C5.06087 8 6.07828 7.57857 6.82843 6.82843C7.57857 6.07828 8 5.06087 8 4C8 2.93913 7.57857 1.92172 6.82843 1.17157C6.07828 0.421427 5.06087 0 4 0Z"
      fill="currentColor"
    />
  ),
});
