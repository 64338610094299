import { useQuery, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { AccessCredential, Guest } from "src/common/types";
import { useKy } from "src/common/ky";

export const getPropertyGuestsQueryKey = (searchParams: URLSearchParams) => {
  searchParams.sort();

  return ["PROPERTY_GUESTS", searchParams.toString()];
};

export const usePropertyGuestsQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { propertyOrganizationId } = useParams<string>();
  const ky = useKy();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  return useQuery<Guest[]>(getPropertyGuestsQueryKey(searchParams), () =>
    ky.get(`guests`, { searchParams }).json<Guest[]>()
  );
};

export const getPropertyGuestQueryKey = (guestId: string) => ["GUEST", guestId];

export const useGetPropertyGuestQuery = () => {
  const ky = useKy();
  const { guestId } = useParams() as { guestId: string };

  return useQuery<Guest>(getPropertyGuestQueryKey(guestId), () =>
    ky.get(`guests/${guestId}`).json<Guest>()
  );
};

export const getPropertyGuestAccessCredentialQueryKey = (guestId: string) => [
  "GUEST_ACCESS_CREDENTIAL",
  guestId,
];

export const useInvalidatePropertyGuestAccessCredentialQuery = () => {
  const { guestId } = useParams() as { guestId: string };
  const queryClient = useQueryClient();

  return () => {
    return queryClient.invalidateQueries(
      getPropertyGuestAccessCredentialQueryKey(guestId)
    );
  };
};
export const useGetPropertyGuestAccessCredentialQuery = (enabled = false) => {
  const ky = useKy();
  const { guestId } = useParams() as { guestId: string };

  return useQuery<AccessCredential>(
    getPropertyGuestAccessCredentialQueryKey(guestId),
    () =>
      ky.get(`guests/${guestId}/access-credential`).json<AccessCredential>(),
    {
      staleTime: 1000 * 60,
      enabled,
    }
  );
};
