import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { useKy } from "src/common/ky";
import { CompactIntercom, PaginatedResult, UnitGroup } from "src/common/types";
import { cleanSearchParams } from "src/common/util";
import { ALLOWED_PAGINATION_SEARCH_PARAM_KEYS } from "src/common/queries";

export const getUnitGroupsQueryKey = (searchParams: URLSearchParams) => {
  searchParams.sort();

  return ["UNIT-GROUPS", searchParams.toString()];
};

export const useUnitGroupsQuery = ({
  pageSize,
}: { pageSize?: number } = {}) => {
  const ky = useKy();
  const { propertyOrganizationId } = useParams<string>();
  const searchParams = new URLSearchParams(location.search);

  cleanSearchParams(ALLOWED_PAGINATION_SEARCH_PARAM_KEYS, searchParams);

  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", "1");
  }

  if (
    !searchParams.has("pageSize") ||
    (pageSize != null && searchParams.get("pageSize") !== String(pageSize))
  ) {
    searchParams.set("pageSize", String(pageSize ?? 20));
  }

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  return useQuery<PaginatedResult<UnitGroup>>(
    getUnitGroupsQueryKey(searchParams),
    () =>
      ky.get(`unit-groups`, { searchParams }).json<PaginatedResult<UnitGroup>>()
  );
};

export const getUnitGroupQueryKey = (unitGroupId?: string) => [
  "UNIT-GROUP",
  unitGroupId,
];

export const useGetUnitGroupQuery = () => {
  const ky = useKy();
  const { unitGroupId } = useParams() as { unitGroupId: string };

  return useQuery<UnitGroup>(getUnitGroupQueryKey(unitGroupId), () =>
    ky.get(`unit-groups/${unitGroupId}`).json<UnitGroup>()
  );
};

export const useGetAssignedIntercomsQueryKey = (unitGroupId: string) => [
  "UNIT-GROUP-ASSIGNED-INTERCOMS",
  unitGroupId.toString(),
];

export const useGetAssignedIntercomsQuery = (unitGroupId: string) => {
  const ky = useKy();

  return useQuery<CompactIntercom[]>(
    ["UNIT-GROUP-ASSIGNED-INTERCOMS", unitGroupId],
    () =>
      ky
        .get(`product-unit-activations/${unitGroupId}/assigned-intercoms`)
        .json<CompactIntercom[]>()
  );
};
