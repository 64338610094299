import React, { useEffect } from "react";
import * as Yup from "yup";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Select,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { addYears, format, isBefore, isEqual, parseISO } from "date-fns";
import { HTTPError } from "ky";

import { useKy } from "src/common/ky";
import { handleHookFormHTTPError } from "src/common/form";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { noop } from "src/common/util";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { PropertyGuestScheduleTypeOption } from "src/routes/PropertyGuestsList/GuestScheduleTypeOption";
import { ENTRY_SCHEDULE_DAYS_OF_WEEK } from "src/routes/AccessControl/constants";
import { DayButton } from "src/routes/PropertyGuestsList/DayButton";
import { EntryScheduleDayOfWeek } from "src/common/types";

export type PropertyGuestScheduleType = "today" | "daily" | "custom";

export interface PropertyGuestValues {
  name: string;
  companyName: string;
  email: string;
  phone: string;
  propertyOrganizationId: string;
  accessCodeDeliveryMethod: "manual" | "email";
  scheduleType: PropertyGuestScheduleType;
  scheduleStartDate?: string;
  scheduleEndDate?: string;
  scheduleStartTime?: string;
  scheduleEndTime?: string;
  isActiveMon?: boolean;
  isActiveTue?: boolean;
  isActiveWed?: boolean;
  isActiveThu?: boolean;
  isActiveFri?: boolean;
  isActiveSat?: boolean;
  isActiveSun?: boolean;
}

export type PropertyGuestMutationValues = {
  name: string;
  companyName: string;
  email: string;
  phone: string;
  propertyOrganizationId: string;
  accessCodeDeliveryMethod: "manual" | "email";
  scheduleType: PropertyGuestScheduleType;
  scheduleStartDate?: string;
  scheduleEndDate?: string;
  scheduleStartTime?: string;
  scheduleEndTime?: string;
  isActiveMon?: boolean;
  isActiveTue?: boolean;
  isActiveWed?: boolean;
  isActiveThu?: boolean;
  isActiveFri?: boolean;
  isActiveSat?: boolean;
  isActiveSun?: boolean;
};

export const DAY_TO_FIELD_MAP: Record<
  EntryScheduleDayOfWeek,
  keyof PropertyGuestValues
> = {
  [EntryScheduleDayOfWeek.Sunday]: "isActiveSun",
  [EntryScheduleDayOfWeek.Monday]: "isActiveMon",
  [EntryScheduleDayOfWeek.Tuesday]: "isActiveTue",
  [EntryScheduleDayOfWeek.Wednesday]: "isActiveWed",
  [EntryScheduleDayOfWeek.Thursday]: "isActiveThu",
  [EntryScheduleDayOfWeek.Friday]: "isActiveFri",
  [EntryScheduleDayOfWeek.Saturday]: "isActiveSat",
};

const PropertyGuestValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  companyName: Yup.string().required("Company Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  propertyOrganizationId: Yup.string().required("Property is required"),
  accessCodeDeliveryMethod: Yup.string().required(
    "Access Code Delivery Method is required"
  ),
  scheduleType: Yup.string().required("Schedule Type is required"),
  scheduleStartDate: Yup.string()
    .required("Schedule Start Date is required")
    .test(
      "is-before-end",
      "Schedule Start Date must be before or equal to Schedule End Date",
      function (value) {
        const { scheduleEndDate } = this.parent;
        if (!value || !scheduleEndDate) return true;
        return (
          isBefore(parseISO(value), parseISO(scheduleEndDate)) ||
          isEqual(parseISO(value), parseISO(scheduleEndDate))
        );
      }
    ),
  scheduleEndDate: Yup.string().required("Schedule End Date is required"),
  scheduleStartTime: Yup.string()
    .required("Schedule Start Time is required")
    .test(
      "is-before-end-time",
      "Schedule Start Time must be before or equal to Schedule End Time",
      function (value) {
        const { scheduleStartDate, scheduleEndDate, scheduleEndTime } =
          this.parent;
        if (!value || !scheduleEndTime) return true;
        if (scheduleStartDate === scheduleEndDate) {
          return value <= scheduleEndTime;
        }
        return true;
      }
    ),
  scheduleEndTime: Yup.string().required("Schedule End Time is required"),
});

export const AddPropertyGuest = () => {
  const { propertyOrganizationId } = useParams();
  const navigation = useNavigate();
  const toast = useToast();

  const ky = useKy();

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<PropertyGuestValues>({
    resolver: yupResolver(PropertyGuestValidationSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      companyName: "",
      email: "",
      phone: "",
      propertyOrganizationId: propertyOrganizationId,
      accessCodeDeliveryMethod: "email",
      scheduleType: "today",
      scheduleStartDate: format(new Date(), "yyyy-MM-dd"),
      scheduleEndDate: format(new Date(), "yyyy-MM-dd"),
      scheduleStartTime: "00:00:00",
      scheduleEndTime: "23:59:59",
      isActiveMon: false,
      isActiveTue: false,
      isActiveWed: false,
      isActiveThu: false,
      isActiveFri: false,
      isActiveSat: false,
      isActiveSun: false,
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "scheduleType") {
        if (value.scheduleType === "today") {
          setValue("scheduleStartDate", format(new Date(), "yyyy-MM-dd"));
          setValue("scheduleEndDate", format(new Date(), "yyyy-MM-dd"));
          setValue("scheduleStartTime", "00:00:00");
          setValue("scheduleEndTime", "23:59:59");

          ENTRY_SCHEDULE_DAYS_OF_WEEK.forEach((day) => {
            setValue(DAY_TO_FIELD_MAP[day], true);
          });
        } else if (value.scheduleType === "daily") {
          setValue("scheduleStartDate", format(new Date(), "yyyy-MM-dd"));
          setValue(
            "scheduleEndDate",
            format(addYears(new Date(), 1), "yyyy-MM-dd")
          );
          setValue("scheduleStartTime", "00:00:00");
          setValue("scheduleEndTime", "23:59:59");

          ENTRY_SCHEDULE_DAYS_OF_WEEK.forEach((day) => {
            setValue(DAY_TO_FIELD_MAP[day], false);
          });
        } else {
          setValue("scheduleStartDate", format(new Date(), "yyyy-MM-dd"));
          setValue("scheduleEndDate", format(new Date(), "yyyy-MM-dd"));
          setValue("scheduleStartTime", "00:00:00");
          setValue("scheduleEndTime", "00:00:00");

          ENTRY_SCHEDULE_DAYS_OF_WEEK.forEach((day) => {
            setValue(DAY_TO_FIELD_MAP[day], false);
          });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const { getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "Today",
    onChange: (val: PropertyGuestScheduleType) => {
      setValue(
        "scheduleType",
        val.toLocaleLowerCase() as PropertyGuestScheduleType
      );
    },
  });

  const addPropertyGuest = useMutation<
    void,
    HTTPError,
    PropertyGuestMutationValues
  >(
    async (values: PropertyGuestMutationValues) => {
      await ky.post("guests", {
        json: {
          ...values,
        },
      });
    },
    {
      onSuccess: () => {
        toast({
          title: "Property Guest added successfully",
          status: "success",
          isClosable: true,
        });
        navigation(`/property-organizations/${propertyOrganizationId}/guests`);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const options = ["Today", "Daily", "Custom"];

  return (
    <>
      <PageHeader title="Add Property Guest">
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/guests`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Property Guest</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) => {
            return addPropertyGuest
              .mutateAsync({
                ...data,
                propertyOrganizationId: data.propertyOrganizationId,
              })
              .catch(noop);
          })}
        >
          <Grid
            gap={4}
            templateColumns={"repeat(2, 1fr)"}
            width={["100%", "100%", "100%", "100%"]}
          >
            <Grid gap={4} width={["100%", "100%", "50%", "50%"]}>
              <GridItem>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormControl isRequired isInvalid={!!errors.name}>
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <Input
                        {...field}
                        id="name"
                        placeholder="Property Guest Name"
                      />
                      <FormErrorMessage>
                        {errors.name?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field }) => (
                    <FormControl isRequired isInvalid={!!errors.companyName}>
                      <FormLabel htmlFor="companyName">Company Name</FormLabel>
                      <Input
                        {...field}
                        id="companyName"
                        placeholder="Company Name"
                      />
                      <FormErrorMessage>
                        {errors.companyName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormControl isRequired isInvalid={!!errors.email}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input {...field} id="email" placeholder="Email" />
                      <FormErrorMessage>
                        {errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <FormControl isRequired isInvalid={!!errors.phone}>
                      <FormLabel htmlFor="phone">Phone</FormLabel>
                      <PhoneNumberInput {...field} id="phone" />
                      <FormErrorMessage>
                        {errors.phone?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
              <GridItem>
                <Controller
                  name="accessCodeDeliveryMethod"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      isRequired
                      isInvalid={!!errors.accessCodeDeliveryMethod}
                    >
                      <FormLabel htmlFor="accessCodeDeliveryMethod">
                        Access Code Delivery Method
                      </FormLabel>
                      <Select {...field} id="accessCodeDeliveryMethod">
                        <option value="email">Email</option>
                        <option value="manual">Manual</option>
                      </Select>
                      <FormErrorMessage>
                        {errors.accessCodeDeliveryMethod?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
            </Grid>
            <Grid gap={4} templateColumns={"repeat(3, 1fr)"}>
              <GridItem colSpan={3}>
                <FormControl isInvalid={!!errors.scheduleType}>
                  <FormLabel htmlFor="propertyOrganizationId">
                    Schedule Type
                  </FormLabel>
                  <Flex justifyContent={"space-between"}>
                    {options.map((value) => {
                      const radio = getRadioProps({ value });

                      return (
                        <PropertyGuestScheduleTypeOption key={value} {...radio}>
                          {value}
                        </PropertyGuestScheduleTypeOption>
                      );
                    })}
                  </Flex>
                  <FormErrorMessage>
                    {errors.scheduleType?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              {watch("scheduleType") !== "today" && (
                <>
                  <GridItem colSpan={3}>
                    <Flex justifyContent="space-between">
                      {ENTRY_SCHEDULE_DAYS_OF_WEEK.map((day) => (
                        <DayButton
                          key={day}
                          dayOfWeek={day}
                          isActive={Boolean(watch(DAY_TO_FIELD_MAP[day]))}
                          onClick={() =>
                            setValue(
                              DAY_TO_FIELD_MAP[day],
                              !watch(DAY_TO_FIELD_MAP[day])
                            )
                          }
                        />
                      ))}
                    </Flex>
                  </GridItem>

                  {watch("scheduleType") === "custom" && (
                    <>
                      <GridItem colSpan={1} colStart={1} colEnd={1}>
                        <Controller
                          name="scheduleStartTime"
                          control={control}
                          render={({ field }) => (
                            <FormControl isInvalid={!!errors.scheduleStartTime}>
                              <FormLabel htmlFor="scheduleStartTime">
                                Schedule Start Time
                              </FormLabel>
                              <Input
                                {...field}
                                type="time"
                                id="scheduleStartTime"
                                onChange={(e) => {
                                  setValue(
                                    "scheduleStartTime",
                                    `${e.target.value}:00`
                                  );
                                }}
                              />
                              <FormErrorMessage>
                                {errors.scheduleStartTime?.message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      </GridItem>
                      <GridItem colSpan={1} colStart={3} colEnd={3}>
                        <Controller
                          name="scheduleEndTime"
                          control={control}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel htmlFor="scheduleEndTime">
                                Schedule End Time
                              </FormLabel>
                              <Input
                                {...field}
                                type="time"
                                id="scheduleEndTime"
                                onChange={(e) => {
                                  setValue(
                                    "scheduleEndTime",
                                    `${e.target.value}:00`
                                  );
                                }}
                              />
                              <FormErrorMessage>
                                {errors.scheduleEndTime?.message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      </GridItem>
                      <GridItem colSpan={1} colStart={1} colEnd={1}>
                        <Controller
                          name="scheduleStartDate"
                          control={control}
                          render={({ field }) => (
                            <FormControl isInvalid={!!errors.scheduleStartDate}>
                              <FormLabel htmlFor="scheduleStartDate">
                                Schedule Start Date
                              </FormLabel>
                              <Input
                                {...field}
                                type="date"
                                id="scheduleStartDate"
                              />
                              <FormErrorMessage>
                                {errors.scheduleStartDate?.message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      </GridItem>

                      <GridItem colSpan={1} colStart={3} colEnd={3}>
                        <Controller
                          name="scheduleEndDate"
                          control={control}
                          render={({ field }) => (
                            <FormControl>
                              <FormLabel htmlFor="scheduleEndDate">
                                Schedule End Date
                              </FormLabel>
                              <Input
                                {...field}
                                type="date"
                                id="scheduleEndDate"
                              />
                              <FormErrorMessage>
                                {errors.scheduleEndDate?.message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      </GridItem>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Flex justifyContent="flex-end">
            <ButtonGroup>
              <Button
                type="submit"
                colorScheme="brand.blue"
                isLoading={isSubmitting}
              >
                Add Property Guest
              </Button>
              <Link
                as={RouterLink}
                to={`/property-organizations/${propertyOrganizationId}/guests`}
              >
                <Button>Cancel</Button>
              </Link>
            </ButtonGroup>
          </Flex>
        </form>
      </PageContent>
    </>
  );
};
