import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useGateHawkUserRole } from "src/common/GateHawkUserLoader";
import { GateHawkUserRole, PropertyUserRole } from "src/common/types";
import { usePropertyUserRole } from "src/common/queries";

export const useIsAdmin = () => {
  const { propertyOrganizationId } = useParams();

  const propertyUserRole = usePropertyUserRole(
    propertyOrganizationId as string
  );
  const gatehawkUserRole = useGateHawkUserRole();

  return useMemo(
    () =>
      propertyUserRole === PropertyUserRole.admin ||
      gatehawkUserRole === GateHawkUserRole.admin,
    [propertyUserRole, gatehawkUserRole]
  );
};
