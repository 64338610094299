import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";

import { useGetPropertyAccessPanelQuery } from "src/routes/AccessControl/queries";
import { Loading } from "src/common/Loading";
import { PanelSyncStatus } from "src/routes/AccessControl/components/PanelSyncStatus";
import { formatDate } from "src/common/formatters";

export type AccessPanelProps = {
  propertyOrganizationId: string;
};

export const AccessPanel = (props: AccessPanelProps) => {
  const { propertyOrganizationId } = props;
  const panel = useGetPropertyAccessPanelQuery(propertyOrganizationId);

  if (panel.isLoading || !panel.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Access Panel
      </Text>
      <Table>
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Last Synced</Th>
            <Th>Serial Number</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <PanelSyncStatus status={panel.data?.syncStatus} toolTip={true} />
            </Td>
            <Td>
              {panel.data?.syncStatus
                ? formatDate(panel.data?.syncStatus.date, "medium")
                : "N/A"}
            </Td>
            <Td>{panel.data?.serialNumber}</Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};
