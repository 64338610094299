import React from "react";
import { createIcon } from "@chakra-ui/react";

export const Upload = createIcon({
  displayName: "Upload",
  viewBox: "0 0 18 23",
  path: (
    <path
      fill="currentColor"
      d="M5.52366 17.3494H12.7625V9.63858H17.5884L9.14309 0.642578L0.697754 9.63858H5.52366V17.3494ZM0.697754 19.9197H17.5884V22.49H0.697754V19.9197Z"
    />
  ),
});
