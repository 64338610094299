import React from "react";
import { Button, ButtonGroup, Flex, Link } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

export const UnitGroupDetailFilterBar = () => {
  const { propertyOrganizationId, unitGroupId } = useParams();

  return (
    <Flex flexDirection="row">
      <ButtonGroup gap="8px">
        <Link
          as={RouterLink}
          to={`/property-organizations/${propertyOrganizationId}/units/unit-groups/${unitGroupId}/edit`}
        >
          <Button colorScheme="brand.blueGreen">Edit Unit Group</Button>
        </Link>
      </ButtonGroup>
    </Flex>
  );
};
