import React from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff, HiOutlineDotsVertical } from "react-icons/hi";

import {
  useGetPropertyGuestAccessCredentialQuery,
  useGetPropertyGuestQuery,
} from "src/routes/PropertyGuestsList/queries";
import { Loading } from "src/common/Loading";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { Guest } from "src/common/types";
import { HiddenAccessCode } from "src/routes/ViewTenant/HiddenAccessCode";
import { ResetPassword } from "src/common/icons/ResetPassword";
import { useResetPropertyGuestVirtualAccessCredential } from "src/routes/PropertyGuestsList/hooks";

const ViewPropertyGuestPageContent = ({
  propertyGuest,
  onResetAccessCode,
}: {
  propertyGuest: Guest;
  onResetAccessCode: () => void;
}) => {
  const [isViewingAccessCode, setIsViewingAccessCode] = React.useState(false);
  const accessCredentialQuery =
    useGetPropertyGuestAccessCredentialQuery(isViewingAccessCode);

  return (
    <Grid templateColumns={"1fr 2fr"} gap={4}>
      <GridItem>
        <Text>Name</Text>
      </GridItem>
      <GridItem>
        <Text>{propertyGuest.name}</Text>
      </GridItem>
      <GridItem>
        <Text>Company</Text>
      </GridItem>
      <GridItem>
        <Text>{propertyGuest.company_name}</Text>
      </GridItem>
      <GridItem>
        <Text>Phone Number</Text>
      </GridItem>
      <GridItem>
        <Text>{propertyGuest.phone}</Text>
      </GridItem>
      <GridItem>
        <Text>Email Address</Text>
      </GridItem>
      <GridItem>
        <Text>{propertyGuest.email}</Text>
      </GridItem>
      <GridItem>
        <Text>Access Schedule</Text>
      </GridItem>
      <GridItem>
        <Text>{propertyGuest.name}</Text>
      </GridItem>
      <GridItem>
        <Text>Access Code</Text>
      </GridItem>
      <Box
        display="flex"
        flex={1}
        alignItems={"center"}
        justifyContent="space-between"
      >
        {isViewingAccessCode &&
        accessCredentialQuery.isSuccess &&
        !accessCredentialQuery.isLoading ? (
          <Text fontWeight={600}>{accessCredentialQuery.data.code}</Text>
        ) : (
          <HiddenAccessCode />
        )}
        <Flex alignItems="center" justifyContent="flex-end">
          <Button
            aria-label={`${isViewingAccessCode ? "Hide" : "View"} Access Code`}
            size="xs"
            onClick={() => setIsViewingAccessCode(!isViewingAccessCode)}
            leftIcon={isViewingAccessCode ? <HiEyeOff /> : <HiEye />}
          >
            {isViewingAccessCode ? "Hide" : "View"} Access Code
          </Button>
          <Menu>
            <MenuButton
              as={IconButton}
              height="100%"
              aria-label="Actions"
              icon={<HiOutlineDotsVertical />}
              variant="clear"
            >
              Actions
            </MenuButton>
            <MenuList>
              <MenuItem
                justifyContent="space-between"
                onClick={onResetAccessCode}
              >
                Reset Access Code
                <ResetPassword />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </Grid>
  );
};
export const ViewPropertyGuest = () => {
  const query = useGetPropertyGuestQuery();
  const { propertyOrganizationId } = useParams() as {
    propertyOrganizationId: string;
  };
  const {
    isResettingVirtualAccessCredential,
    resetPropertyGuestVirtualAccessCredentialMutation,
    setResettingForGuestId,
  } = useResetPropertyGuestVirtualAccessCredential();

  const content =
    query.isLoading || !query.data ? (
      <Loading />
    ) : (
      <ViewPropertyGuestPageContent
        propertyGuest={query.data}
        onResetAccessCode={() => setResettingForGuestId(query.data.guest_id)}
      />
    );

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/guests`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>{query.isSuccess && query.data.name}</PageHeaderTitle>
      </PageHeader>
      <PageContent>{content}</PageContent>
      <Modal
        isOpen={isResettingVirtualAccessCredential}
        onClose={() => setResettingForGuestId(undefined)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Access Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? This will immediately reset the guest&apos;s access
            code and email them about the change.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"brand.lightGray"}
              color="gray.700"
              mr={3}
              onClick={() => {
                setResettingForGuestId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() =>
                resetPropertyGuestVirtualAccessCredentialMutation.mutate()
              }
              isDisabled={
                resetPropertyGuestVirtualAccessCredentialMutation.isLoading
              }
            >
              {resetPropertyGuestVirtualAccessCredentialMutation.isLoading
                ? "Resetting..."
                : "Reset Access Code"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
