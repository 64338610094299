import React from "react";
import { Box } from "@chakra-ui/react";

import { Dot } from "src/common/icons/Dot";

export const HiddenAccessCode = () => (
  <Box>
    <Dot m="0 10px 0 0" />
    <Dot m="0 10px" />
    <Dot m="0 10px" />
    <Dot m="0 10px" />
    <Dot m="0 0 0 10px" />
  </Box>
);
