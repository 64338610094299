import React from "react";
import { createIcon } from "@chakra-ui/react";

export const GateClosed = createIcon({
  displayName: "GateClosed",
  viewBox: "0 0 22 15",
  path: (
    <path
      fill="currentColor"
      d="M8 0V5H6V1H4V5H2V3H0V15H2V13H4V15H6V13H8V15H10V13H12V15H14V13H16V15H18V13H20V15H22V3H20V5H18V1H16V5H14V0H12V5H10V0H8ZM2 7H4V11H2V7ZM6 7H8V11H6V7ZM10 7H12V11H10V7ZM14 7H16V11H14V7ZM18 7H20V11H18V7Z"
    />
  ),
});
