import {
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import {
  useGetAssignedIntercomsQuery,
  useGetUnitGroupQuery,
} from "src/routes/UnitGroups/queries";
import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { formatDate } from "src/common/formatters";
import { useIsAdmin } from "src/common/useIsAdmin";
import { useDeleteUnit } from "src/routes/Unit/hooks";
import { Pagination } from "src/common/Pagination";
import { useUnitsQuery } from "src/routes/Unit/queries";
import { SortableTh } from "src/common/SortableTh";
import { UnitGroupDetailFilterBar } from "src/routes/UnitGroups/UnitGroupDetailFilterBar";

export const UnitGroupDetails = () => {
  const { propertyOrganizationId, unitGroupId } = useParams() as {
    propertyOrganizationId: string;
    unitGroupId: string;
  };
  const units = useUnitsQuery();
  const unitGroup = useGetUnitGroupQuery();
  const assignedIntercoms = useGetAssignedIntercomsQuery(unitGroupId);

  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const { isDeletingUnit, deleteUnit, setDeletingUnitId } = useDeleteUnit();

  const onPageChange = useCallback(
    (nextPage: number) => {
      const newSp = new URLSearchParams(location.search);
      newSp.set("pageNumber", nextPage.toString());
      navigate(`?${newSp.toString()}`);
    },
    [location, navigate]
  );

  if (unitGroup.isLoading || !unitGroup.data) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader buttons={isAdmin ? <UnitGroupDetailFilterBar /> : null}>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>{unitGroup.data.unitGroupName}</PageHeaderTitle>
        <PageHeaderSubtitle>
          View Unit Group, and assigned Units and Intercoms
        </PageHeaderSubtitle>
      </PageHeader>

      <PageContent>
        <Grid templateColumns={"1fr 2fr"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Units</Heading>
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
        </Grid>
        {units.isLoading || !units.data ? (
          <Loading />
        ) : !units.data?.result.length ? (
          <Flex mt={4}>There are no assigned units.</Flex>
        ) : (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <SortableTh sortKey="name">Unit Name</SortableTh>
                  <SortableTh sortKey="createdAt">Date Added</SortableTh>
                  <Th>Number of Tenants</Th>
                  <Th>{/*menu column}*/}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {units.data.result.map((unit) => (
                  <Tr
                    key={unit.unitId}
                    _hover={{ bg: "brand.lightGray.100", cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/property-organizations/${propertyOrganizationId}/units/${unit.unitId}`
                      );
                    }}
                  >
                    <Td>
                      <Link
                        as={RouterLink}
                        to={`/property-organizations/${propertyOrganizationId}/units/${unit.unitId}`}
                      >
                        {unit.unitName}
                      </Link>
                    </Td>
                    <Td>{formatDate(unit.createdAt, "short")}</Td>
                    <Td>{unit.tenantCount}</Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Actions"
                          icon={<HiOutlineDotsVertical />}
                          variant="clear"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            justifyContent={"space-between"}
                            isDisabled={!isAdmin}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/property-organizations/${propertyOrganizationId}/units/${unit.unitId}/edit`
                              );
                            }}
                          >
                            Edit Unit
                            <HiOutlinePencil />
                          </MenuItem>
                          <MenuItem
                            justifyContent={"space-between"}
                            isDisabled={!isAdmin}
                            onClick={() => {
                              setDeletingUnitId(unit.unitId);
                            }}
                          >
                            Delete Unit
                            <HiOutlineTrash />
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              unitName="unit"
              total={units.data.total}
              pageNumber={units.data.pageNumber}
              pageSize={units.data.pageSize}
              onPageChange={onPageChange}
            />
          </>
        )}
      </PageContent>

      <PageContent>
        <Grid templateColumns={"1fr 2fr"} gap={4}>
          <GridItem colSpan={2}>
            <Heading size="md">Intercoms</Heading>
          </GridItem>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
        </Grid>
        {assignedIntercoms.isLoading || !assignedIntercoms.data ? (
          <Loading />
        ) : !assignedIntercoms.data.length ? (
          <Text mt={4}>There are no assigned intercoms</Text>
        ) : (
          <Flex
            mt={4}
            justify={"flex-start"}
            alignItems={"space-between"}
            direction={"column"}
          >
            {assignedIntercoms.data.map((intercom) => (
              <Link
                mt={1}
                key={intercom.intercomActivationId}
                as={RouterLink}
                to={`/property-organizations/${propertyOrganizationId}/intercom-units/${intercom.intercomActivationId}/details`}
              >
                {intercom.intercomActivationName}
              </Link>
            ))}
          </Flex>
        )}
      </PageContent>
      <Modal
        isOpen={isDeletingUnit}
        onClose={() => {
          setDeletingUnitId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Unit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this unit? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingUnitId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              onClick={async () => {
                deleteUnit.mutate();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
