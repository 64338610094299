import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

import { getPropertyUsersQueryKey } from "src/routes/PropertyStaffList/queries";
import { useKy } from "src/common/ky";

export const useDeletePropertyUser = () => {
  const { propertyOrganizationId } = useParams<string>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const ky = useKy();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  const [deletingPropertyUserId, setDeletingPropertyUserId] = useState<
    string | undefined
  >();
  const deletePropertyUserMutation = useMutation(
    () =>
      ky.delete(`property-users/${deletingPropertyUserId}`, {
        json: { propertyOrganizationId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPropertyUsersQueryKey(searchParams));
        setDeletingPropertyUserId(undefined);
        toast({
          description: "Staff Member Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return {
    isDeletingPropertyUser: !!deletingPropertyUserId,
    deletePropertyUserMutation,
    setDeletingPropertyUserId,
  };
};

export const useResetPropertyUserPassword = () => {
  const { propertyOrganizationId } = useParams<string>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const ky = useKy();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  const [resettingPropertyUserId, setResettingPropertyUserId] = useState<
    string | undefined
  >();
  const resetPropertyUserPasswordMutation = useMutation(
    () =>
      ky.post(`property-users/${resettingPropertyUserId}/reset-password`, {
        json: { propertyOrganizationId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPropertyUsersQueryKey(searchParams));
        setResettingPropertyUserId(undefined);
        toast({
          description: "Password Reset Successful",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return {
    isResettingPropertyUser: !!resettingPropertyUserId,
    resetPropertyUserPasswordMutation,
    setResettingPropertyUserId,
  };
};
