import React from "react";
import { Divider, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";

import { PageContent } from "src/layout/PageContent";
import { PropertyOrganization, PropertyUser } from "src/common/types";
import { Loading } from "src/common/Loading";
import { useKy } from "src/common/ky";
import { formatPhoneNumber } from "src/common/phone-numbers";

type Props = {
  propertyOrganization: PropertyOrganization;
};
export const PropertyAdminContactDetails = ({
  propertyOrganization,
}: Props) => {
  const ky = useKy();
  const propertyAdminQuery = useQuery(
    ["PROPERTY_ORGANIZATION_ADMIN_CONTACT", propertyOrganization?.contactId],
    () =>
      ky
        .get(`property-users/${propertyOrganization?.contactId}`)
        .json<PropertyUser>(),
    {
      staleTime: 60 * 1000,
    }
  );
  return (
    <PageContent>
      <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
        <GridItem colSpan={2}>
          <Heading size="md">Property Admin Contact Details</Heading>
        </GridItem>

        {propertyAdminQuery.isLoading || !propertyAdminQuery.isSuccess ? (
          <Loading />
        ) : (
          <>
            <GridItem colSpan={2}>
              <Divider />
            </GridItem>
            <GridItem>
              <Text>Property Admin First Name</Text>
            </GridItem>
            <GridItem>
              <Text>{propertyAdminQuery.data.user.firstName}</Text>
            </GridItem>
            <GridItem>
              <Text>Property Admin Last Name</Text>
            </GridItem>
            <GridItem>
              <Text>{propertyAdminQuery.data.user.lastName}</Text>
            </GridItem>
            <GridItem>
              <Text>Property Admin Phone Number</Text>
            </GridItem>
            <GridItem>
              <Text>
                {formatPhoneNumber(propertyAdminQuery.data.user.phone)}
              </Text>
            </GridItem>
            <GridItem>
              <Text>Property Admin Email</Text>
            </GridItem>
            <GridItem>
              <Text>{propertyAdminQuery.data.user.email}</Text>
            </GridItem>{" "}
          </>
        )}
      </Grid>
    </PageContent>
  );
};
