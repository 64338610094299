import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardProps,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import { useIsAdmin } from "src/common/useIsAdmin";
import { UnitGroup } from "src/common/types";
import { useDeleteUnitGroup } from "src/routes/UnitGroups/hooks";
import { useGetAssignedIntercomsQuery } from "src/routes/UnitGroups/queries";
import { Loading } from "src/common/Loading";

export type UnitGroupCardProps = CardProps & {
  unitGroup: UnitGroup;
  withMenu?: boolean;
};

export const UnitGroupCard = ({
  unitGroup,
  withMenu = true,
  ...props
}: UnitGroupCardProps) => {
  const { propertyOrganizationId } = useParams();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const { isDeletingUnitGroup, deleteUnitGroup, setDeletingUnitGroupId } =
    useDeleteUnitGroup();
  const assignedIntercoms = useGetAssignedIntercomsQuery(unitGroup.unitGroupId);

  if (assignedIntercoms.isLoading || !assignedIntercoms.data) {
    return <Loading />;
  }

  return (
    <>
      <Card
        height={"88px"}
        onClick={() =>
          navigate(
            `/property-organizations/${propertyOrganizationId}/units/unit-groups/${unitGroup.unitGroupId}`
          )
        }
        _hover={{ bg: "brand.lightGray.100", cursor: "pointer" }}
        {...props}
      >
        <CardBody
          px={4}
          py={3}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <Flex
            justifyContent={"space-between"}
            alignItems="flex-start"
            direction={"column"}
          >
            {assignedIntercoms.data?.length > 0 ? (
              <Flex direction={"row"}>
                {assignedIntercoms.data.map((intercom) => (
                  <Text
                    noOfLines={1}
                    key={intercom.intercomActivationId}
                    color="gray.600"
                    fontWeight="700"
                    textTransform={"uppercase"}
                    fontSize={"xs"}
                    mr={2}
                  >
                    {intercom.intercomActivationName}
                  </Text>
                ))}
              </Flex>
            ) : null}
            <Heading
              size="md"
              fontSize={"18px"}
              color={"brand.blue.600"}
              noOfLines={1}
            >
              {unitGroup.unitGroupName}
            </Heading>
            <Text>
              {unitGroup.unitCount} Unit{unitGroup.unitCount === 1 ? "" : "s"}
            </Text>
          </Flex>
          {withMenu ? (
            <Menu>
              <MenuButton
                onClick={(e) => e.stopPropagation()}
                as={IconButton}
                aria-label="Actions"
                icon={<HiOutlineDotsVertical size={30} />}
                variant="clear"
              >
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem
                  justifyContent="space-between"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `/property-organizations/${propertyOrganizationId}/units/unit-groups/${unitGroup.unitGroupId}/edit`
                    );
                  }}
                  isDisabled={!isAdmin}
                >
                  Edit Unit Group
                  <HiOutlinePencil />
                </MenuItem>
                <MenuItem
                  justifyContent="space-between"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletingUnitGroupId(unitGroup.unitGroupId);
                  }}
                  isDisabled={!isAdmin}
                >
                  Delete Unit Group
                  <HiOutlineTrash />
                </MenuItem>
              </MenuList>
            </Menu>
          ) : null}
        </CardBody>
      </Card>

      <Modal
        isOpen={isDeletingUnitGroup}
        onClose={() => {
          setDeletingUnitGroupId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Unit Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this unit group? This action cannot
            be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingUnitGroupId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                deleteUnitGroup.mutate();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
