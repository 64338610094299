import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

import { ScreenSize } from "src/common/types";
import { config } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/previewConfig";

type PreviewTextProps = TextProps & { screenSize: ScreenSize };

export const PreviewText = ({
  screenSize,
  children,
  ...props
}: PreviewTextProps) => {
  const { lineHeight, fontSize } = config[screenSize];

  return (
    <Text
      fontFamily="Montserrat"
      color="white"
      lineHeight={lineHeight.default}
      fontSize={fontSize.default}
      {...props}
    >
      {children}
    </Text>
  );
};
