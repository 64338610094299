import React from "react";
import {
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import { useMutation, useQueryClient } from "react-query";
import { HTTPError } from "ky";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useIsAdmin } from "src/common/useIsAdmin";
import { noop } from "src/common/util";
import { useUnitGroupsQuery } from "src/routes/UnitGroups/queries";
import {
  getProductActivationUnitGroupKey,
  useProductActivationUnitGroupQuery,
} from "src/common/queries";
import { Loading } from "src/common/Loading";
import { useKy } from "src/common/ky";
import { handleHookFormHTTPError } from "src/common/form";

export type UnitGroupCardProps = {
  intercomActivationId: string;
};

type UnitGroupFormValues = { unitGroupId: string };
type UnitGroupMutationValues = { unitGroupId: string | null };

export const UnitGroupAssignmentCard = ({
  intercomActivationId,
}: UnitGroupCardProps) => {
  const ky = useKy();
  const isAdmin = useIsAdmin();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { propertyOrganizationId } = useParams();
  const unitGroupsQuery = useUnitGroupsQuery({ pageSize: 999 });
  const intercomUnitGroupQuery = useProductActivationUnitGroupQuery();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit: _handleSubmit,
    formState: { isSubmitting },
    setError,
    getValues,
  } = useForm<UnitGroupFormValues>({
    defaultValues: {
      unitGroupId: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        unitGroupId: Yup.string().required().label("Unit Group"),
      })
    ),
  });

  const {
    isOpen: isRemovingUnitGroup,
    onOpen: openRemovingUnitGroup,
    onClose: closeRemovingUnitGroup,
  } = useDisclosure();
  const {
    isOpen: isAssigningUnitGroup,
    onOpen: openAssigningUnitGroup,
    onClose: closeAssigningUnitGroup,
  } = useDisclosure();

  const updateUnitGroup = useMutation<void, HTTPError, UnitGroupMutationValues>(
    async (values: UnitGroupMutationValues) => {
      await ky.patch(
        `product-unit-activations/${intercomActivationId}/assign-unit-group`,
        {
          json: values,
        }
      );
    },
    {
      onSuccess: async () => {
        toast({
          description: "Unit Group Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        closeAssigningUnitGroup();
        closeRemovingUnitGroup();
        await queryClient.invalidateQueries(
          getProductActivationUnitGroupKey(intercomActivationId)
        );
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const handleSubmit = _handleSubmit((data) => {
    return updateUnitGroup
      .mutateAsync({
        unitGroupId: data.unitGroupId ? data.unitGroupId : null,
      })
      .catch(noop);
  });

  if (intercomUnitGroupQuery.isLoading || unitGroupsQuery.isLoading) {
    return <Loading />;
  }

  return (
    <>
      {intercomUnitGroupQuery.data?.unitGroup ? (
        <Card
          height={"80px"}
          onClick={() =>
            navigate(
              `/property-organizations/${propertyOrganizationId}/units/unit-groups/${intercomUnitGroupQuery.data.unitGroup?.unitGroupId}`
            )
          }
          _hover={{ bg: "brand.lightGray.100", cursor: "pointer" }}
        >
          <CardBody
            px={4}
            py={3}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <Flex
              justifyContent={"space-between"}
              alignItems="flex-start"
              direction={"column"}
            >
              <Heading
                size="md"
                fontSize={"18px"}
                color={"brand.blue.600"}
                noOfLines={1}
              >
                {intercomUnitGroupQuery.data.unitGroup.unitGroupName}
              </Heading>
              <Text
                textTransform={"uppercase"}
                fontWeight={"bold"}
                fontSize={12}
                color={"gray.600"}
              >
                {intercomUnitGroupQuery.data.unitGroup.unitCount} Unit
                {intercomUnitGroupQuery.data.unitGroup.unitCount === 1
                  ? ""
                  : "s"}
              </Text>
            </Flex>
            <Menu>
              <MenuButton
                onClick={(e) => e.stopPropagation()}
                as={IconButton}
                aria-label="Actions"
                icon={<HiOutlineDotsVertical size={30} />}
                variant="clear"
              >
                Actions
              </MenuButton>
              <MenuList>
                <MenuItem
                  justifyContent="space-between"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAssigningUnitGroup();
                  }}
                  isDisabled={!isAdmin}
                >
                  Change Intercom Unit Group
                  <HiOutlinePencil style={{ marginLeft: 10 }} />
                </MenuItem>
                <MenuItem
                  justifyContent="space-between"
                  onClick={(e) => {
                    e.stopPropagation();
                    openRemovingUnitGroup();
                  }}
                  isDisabled={!isAdmin}
                >
                  Remove Unit Group from Intercom
                  <HiOutlineTrash style={{ marginLeft: 10 }} />
                </MenuItem>
              </MenuList>
            </Menu>
          </CardBody>
        </Card>
      ) : (
        <Button
          alignSelf={"flex-start"}
          colorScheme="brand.blueGreen"
          onClick={openAssigningUnitGroup}
        >
          Assign a Unit Group to Intercom
        </Button>
      )}

      <Modal isOpen={isAssigningUnitGroup} onClose={closeAssigningUnitGroup}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Intercom Unit Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Controller
                name="unitGroupId"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl
                    isRequired
                    isInvalid={fieldState.invalid && fieldState.isTouched}
                  >
                    <FormLabel htmlFor="unitGroupId">
                      Select a Unit Group
                    </FormLabel>
                    <Select {...field} id="unitGroupId">
                      <option value={""}></option>
                      {unitGroupsQuery.data?.result.map((ug) => (
                        <option key={ug.unitGroupId} value={ug.unitGroupId}>
                          {ug.unitGroupName}
                          {ug.unitCount != null
                            ? ` (${ug.unitCount} Unit${
                                ug.unitCount === 1 ? "" : "s"
                              })`
                            : ""}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={closeAssigningUnitGroup}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.blue"
              mr={3}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isRemovingUnitGroup} onClose={closeRemovingUnitGroup}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Unit Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you wish to remove this unit group from the intercom?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={closeRemovingUnitGroup}
            >
              Close
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              isLoading={isSubmitting}
              onClick={() => updateUnitGroup.mutateAsync({ unitGroupId: null })}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
