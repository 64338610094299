import React, { useEffect, useMemo } from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { format, setHours, setMinutes, startOfToday } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { sortBy } from "lodash";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { DayOfWeek, IntercomActivation } from "src/common/types";
import { DayButton } from "src/routes/IntercomUnits/EditSchedule/DayButton";
import { HTTPError, useKy } from "src/common/ky";
import { getProductActivationsQueryKey } from "src/common/queries";
import { noop } from "src/common/util";
import { handleHookFormHTTPError } from "src/common/form";

const displayDays: Record<DayOfWeek, string> = {
  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
};
const dayArray: DayOfWeek[] = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

type IntercomScheduleDay = {
  dayOfWeek: DayOfWeek;
  openTime: Date;
  closeTime: Date;
};

type IntercomScheduleDaysPayload = {
  scheduleDays: {
    dayOfWeek: DayOfWeek;
    openTime: string;
    closeTime: string;
  }[];
};

type UpdateScheduleDaysValues = {
  scheduleDays: IntercomScheduleDay[];
};

const EditIntercomScheduleSchema = Yup.object().shape({
  scheduleDays: Yup.array()
    .of(
      Yup.object({
        dayOfWeek: Yup.string().required().oneOf(dayArray).label("Day of Week"),
        openTime: Yup.date().required().label("Open Time"),
        closeTime: Yup.date().required().label("Close Time"),
      })
    )
    .label("Entry Schedule"),
});

export const EditSchedule = () => {
  const { activationId, propertyOrganizationId } = useParams();
  const toast = useToast();
  const ky = useKy();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  const {
    isOpen: overrideModalIsOpen,
    onOpen: onOpenOverrideModal,
    onClose: onCloseOverrideModal,
  } = useDisclosure();

  useEffect(() => {
    if (currentActivation && currentActivation.isForcedOpen) {
      onOpenOverrideModal();
    }
  }, [currentActivation]);

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<UpdateScheduleDaysValues>({
    defaultValues: {
      scheduleDays: currentActivation.scheduleDays.map((sd) => {
        const [openHours, openMinutes] = sd.openTime.split(":");
        const [closeHours, closeMinutes] = sd.closeTime.split(":");

        return {
          dayOfWeek: sd.dayOfWeek,
          openTime: setMinutes(
            setHours(startOfToday(), parseInt(openHours)),
            parseInt(openMinutes)
          ),
          closeTime: setMinutes(
            setHours(startOfToday(), parseInt(closeHours)),
            parseInt(closeMinutes)
          ),
        };
      }),
    },
    resolver: yupResolver(EditIntercomScheduleSchema),
  });

  const {
    fields: scheduleFields,
    append: appendScheduleField,
    remove: removeScheduleField,
  } = useFieldArray({
    control,
    name: "scheduleDays",
  });

  const sortedScheduleFields = useMemo(
    () => sortBy(scheduleFields, (v) => dayArray.indexOf(v.dayOfWeek)),
    [scheduleFields]
  );

  const editScheduleDays = useMutation<
    void,
    HTTPError,
    IntercomScheduleDaysPayload
  >(
    async (values: IntercomScheduleDaysPayload) => {
      await ky.put(`product-unit-activations/${activationId}/schedule-days`, {
        json: values,
      });
    },
    {
      onSuccess: async () => {
        toast({
          title: "Schedule saved.",
          description: "The schedule was saved successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        await queryClient.invalidateQueries(
          getProductActivationsQueryKey(propertyOrganizationId)
        );
        navigate(
          `/property-organizations/${propertyOrganizationId}/intercom-units`
        );
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  if (!currentActivation) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/intercom-units`}
          >
            &#8249; Back to Intercom Units
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>{`Intercom Schedule (${currentActivation.name})`}</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            editScheduleDays
              .mutateAsync({
                scheduleDays: data.scheduleDays.map((sd) => ({
                  dayOfWeek: sd.dayOfWeek,
                  openTime: format(sd.openTime, "HH:mm"),
                  closeTime: format(sd.closeTime, "HH:mm"),
                })),
              })
              .catch(noop)
          )}
        >
          <Grid gap={4}>
            <GridItem>
              <Grid width={["100%", "100%", "500px", "500px", "500px"]} gap={4}>
                <GridItem>
                  <Text color="gray.500" fontSize="sm">
                    Select the days and times you want entry points to remain
                    open.
                  </Text>
                </GridItem>

                <GridItem>
                  <Wrap
                    justify="space-between"
                    p="10px 0"
                    justifyContent="space-between"
                  >
                    {dayArray.map((day) => (
                      <WrapItem key={day}>
                        <Controller
                          name={`scheduleDays`}
                          control={control}
                          render={({ field }) => (
                            <DayButton
                              day={day}
                              isActive={field.value.some(
                                (d) => d.dayOfWeek === day
                              )}
                              onClick={() => {
                                if (
                                  !field.value.some((d) => d.dayOfWeek === day)
                                ) {
                                  appendScheduleField({
                                    dayOfWeek: day,
                                    openTime: setHours(startOfToday(), 8),
                                    closeTime: setHours(startOfToday(), 9),
                                  });
                                } else {
                                  removeScheduleField(
                                    field.value.findIndex(
                                      (d) => d.dayOfWeek === day
                                    )
                                  );
                                }
                              }}
                            />
                          )}
                        />
                      </WrapItem>
                    ))}
                  </Wrap>
                </GridItem>

                <GridItem>
                  <Grid rowGap={"24px"}>
                    {sortedScheduleFields.map((scheduleField, index) => (
                      <GridItem key={scheduleField.dayOfWeek}>
                        <Grid templateColumns="1fr 1fr" columnGap="16px">
                          <GridItem colSpan={2}>
                            <Text fontSize="m" fontWeight={600}>
                              {displayDays[scheduleField.dayOfWeek]}
                            </Text>
                          </GridItem>
                          <GridItem>
                            <Controller
                              name={`scheduleDays.${index}.openTime`}
                              control={control}
                              render={({ field, fieldState }) => (
                                <FormControl isInvalid={!!fieldState.error}>
                                  <FormLabel
                                    textTransform="uppercase"
                                    color="#7A8A99"
                                    fontSize="sm"
                                    fontWeight={600}
                                    htmlFor={`schedule.${index}.openTime`}
                                  >
                                    Open Entry Point
                                  </FormLabel>
                                  <DatePicker
                                    customInput={<Input />}
                                    selected={setMinutes(
                                      setHours(
                                        startOfToday(),
                                        field.value.getHours()
                                      ),
                                      field.value.getMinutes()
                                    )}
                                    onChange={(date) =>
                                      field.onChange(
                                        date || setHours(startOfToday(), 8)
                                      )
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="hh:mm aa"
                                    timeFormat="hh:mm aa"
                                    showPopperArrow={false}
                                  />
                                  <FormErrorMessage>
                                    {fieldState.error?.message}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          </GridItem>
                          <GridItem>
                            <Controller
                              name={`scheduleDays.${index}.closeTime`}
                              control={control}
                              render={({ field, fieldState }) => (
                                <FormControl isInvalid={!!fieldState.error}>
                                  <FormLabel
                                    textTransform="uppercase"
                                    color="#7A8A99"
                                    fontSize="sm"
                                    fontWeight={600}
                                    htmlFor={`scheduleDays.${index}.closeTime`}
                                  >
                                    Close Entry Point
                                  </FormLabel>
                                  <DatePicker
                                    customInput={<Input />}
                                    selected={setMinutes(
                                      setHours(
                                        startOfToday(),
                                        field.value.getHours()
                                      ),
                                      field.value.getMinutes()
                                    )}
                                    onChange={(date) =>
                                      field.onChange(
                                        date || setHours(startOfToday(), 9)
                                      )
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="hh:mm aa"
                                    timeFormat="hh:mm aa"
                                    showPopperArrow={false}
                                  />
                                  <FormErrorMessage>
                                    {fieldState.error?.message}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          </GridItem>
                        </Grid>
                      </GridItem>
                    ))}
                  </Grid>
                </GridItem>
              </Grid>
            </GridItem>

            {errors[`scheduleDays`]?.message ? (
              <GridItem colSpan={2}>
                <FormControl isInvalid={true}>
                  <FormErrorMessage>
                    {errors[`scheduleDays`]?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            ) : null}

            {Array.isArray(errors["scheduleDays"])
              ? errors["scheduleDays"]?.map((err, idx) =>
                  err?.message ? (
                    <GridItem key={idx} colSpan={2}>
                      <FormControl isInvalid={true}>
                        <FormErrorMessage>{err?.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  ) : null
                )
              : null}

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/property-organizations/${propertyOrganizationId}/intercom-units`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save Schedule
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
      <Modal
        isOpen={overrideModalIsOpen}
        onClose={() => {
          onCloseOverrideModal();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${currentActivation.name} Forced Unlocked`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {`${currentActivation.name} is currently Forced Unlocked. Any changes made to the schedule will not take effect until ${currentActivation.name} is no longer Forced Unlocked.`}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.blue"
              mr={3}
              onClick={() => {
                onCloseOverrideModal();
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
