import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Loading } from "src/common/Loading";
import { Pagination } from "src/common/Pagination";
import { AccessRequestSource, AccessRequestUserType } from "src/common/types";
import { PageContent } from "src/layout/PageContent";
import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import {
  AccessLogsFilterBar,
  useAccessRequestsQuery,
} from "src/routes/AccessLogs/AccessLogsFilterBar";

export const AccessLogs = () => {
  const navigate = useNavigate();

  const query = useAccessRequestsQuery();

  return (
    <>
      <PageHeader buttons={<AccessLogsFilterBar />}>
        <PageHeaderTitle>Access Logs</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        {query.isLoading || !query.data ? (
          <Loading />
        ) : (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>User Type</Th>
                  <Th>Access Resolution</Th>
                  <Th>Intercom</Th>
                  <Th>Source</Th>
                  <Th>Medium</Th>
                  <Th>Date</Th>
                  <Th>Time</Th>
                </Tr>
              </Thead>
              <Tbody>
                {query.data.result.map((accessRequest) => (
                  <Tr key={accessRequest.accessRequestId}>
                    <Td>
                      {accessRequest.userType ===
                      AccessRequestUserType.TenantGuest ? (
                        <Text>
                          {accessRequest.userInvitedByName}&apos;s Guest
                        </Text>
                      ) : (
                        <Text>{accessRequest.userName}</Text>
                      )}
                    </Td>
                    <Td>
                      <Text>
                        {accessRequest.userType
                          ?.match(/[A-Z][a-z]+/g)
                          ?.join(" ")}
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        color={accessRequest.accessGranted ? "green" : "red"}
                      >
                        {accessRequest.accessGranted
                          ? "Access Granted"
                          : "Access Denied"}
                      </Text>
                    </Td>
                    <Td>
                      <Text>{accessRequest.locationName}</Text>
                    </Td>
                    <Td>
                      <Text>
                        {accessRequest.source ===
                        AccessRequestSource.MobileIntercom
                          ? "Virtual"
                          : accessRequest.source}
                      </Text>
                    </Td>
                    <Td>
                      <Text>{accessRequest.medium}</Text>
                    </Td>
                    <Td>
                      <Text>
                        {format(new Date(accessRequest.createdAt), "P")}
                      </Text>
                    </Td>
                    <Td>
                      <Text>
                        {format(new Date(accessRequest.createdAt), "p")}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              unitName="log entry"
              unitPlural="log entries"
              total={query.data.total}
              pageNumber={query.data.pageNumber}
              pageSize={query.data.pageSize}
              onPageChange={(nextPageNumber) => {
                navigate(`${location.pathname}?pageNumber=${nextPageNumber}`);
              }}
            />
          </>
        )}
      </PageContent>
    </>
  );
};
