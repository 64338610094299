import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { HTTPError, useKy } from "src/common/ky";

export const useDeleteUnitGroup = () => {
  const ky = useKy();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deletingUnitGroupId, setDeletingUnitGroupId] = useState<
    string | undefined
  >();

  const deleteUnitGroup = useMutation<void, HTTPError>(
    async () => {
      await ky.delete(`unit-groups/${deletingUnitGroupId}`);
    },
    {
      onSuccess: () => {
        toast({
          title: "Unit group deleted",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries("UNIT-GROUPS");
        setDeletingUnitGroupId(undefined);
      },
      onError: async (error) => {
        let message = error.message;

        if (error.response.status < 500) {
          const body: { message: string } = await error.response.json();
          message = body.message;
        }

        toast({
          title: "Failed to delete unit group",
          description: message,
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  return {
    isDeletingUnitGroup: !!deletingUnitGroupId,
    deleteUnitGroup,
    setDeletingUnitGroupId,
  };
};
