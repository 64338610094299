import React, { useEffect, useMemo } from "react";
import {
  useParams,
  Outlet,
  useOutletContext,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { Loading } from "src/common/Loading";
import { IntercomActivation } from "src/common/types";

export const IntercomActivationLoader = () => {
  const { activationId, propertyOrganizationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { intercomActivations } =
    useOutletContext<{ intercomActivations: IntercomActivation[] }>();

  const currentActivation = useMemo(() => {
    if (intercomActivations.length && activationId) {
      return intercomActivations.find(
        (activation) => activation.intercomActivationId === activationId
      );
    }
  }, [activationId, intercomActivations]);

  useEffect(() => {
    if (!currentActivation) {
      return;
    }

    if (!location.pathname.match(new RegExp(`${activationId}$`))) {
      return;
    }

    navigate(
      `/property-organizations/${propertyOrganizationId}/intercom-units/${activationId}/details`
    );
  }, [currentActivation, location.pathname]);

  if (activationId && !currentActivation) {
    return <Loading />;
  }

  return <Outlet context={{ currentActivation }} />;
};
