import { useState } from "react";
import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";

import { useInvalidateTenantsQuery } from "src/routes/TenantList/queries";
import { useKy } from "src/common/ky";

export const useDeleteTenant = () => {
  const [deletingTenantId, setDeletingTenantId] = useState<
    string | undefined
  >();
  const invalidateTenantsQuery = useInvalidateTenantsQuery();
  const toast = useToast();
  const ky = useKy();

  const deleteTenantMutation = useMutation(
    () => ky.delete(`tenants/${deletingTenantId}`),
    {
      onSuccess: async () => {
        toast({
          title: "Tenant deleted.",
          description: "The tenant was deleted successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        await invalidateTenantsQuery();
        setDeletingTenantId(undefined);
      },
    }
  );

  return {
    isDeletingTenant: !!deletingTenantId,
    deleteTenantMutation,
    setDeletingTenantId,
  };
};

export const useResetTenantPassword = () => {
  const toast = useToast();
  const ky = useKy();
  const invalidateTenantsQuery = useInvalidateTenantsQuery();

  const [resettingTenantId, setResettingTenantId] = useState<
    string | undefined
  >();
  const resetTenantPasswordMutation = useMutation(
    () => ky.post(`tenants/${resettingTenantId}/reset-password`),
    {
      onSuccess: async () => {
        await invalidateTenantsQuery();
        setResettingTenantId(undefined);
        toast({
          description: "Password Reset Successful!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return {
    isResettingTenant: !!resettingTenantId,
    resetTenantPasswordMutation,
    setResettingTenantId,
  };
};
