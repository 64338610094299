import React, { useMemo } from "react";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { EditTenantForm } from "src/routes/EditTenant/EditTenantForm";
import { Loading } from "src/common/Loading";
import { useGetTenantQuery } from "src/routes/ViewTenant/queries";

export const EditTenant = () => {
  const { propertyOrganizationId, unitId } = useParams();

  const tenantQuery = useGetTenantQuery();

  const backUrl = useMemo(
    () =>
      `/property-organizations/${propertyOrganizationId}/units${
        unitId ? `/${unitId}` : `/tenants`
      }`,
    [propertyOrganizationId, unitId]
  );

  const content = tenantQuery.isSuccess ? (
    <EditTenantForm tenant={tenantQuery.data} />
  ) : (
    <Loading />
  );

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link as={RouterLink} to={backUrl}>
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Tenant</PageHeaderTitle>
      </PageHeader>
      <PageContent>{content}</PageContent>
    </>
  );
};
