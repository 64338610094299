import React from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import { PageContent } from "src/layout/PageContent";
import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import {
  useCurrentPropertyOrganization,
  useCurrentPropertyUser,
} from "src/common/queries";
import { Loading } from "src/common/Loading";
import {
  useDeletePropertyUser,
  useResetPropertyUserPassword,
} from "src/routes/PropertyStaffList/hooks";
import { ResetPassword } from "src/common/icons/ResetPassword";
import { useGetPropertyUsersQuery } from "src/routes/PropertyStaffList/queries";
import { PropertyStaffListFilterBar } from "src/routes/PropertyStaffList/PropertyStaffListFilterBar";
import { useIsAdmin } from "src/common/useIsAdmin";
import { formatPhoneNumber } from "src/common/phone-numbers";
import { CircleInfo } from "src/common/icons/CircleInfo";

export const PropertyStaffList = () => {
  const { propertyOrganizationId } = useParams();
  const user = useCurrentPropertyUser(propertyOrganizationId as string);
  const propertyUserId = user?.propertyUserId || null;
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  const query = useGetPropertyUsersQuery();
  const currentPropertyOrganization = useCurrentPropertyOrganization(
    propertyOrganizationId as string
  );

  const {
    isDeletingPropertyUser,
    deletePropertyUserMutation,
    setDeletingPropertyUserId,
  } = useDeletePropertyUser();
  const {
    isResettingPropertyUser,
    resetPropertyUserPasswordMutation,
    setResettingPropertyUserId,
  } = useResetPropertyUserPassword();

  if (query.isLoading || !query.data) {
    return <Loading />;
  }

  if (query.isError) {
    return <>Error</>;
  }

  const propertyUsers = query.data || [];

  return (
    <>
      <PageHeader buttons={<PropertyStaffListFilterBar />}>
        <PageHeaderTitle>Staff Members</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {propertyUsers.map((propertyUser) => (
              <Tr key={propertyUser.propertyUserId}>
                <Td>
                  <Flex>
                    <Text>
                      {propertyUser.user.firstName} {propertyUser.user.lastName}
                    </Text>
                    {currentPropertyOrganization?.contactId ===
                      propertyUser.propertyUserId && (
                      <>
                        <Box width={5} />
                        <Badge>Property Contact</Badge>
                      </>
                    )}
                  </Flex>
                </Td>
                <Td>{formatPhoneNumber(propertyUser.user.phone)}</Td>
                <Td>{propertyUser.user.email}</Td>
                <Td>
                  {propertyUser.role.substring(0, 1).toUpperCase() +
                    propertyUser.role.substring(1).toLowerCase()}
                </Td>
                <Td>
                  {isAdmin && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Actions"
                        icon={<HiOutlineDotsVertical />}
                        variant="clear"
                      >
                        Actions
                      </MenuButton>
                      <MenuList w="320px">
                        <MenuItem
                          justifyContent="space-between"
                          onClick={() => {
                            navigate(
                              `/property-organizations/${propertyOrganizationId}/staff/${propertyUser.propertyUserId}/edit`
                            );
                          }}
                        >
                          Edit Staff Member
                          <HiOutlinePencil />
                        </MenuItem>
                        <MenuItem
                          isDisabled={
                            propertyUserId === propertyUser.propertyUserId
                          }
                          justifyContent="space-between"
                          onClick={() => {
                            setResettingPropertyUserId(
                              propertyUser.propertyUserId
                            );
                          }}
                        >
                          Reset Password
                          <ResetPassword />
                        </MenuItem>
                        <MenuItem
                          opacity={
                            propertyUserId === propertyUser.propertyUserId ||
                            currentPropertyOrganization?.contactId ===
                              propertyUser.propertyUserId
                              ? 0.4
                              : 1
                          }
                          justifyContent="space-between"
                          onClick={() => {
                            // Mimicking a disabled state. We can't use the isDisabled prop because of a Chakra UI bug
                            // which prevents the tooltip from working correctly on disabled menu items.
                            if (
                              propertyUserId === propertyUser.propertyUserId ||
                              currentPropertyOrganization?.contactId !==
                                propertyUser.propertyUserId
                            ) {
                              setDeletingPropertyUserId(
                                propertyUser.propertyUserId
                              );
                            }
                          }}
                        >
                          <Flex>
                            <Text>Delete Staff Member</Text>
                            {propertyUserId === propertyUser.propertyUserId ||
                              (currentPropertyOrganization?.contactId ===
                                propertyUser.propertyUserId && (
                                <>
                                  <Box width={1} />
                                  <Tooltip label="This staff member cannot be deleted because they are listed as the primary contact for this property.">
                                    <CircleInfo />
                                  </Tooltip>
                                </>
                              ))}
                          </Flex>
                          <HiOutlineTrash />
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>{" "}
      </PageContent>

      {isAdmin && (
        <>
          <Modal
            isOpen={isDeletingPropertyUser}
            onClose={() => {
              setDeletingPropertyUserId(undefined);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Delete Staff Member</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure? You can&apos;t undo this action afterwards.
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="brand.lightGray"
                  color="gray.700"
                  mr={3}
                  onClick={() => {
                    setDeletingPropertyUserId(undefined);
                  }}
                >
                  Close
                </Button>
                <Button
                  colorScheme="brand.red"
                  onClick={() => deletePropertyUserMutation.mutate()}
                  isDisabled={deletePropertyUserMutation.isLoading}
                >
                  Accept
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={isResettingPropertyUser}
            onClose={() => {
              setResettingPropertyUserId(undefined);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Reset Staff Password</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure? This will immediately email the user a new
                password and they will not be able to login with their existing
                credentials.
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="brand.lightGray"
                  color="gray.700"
                  mr={3}
                  onClick={() => {
                    setResettingPropertyUserId(undefined);
                  }}
                >
                  Close
                </Button>
                <Button
                  colorScheme="brand.red"
                  onClick={() => resetPropertyUserPasswordMutation.mutate()}
                  isDisabled={resetPropertyUserPasswordMutation.isLoading}
                >
                  {resetPropertyUserPasswordMutation.isLoading
                    ? "Resetting..."
                    : "Accept"}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};
