import React from "react";
import { createIcon } from "@chakra-ui/react";

export const GateOpen = createIcon({
  displayName: "GateOpen",
  viewBox: "0 0 22 14",
  path: (
    <path
      fill="currentColor"
      d="M6 14V0H4V4H2V2H0V14H2V12H4V14H6ZM2 10V6H4V10H2ZM20 2V4H18V0H16V14H18V12H20V14H22V2H20ZM20 10H18V6H20V10Z"
    />
  ),
});
