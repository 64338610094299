import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { HTTPError } from "ky";
import { UseFormGetValues, UseFormSetError } from "react-hook-form";

import {
  getAccessProfileQueryKey,
  getAccessProfilesByPropertyQueryKey,
  getEntryScheduleQueryKey,
  getEntrySchedulesQueryKey,
} from "src/routes/AccessControl/queries";
import { useKy } from "src/common/ky";
import { UpdateAccessProfileValues } from "src/routes/AccessControl/EditProfile";
import { AccessProfile, EntrySchedule } from "src/common/types";
import { handleHookFormHTTPError } from "src/common/form";
import { UpdateEntryScheduleValues } from "src/routes/AccessControl/EditEntrySchedule";

export const useDeleteProfile = () => {
  const queryClient = useQueryClient();
  const { propertyOrganizationId } = useParams();
  const toast = useToast();
  const ky = useKy();

  const [deletingProfileId, setDeletingProfileId] = useState<
    string | undefined
  >();
  const deleteProfileMutation = useMutation(
    () =>
      ky.delete(`access-control/profile/${deletingProfileId}`, {
        json: { propertyOrganizationId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAccessProfilesByPropertyQueryKey());
        setDeletingProfileId(undefined);
        toast({
          title: "Profile Deleted",
          status: "success",
        });
      },
      onError: async (error: HTTPError) => {
        if (error.response.status < 500) {
          const body: { message?: string } = await error.response.json();
          const message = body?.message ?? error.message;

          toast({
            title: "Failed to delete profile",
            description: message,
            status: "error",
            isClosable: true,
          });

          setDeletingProfileId(undefined);
        }
      },
    }
  );

  return {
    isDeletingProfile: !!deletingProfileId,
    deleteProfileMutation,
    setDeletingProfileId,
  };
};

export const useDeleteEntrySchedule = () => {
  const queryClient = useQueryClient();
  const { propertyOrganizationId, dealerOrganizationId } = useParams();
  const toast = useToast();
  const ky = useKy();

  const [deletingEntryScheduleId, setDeletingEntryScheduleId] = useState<
    string | undefined
  >();
  const deleteEntryScheduleMutation = useMutation(
    () =>
      ky.delete(`access-control/entry-schedule/${deletingEntryScheduleId}`, {
        json: { dealerOrganizationId },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getEntrySchedulesQueryKey(propertyOrganizationId as string)
        );
        setDeletingEntryScheduleId(undefined);
        toast({
          title: "Entry Schedule Deleted",
          status: "success",
        });
      },
      onError: async (error: HTTPError) => {
        if (error.response.status < 500) {
          const body: { message?: string } = await error.response.json();
          const message = body?.message ?? error.message;

          toast({
            title: "Failed to delete entry schedule",
            description: message,
            status: "error",
            isClosable: true,
          });

          setDeletingEntryScheduleId(undefined);
        }
      },
    }
  );

  return {
    isDeletingEntrySchedule: !!deletingEntryScheduleId,
    deleteEntryScheduleMutation,
    setDeletingEntryScheduleId,
  };
};

export const useUpdateAccessProfile = (
  setError: UseFormSetError<UpdateAccessProfileValues>,
  getValues: UseFormGetValues<UpdateAccessProfileValues>
) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const ky = useKy();
  const navigate = useNavigate();
  const { propertyOrganizationId, accessProfileId } = useParams();

  const [updatingAccessProfile, setUpdatingAccessProfile] = useState<
    AccessProfile | undefined
  >();

  const updateAccessProfileMutation = useMutation<
    void,
    HTTPError,
    UpdateAccessProfileValues
  >(
    async (values: UpdateAccessProfileValues) => {
      await ky.put(`access-control/profile/${accessProfileId}`, {
        json: values,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: "Access Profile Updated",
          status: "success",
        });
        navigate(
          `/property-organizations/${propertyOrganizationId}/access-control/profiles`
        );
        queryClient.invalidateQueries(
          getAccessProfileQueryKey(accessProfileId as string)
        );
        setUpdatingAccessProfile(undefined);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  return {
    isUpdatingAccessProfile: !!updatingAccessProfile,
    updateAccessProfileMutation: updateAccessProfileMutation,
    setUpdatingAccessProfile: setUpdatingAccessProfile,
  };
};

export const useUpdateEntrySchedule = (
  setError: UseFormSetError<UpdateEntryScheduleValues>,
  getValues: UseFormGetValues<UpdateEntryScheduleValues>
) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const ky = useKy();
  const navigate = useNavigate();
  const { propertyOrganizationId, entryScheduleId } = useParams();

  const [updatingEntrySchedule, setUpdatingEntrySchedule] = useState<
    EntrySchedule | undefined
  >();

  const updateEntryScheduleMutation = useMutation<
    void,
    HTTPError,
    UpdateEntryScheduleValues
  >(
    async (values: UpdateEntryScheduleValues) => {
      await ky.patch(`access-control/entry-schedule/${entryScheduleId}`, {
        json: values,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: "Entry Schedule Updated",
          status: "success",
        });
        navigate(
          `/property-organizations/${propertyOrganizationId}/access-control/entry-schedules`
        );
        queryClient.invalidateQueries(
          getEntryScheduleQueryKey(entryScheduleId as string)
        );
        setUpdatingEntrySchedule(undefined);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  return {
    isUpdatingEntrySchedule: !!updatingEntrySchedule,
    updateEntryScheduleMutation: updateEntryScheduleMutation,
    setUpdatingEntrySchedule: setUpdatingEntrySchedule,
  };
};
