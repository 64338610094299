import React, { FC, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Loading } from "src/common/Loading";
import { IntercomActivation, IntercomGeneration } from "src/common/types";
import useNavigateToIntercomEntryPointIfGeneration from "src/routes/IntercomUnits/ViewIntercomUnit/useNavigateToIntercomEntryPointIfGeneration";
import { ViewIntercomUnitLayout } from "src/routes/IntercomUnits/ViewIntercomUnit/ViewIntercomUnitLayout";
import { CurrentSplashScreen } from "src/routes/IntercomUnits/ViewIntercomUnit/EditIntercomSplashScreen/CurrentSplashScreen";
import { EditIntercomSplashScreenForm } from "src/routes/IntercomUnits/ViewIntercomUnit/EditIntercomSplashScreen/EditIntercomSplashScreenForm";

/**
 * This component handles view and editing of the intercom's splash screen configuration.
 */
export const EditIntercomSplashScreen: FC = () => {
  const [editing, setEditing] = useState(false);
  useNavigateToIntercomEntryPointIfGeneration(IntercomGeneration.GEN_3);
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  if (!currentActivation) {
    return <Loading />;
  }

  return (
    <ViewIntercomUnitLayout
      title="Intercom Splash Screen"
      subtitle="Design and customize your intercom splash screen. This will be the first thing seen on your intercoms."
    >
      {editing ? (
        <EditIntercomSplashScreenForm
          intercomActivation={currentActivation}
          onComplete={() => setEditing(false)}
        />
      ) : (
        <CurrentSplashScreen
          intercomActivation={currentActivation}
          onClick={() => setEditing(true)}
        />
      )}
    </ViewIntercomUnitLayout>
  );
};
