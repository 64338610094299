import { Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { format } from "date-fns";
import React, { FC } from "react";
import { useOutletContext } from "react-router-dom";

import { Loading } from "src/common/Loading";
import { IntercomActivation } from "src/common/types";
import { ViewIntercomUnitLayout } from "src/routes/IntercomUnits/ViewIntercomUnit/ViewIntercomUnitLayout";

/**
 * This component handles viewing of the cell kits associated with the intercom.
 */
export const CellUpgradeKit: FC = () => {
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  if (!currentActivation) {
    return <Loading />;
  }

  const { cellKitAssignmentInfo } = currentActivation;

  return (
    <ViewIntercomUnitLayout
      title="Cell Upgrade Kit"
      subtitle="View Cell Service information. In order to make changes, please contact your dealer."
    >
      {!cellKitAssignmentInfo ? (
        <Flex>There is no cell kit assigned to this intercom.</Flex>
      ) : (
        <Table variant="unstyled">
          <Thead backgroundColor={"gray.50"}>
            <Tr>
              <Th>Serial Number</Th>
              <Th>Carrier</Th>
              <Th>Status</Th>
              <Th>Date Activated</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{cellKitAssignmentInfo.serialNumber}</Td>
              <Td>{cellKitAssignmentInfo.carrier || "-"}</Td>
              <Td>
                {cellKitAssignmentInfo.activatedAt ? "ACTIVE" : "INACTIVE"}
              </Td>
              <Td>
                {cellKitAssignmentInfo.activatedAt
                  ? format(new Date(cellKitAssignmentInfo.activatedAt), "P")
                  : "-"}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      )}
    </ViewIntercomUnitLayout>
  );
};
