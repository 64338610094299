import React from "react";
import * as Yup from "yup";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { noop } from "lodash";

import { HTTPError, useKy } from "src/common/ky";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { handleHookFormHTTPError } from "src/common/form";

interface UnitGroupValues {
  name: string;
  propertyOrganizationId: string;
}

const UnitGroupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Unit Group Name is required"),
});

export const AddUnitGroupForm = () => {
  const { propertyOrganizationId } = useParams();
  const navigation = useNavigate();
  const toast = useToast();
  const ky = useKy();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<UnitGroupValues>({
    resolver: yupResolver(UnitGroupValidationSchema),
    defaultValues: {
      name: "",
    },
  });

  const addUnitGroup = useMutation<void, HTTPError, UnitGroupValues>(
    async (values: UnitGroupValues) => {
      await ky.post("unit-groups", {
        json: { ...values, propertyOrganizationId },
      });
    },
    {
      onSuccess: () => {
        toast({
          description: "Unit Group successfully added",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigation(`/property-organizations/${propertyOrganizationId}/units`);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );
  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Unit Group</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) => {
            return addUnitGroup.mutateAsync(data).catch(noop);
          })}
          style={{ width: "100%" }}
        >
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem colSpan={1}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isRequired isInvalid={!!errors.name}>
                    <FormLabel htmlFor="name">Unit Group Name</FormLabel>
                    <Input {...field} id="name" placeholder="Unit Group Name" />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
          </Grid>

          <Flex justifyContent="flex-end">
            <ButtonGroup>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="brand.blue"
              >
                Add Unit Group
              </Button>
              <Link
                as={RouterLink}
                to={`/property-organizations/${propertyOrganizationId}/units`}
              >
                <Button>Cancel</Button>
              </Link>
            </ButtonGroup>
          </Flex>
        </form>
      </PageContent>
    </>
  );
};
