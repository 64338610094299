import React from "react";
import { Alert, AlertIcon, Stack, Text } from "@chakra-ui/react";

import { Loading } from "src/common/Loading";
import { useIntercomHealthQuery } from "src/common/queries";
import { IntercomComponentStatus } from "src/common/types";
import { IntercomHealthy } from "src/common/icons/IntercomHealthy";
import { IntercomUnhealthy } from "src/common/icons/IntercomUnhealthy";
import { formatDate } from "src/common/formatters";
import { ComponentList } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomSystem/IntercomHealthStatus/ComponentList";

export const IntercomHealthStatus = () => {
  const healthQuery = useIntercomHealthQuery();

  if (healthQuery.isLoading) {
    return <Loading />;
  }

  if (healthQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        Error loading intercom health. Please try again later.
      </Alert>
    );
  }

  if (!healthQuery.data || healthQuery.data?.intercomReportId === null) {
    return (
      <Alert status="warning">
        <AlertIcon />
        No intercom health data available yet. Please check again later.
      </Alert>
    );
  }

  const isHealthy = healthQuery.data.componentReports.every(
    (c) => c.status === IntercomComponentStatus.ONLINE
  );

  const Icon = isHealthy ? IntercomHealthy : IntercomUnhealthy;
  const statusText = isHealthy
    ? "Intercom health is great!"
    : "Intercom needs attention";
  const timestamp = healthQuery.data.createdAt
    ? formatDate(healthQuery.data.createdAt)
    : "Timestamp unknown";

  return (
    <Stack alignItems="center" direction="row" gap={3}>
      <Icon w="125px" h="170px" />
      <Stack>
        <Text fontSize="24px" fontWeight="bold">
          {statusText}
        </Text>
        <Text>{timestamp}</Text>
        <ComponentList components={healthQuery.data.componentReports} />
      </Stack>
    </Stack>
  );
};
