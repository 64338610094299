import { Box, BoxProps, Container } from "@chakra-ui/react";
import * as React from "react";

import { Card } from "src/layout/Card";

export const PageContent = (props: BoxProps) => (
  <Box as="main" py="4" {...props}>
    <Container maxW="7xl">
      <Card>{props.children}</Card>
    </Container>
  </Box>
);
