import React, { useEffect } from "react";
import {
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { HiOutlineDotsVertical, HiOutlineTrash } from "react-icons/hi";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { SortableTh } from "src/common/SortableTh";
import { TabLink } from "src/layout/TabLink";
import { useTenantGuestsQuery } from "src/routes/TenantGuestsList/queries";
import { TenantGuestsListFilterBar } from "src/routes/TenantGuestsList/TenantGuestsListFilterBar";
import { useDeleteTenantGuest } from "src/routes/TenantGuestsList/hooks";
import { useFlags } from "src/common/useFlags";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const TenantGuestsList = () => {
  const { propertyOrganizationId } = useParams();
  const query = useTenantGuestsQuery();
  const flags = useFlags();
  const navigate = useNavigate();

  const { isDeletingGuest, deleteGuestMutation, setDeletingGuestId } =
    useDeleteTenantGuest();

  useEffect(() => {
    const newSp = new URLSearchParams(location.search);
    newSp.set("tenantOrPropertyGuest", "tenant");
    newSp.sort();

    if (newSp.toString()) {
      navigate(`?${newSp.toString()}`);
    }
  }, []);

  let contents;
  if (query.isLoading || !query.data) {
    contents = <Loading />;
  } else if (!query.data.length) {
    contents = <Flex>There are no guests.</Flex>;
  } else {
    contents = (
      <Table variant="simple">
        <Thead>
          <Tr>
            <SortableTh sortKey="name">Name</SortableTh>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Tenant Name</Th>
            <Th>Access Credential</Th>
            <SortableTh sortKey="createdAt">Date Added</SortableTh>
            <Th>{/*menu column*/}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {query.data.map((guest) => (
            <Tr key={guest.guest_id}>
              <Td>{guest.name}</Td>
              <Td>{guest.email}</Td>
              <Td>{formatPhoneNumber(guest.phone)}</Td>
              {guest.tenant ? (
                <Td>{`${guest.tenant.user.first_name} ${guest.tenant.user.last_name}`}</Td>
              ) : (
                <Td></Td>
              )}
              <Td>{guest.access_code}</Td>
              <Td>{format(new Date(guest.created_at), "P")}</Td>
              <Td>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Actions"
                    icon={<HiOutlineDotsVertical />}
                    variant="clear"
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      justifyContent="space-between"
                      onClick={() => {
                        setDeletingGuestId(guest.guest_id);
                      }}
                    >
                      Delete Guest
                      <HiOutlineTrash />
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  return (
    <>
      <PageHeader buttons={<TenantGuestsListFilterBar />}>
        <PageHeaderTitle>Tenant Guests</PageHeaderTitle>
        <PageHeaderTabs>
          {flags.units && (
            <TabLink
              key="units"
              to={`/property-organizations/${propertyOrganizationId}/units`}
              label="Units"
              end
            />
          )}
          <TabLink
            key="tenants"
            to={`/property-organizations/${propertyOrganizationId}/units/tenants`}
            label="Tenants"
            end
          />
          <TabLink
            key="tenant-guests"
            to={`/property-organizations/${propertyOrganizationId}/units/tenants/guests?tenantOrPropertyGuest=tenant`}
            label="Guests"
            end
          />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>{contents}</PageContent>

      <Modal
        isOpen={isDeletingGuest}
        onClose={() => {
          setDeletingGuestId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Guest</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingGuestId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteGuestMutation.mutate()}
              isDisabled={deleteGuestMutation.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
