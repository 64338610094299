import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { Building as BuildingIcon } from "src/common/icons/Building";
import {
  PageHeader,
  PageHeaderSubtitle,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { useIsAdmin } from "src/common/useIsAdmin";
import { Loading } from "src/common/Loading";
import { useGetPropertyAccessPanelQuery } from "src/routes/AccessControl/queries";
import { TabLink } from "src/layout/TabLink";
import { PageContent } from "src/layout/PageContent";
import { Profiles } from "src/routes/AccessControl/Profiles";
import { EntryPointList } from "src/routes/AccessControl/EntryPointList";
import { EntrySchedules } from "src/routes/AccessControl/EntrySchedules";
import { AccessPanel } from "src/routes/AccessControl/AccessPanel";
import { Settings } from "src/routes/AccessControl/Settings";

export type AccessControlProps = {
  tab:
    | "profiles"
    | "entry-points"
    | "entry-schedules"
    | "access-panel"
    | "settings";
};

export const AccessControl = ({ tab }: AccessControlProps) => {
  const isAdmin = useIsAdmin();
  const { propertyOrganizationId } = useParams();
  const propertyPanelQuery = useGetPropertyAccessPanelQuery(
    propertyOrganizationId as string
  );

  if (propertyPanelQuery.isLoading || !propertyPanelQuery.isSuccess) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Access Control</PageHeaderTitle>
        <PageHeaderSubtitle>Manage access control</PageHeaderSubtitle>
        <PageHeaderTabs>
          <TabLink
            key="profiles"
            to={`/property-organizations/${propertyOrganizationId}/access-control/profiles`}
            label="Profiles"
            end
          />
          <TabLink
            key="entry-points"
            to={`/property-organizations/${propertyOrganizationId}/access-control/entry-points`}
            label="Entry Points"
            end
          />
          <TabLink
            key="entry-schedules"
            to={`/property-organizations/${propertyOrganizationId}/access-control/entry-schedules`}
            label="Entry Schedules"
            end
          />
          <TabLink
            key={"access-panel"}
            to={`/property-organizations/${propertyOrganizationId}/access-control/access-panel`}
            label={"Access Panel"}
            end
          />
          {isAdmin && (
            <TabLink
              key={"settings"}
              to={`/property-organizations/${propertyOrganizationId}/access-control/settings`}
              label={"Settings"}
              end
            />
          )}
        </PageHeaderTabs>
      </PageHeader>
      {propertyPanelQuery.data ? (
        <PageContent>
          {tab === "profiles" ? <Profiles /> : null}
          {tab === "entry-points" ? <EntryPointList /> : null}
          {tab === "entry-schedules" ? <EntrySchedules /> : null}
          {tab === "access-panel" ? (
            <AccessPanel
              propertyOrganizationId={propertyOrganizationId as string}
            />
          ) : null}
          {tab === "settings" ? (
            <Settings
              propertyOrganizationId={propertyOrganizationId as string}
            />
          ) : null}
        </PageContent>
      ) : (
        <VStack mt={4} spacing={4}>
          <BuildingIcon />
          <Text
            color="gray.500"
            fontWeight="medium"
            fontSize="lg"
            align="center"
          >
            Access Control is not configured at this property. <br />
            Connect with your dealer.
          </Text>
        </VStack>
      )}
    </>
  );
};
