import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { HTTPError } from "ky";

import { KyOptions, useKy } from "src/common/ky";
import {
  AccessProfile,
  EntryGroup,
  EntryPoint,
  EntrySchedule,
  Panel,
  AccessCredential,
} from "src/common/types";

export const getAccessProfilesByPropertyQueryKey = () => {
  return ["ACCESS_PROFILES_BY_PROPERTY"];
};
export const useGetAccessProfilesByPropertyQuery = () => {
  const { propertyOrganizationId } = useParams();
  const ky = useKy();
  const searchParams = new URLSearchParams(location.search);

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  return useQuery(
    getAccessProfilesByPropertyQueryKey(),
    async () =>
      await ky
        .get(`access-control/profile`, { searchParams })
        .json<AccessProfile[]>()
  );
};

export const getEntryGroupsQueryKey = (propertyOrganizationId: string) => [
  "GET_ENTRY_GROUP_QUERY",
  propertyOrganizationId,
];

export const useGetEntryGroupsQuery = () => {
  const { propertyOrganizationId } = useParams();
  const ky = useKy();

  return useQuery(
    getEntryGroupsQueryKey(propertyOrganizationId as string),
    async () =>
      await ky
        .get(
          `access-control/entry-group/property-organization/${propertyOrganizationId}`
        )
        .json<EntryGroup[]>()
  );
};

export const getEntryPointsQueryKey = (propertyOrganizationId: string) => [
  "GET_ENTRY_POINTS_QUERY",
  propertyOrganizationId,
];

export const useGetEntryPointsQuery = (propertyOrganizationId: string) => {
  const ky = useKy();

  return useQuery<EntryPoint[], HTTPError>(
    getEntryPointsQueryKey(propertyOrganizationId as string),
    async () =>
      await ky
        .get(
          `access-control/entry-point/property-organization/${propertyOrganizationId}`
        )
        .json<EntryPoint[]>()
  );
};

export const getAccessProfileQueryKey = (profileId: string) => [
  "GET_ACCESS_PROFILE_QUERY_KEY",
  profileId,
];
export const useGetAccessProfileQuery = (
  getAccessProfileOnSuccess: (profile: AccessProfile) => void
) => {
  const { accessProfileId } = useParams();
  const ky = useKy();

  return useQuery<AccessProfile, HTTPError>(
    getAccessProfileQueryKey(accessProfileId as string),
    async () =>
      await ky
        .get(`access-control/profile/${accessProfileId}`)
        .json<AccessProfile>(),
    {
      onSuccess: getAccessProfileOnSuccess,
    }
  );
};

export const getEntrySchedulesQueryKey = (propertyOrganizationId: string) => [
  "GET_ENTRY_SCHEDULE_QUERY",
  propertyOrganizationId,
];

export const useGetEntrySchedulesQuery = () => {
  const { propertyOrganizationId } = useParams();
  const ky = useKy();

  return useQuery(
    getEntrySchedulesQueryKey(propertyOrganizationId as string),
    async () =>
      await ky
        .get(
          `access-control/entry-schedule/property-organization/${propertyOrganizationId}`
        )
        .json<EntrySchedule[]>()
  );
};

export const getPropertyAccessPanelQueryKey = (
  propertyOrganizationId: string | undefined
) => {
  return ["PROPERTY_ACCESS_PANEL_QUERY", propertyOrganizationId];
};

export const useGetPropertyAccessPanelQuery = (
  propertyOrganizationId: string,
  config?: {
    kyOptions?: KyOptions;
  }
) => {
  const ky = useKy(config?.kyOptions);

  return useQuery(
    getPropertyAccessPanelQueryKey(propertyOrganizationId),
    async () =>
      await ky
        .get(
          `access-control/panel/property-organization/${propertyOrganizationId}`
        )
        .json<Panel | null>()
  );
};

export const getEntryScheduleQueryKey = (entryScheduleId: string) => [
  "GET_ENTRY_SCHEDULE_QUERY_KEY",
  entryScheduleId,
];

export const useGetEntryScheduleQuery = (
  getEntryScheduleOnSuccess: (entrySchedule: EntrySchedule) => void
) => {
  const { entryScheduleId } = useParams();
  const ky = useKy();

  return useQuery<EntrySchedule, HTTPError>(
    getEntryScheduleQueryKey(entryScheduleId as string),
    async () =>
      await ky
        .get(`access-control/entry-schedule/${entryScheduleId}`)
        .json<EntrySchedule>(),
    {
      onSuccess: getEntryScheduleOnSuccess,
    }
  );
};

export const useGetSettingsQuery = (propertyOrganizationId: string) => {
  const ky = useKy();

  return useQuery(
    getSettingsQueryKey(),
    async () =>
      await ky.get(`access-control/settings/${propertyOrganizationId}`).json<{
        lockdownCredentials: AccessCredential[];
        alarmResetCredentials: AccessCredential[];
        combinedCredentials: AccessCredential[];
      }>()
  );
};

export const getSettingsQueryKey = () => ["SETTINGS_BY_PROPERTY"];
