import React, { useMemo, useState } from "react";
import {
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { HiOutlineDotsVertical, HiOutlineSearch } from "react-icons/hi";
import { GoX } from "react-icons/go";
import { sortBy } from "lodash";

import {
  useGetEntryGroupsQuery,
  useGetEntryPointsQuery,
} from "src/routes/AccessControl/queries";
import { Loading } from "src/common/Loading";
import { EntryGroup } from "src/common/types";

export const EntryPointList = () => {
  const { propertyOrganizationId } = useParams();
  const entryPointsQuery = useGetEntryPointsQuery(
    propertyOrganizationId as string
  );
  const entryGroupsQuery = useGetEntryGroupsQuery();

  const [search, setSearch] = useState("");
  const entryPoints = useMemo(
    () =>
      sortBy(entryPointsQuery.data ?? [], [(v) => v.displayName.toString()]),
    [entryPointsQuery.data]
  );
  const entryGroups = useMemo(
    () => entryGroupsQuery.data ?? [],
    [entryGroupsQuery.data]
  );
  const filteredEntryPoints = useMemo(
    () =>
      entryPoints.filter(
        (ep) =>
          ep.displayName
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase()) ||
          ep.entryGroupIds
            .map(
              (id) =>
                entryGroups.find((eg) => eg.entryGroupId === id)?.displayName
            )
            .some(
              (egName) =>
                egName &&
                egName.toLowerCase().includes(search.trim().toLowerCase())
            )
      ),
    [search, entryPoints, entryGroups]
  );

  if (
    entryPointsQuery.isLoading ||
    !entryPointsQuery.isSuccess ||
    entryGroupsQuery.isLoading ||
    !entryGroupsQuery.isSuccess
  ) {
    return <Loading />;
  }

  return (
    <>
      <Stack
        direction={{ base: "column", lg: "row" }}
        alignItems={{ base: "stretch", lg: "center" }}
        justifyContent={"space-between"}
      >
        <Text fontSize="xl" fontWeight={600}>
          Entry Points
        </Text>
        <HStack flex={1} justifyContent={"end"}>
          <InputGroup width={"100%"} maxWidth={500}>
            <InputLeftElement pointerEvents="none" color="gray.300">
              <HiOutlineSearch />
            </InputLeftElement>
            <Input
              value={search}
              onChange={({ currentTarget: { value } }) => setSearch(value)}
              placeholder="Search by Name or Entry Group"
            />
            <InputRightElement>
              <Button variant="ghost" onClick={() => setSearch("")}>
                <GoX />
              </Button>
            </InputRightElement>
          </InputGroup>
        </HStack>
      </Stack>
      {filteredEntryPoints.length ? (
        <Table>
          <Thead>
            <Tr>
              <Th>Display Name</Th>
              <Th>Entry Groups</Th>
              <Th>Schedule Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredEntryPoints.map((entryPoint) => {
              return (
                <Tr key={entryPoint.entryPointId}>
                  <Td>{entryPoint.displayName}</Td>
                  <Td>
                    {(
                      entryPoint.entryGroupIds
                        .map((egId) =>
                          entryGroupsQuery.data.find(
                            (group) => group.entryGroupId === egId
                          )
                        )
                        .filter((v) => v) as EntryGroup[]
                    ).map((entryGroup) => (
                      <Text key={entryGroup.entryGroupId}>
                        {entryGroup.displayName}
                      </Text>
                    ))}
                  </Td>
                  <Td></Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Actions"
                        icon={<HiOutlineDotsVertical />}
                        variant="clear"
                      >
                        Actions
                      </MenuButton>
                      <MenuList></MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : entryPoints.length ? (
        <Text>There are no entry points matching your search</Text>
      ) : (
        <Text>There are no entry points</Text>
      )}
    </>
  );
};
