import React, { useMemo } from "react";
import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Link,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { HiOutlinePlus, HiTrash } from "react-icons/hi";
import { intersection } from "lodash";

import { HTTPError, useKy } from "src/common/ky";
import { useFlags } from "src/common/useFlags";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { AccessCredential } from "src/common/types";
import { noop } from "src/common/util";
import { useInvalidateTenantsQuery } from "src/routes/TenantList/queries";
import { useGetAccessProfilesQuery } from "src/routes/AccessProfile/queries";
import { Loading } from "src/common/Loading";
import { handleHookFormHTTPError } from "src/common/form";
import { useHasAccessControl } from "src/common/useHasAccessControl";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface AddTenantValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  propertyOrganizationId: string;
  unitId: string;
  enableDirectoryListing: boolean;
  physicalAccessCredentials: Partial<AccessCredential>[];
  accessProfileIds: string[];
}

const AddTenantValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phone: YupPossiblePhoneNumber({ required: true }),
  email: Yup.string().email().required().label("Email"),
  enableDirectoryListing: Yup.boolean()
    .required()
    .label("Enable Directory Listing"),
  physicalAccessCredentials: Yup.array(
    Yup.object().shape({
      code: Yup.string()
        .length(5)
        .matches(/\d\d\d\d\d/, "${label} must be numeric")
        .test(
          "is-valid-code",
          "${label} must be between 00000 and 65535",
          (code) => Boolean(code && code >= "00000" && code <= "65535")
        )
        .required()
        .label("Access Code"),
    })
  ),
  accessProfileIds: Yup.array(Yup.string().required()).label("Access Profiles"),
});

export const AddTenant = () => {
  const { propertyOrganizationId, unitId } = useParams();
  const toast = useToast();
  const ky = useKy();
  const flags = useFlags();
  const navigate = useNavigate();
  const invalidateTenantsQuery = useInvalidateTenantsQuery();
  const getAccessProfilesQuery = flags.accessControl
    ? useGetAccessProfilesQuery()
    : null;
  const hasAccessControl = useHasAccessControl(propertyOrganizationId);

  const backUrl = useMemo(
    () =>
      `/property-organizations/${propertyOrganizationId}/units${
        unitId ? `/${unitId}` : `/tenants`
      }`,
    [propertyOrganizationId, unitId]
  );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<AddTenantValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      propertyOrganizationId,
      unitId,
      enableDirectoryListing: true,
      physicalAccessCredentials: [],
      accessProfileIds: [],
    },
    resolver: yupResolver(AddTenantValidationSchema),
  });

  const validAccessProfileIds = useMemo(
    () =>
      (getAccessProfilesQuery?.data ?? []).map(
        (profile) => profile.accessProfileId
      ),
    [getAccessProfilesQuery?.data]
  );

  const {
    fields: physicalAccessCredentialFields,
    append: appendPhysicalAccessCredentialField,
    remove: removePhysicalAccessCredentialField,
  } = useFieldArray({
    control,
    name: "physicalAccessCredentials",
  });

  const addTenant = useMutation<void, HTTPError, AddTenantValues>(
    async (values: AddTenantValues) => {
      await ky.post("tenants", { json: values });
    },
    {
      onSuccess: async () => {
        toast({
          title: "Tenant created.",
          description: "The tenant was created successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        await invalidateTenantsQuery();
        navigate(backUrl);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  if (flags.accessControl) {
    if (
      getAccessProfilesQuery?.isLoading ||
      !getAccessProfilesQuery?.isSuccess
    ) {
      return <Loading />;
    }
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link as={RouterLink} to={backUrl}>
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Tenant</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            addTenant.mutateAsync(data).catch(noop)
          )}
        >
          <Grid gap={4}>
            <GridItem>
              <Controller
                name="firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isRequired isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="firstName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isRequired isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="lastName"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isRequired isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="phone">Phone Number</FormLabel>
                    <PhoneNumberInput
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="phone"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isRequired isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      width={["100%", "100%", "50%", "50%"]}
                      {...field}
                      id="email"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <Controller
              name="enableDirectoryListing"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="enableDirectoryListing" mb="0">
                    Enable Directory Listing
                  </FormLabel>
                  <Switch
                    id="enableDirectoryListing"
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />

            <GridItem
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              width={["100%", "100%", "50%", "50%"]}
            >
              <FormLabel>Access Credentials</FormLabel>
              {physicalAccessCredentialFields.length ? (
                <Button
                  leftIcon={<HiOutlinePlus />}
                  variant="outline"
                  colorScheme="brand.blue"
                  onClick={() =>
                    appendPhysicalAccessCredentialField({
                      code: "",
                      propertyOrganizationId,
                    })
                  }
                >
                  Add Credential
                </Button>
              ) : null}
            </GridItem>

            {physicalAccessCredentialFields.map((item, index) => (
              <GridItem
                display="flex"
                key={item.id}
                width={["100%", "100%", "50%", "50%"]}
              >
                <Controller
                  name={`physicalAccessCredentials.${index}.code`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={!!fieldState.error}
                      isDisabled={!!item.accessCredentialId}
                    >
                      <Input
                        {...field}
                        maxLength={5}
                        isDisabled={!!item.accessCredentialId}
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <IconButton
                  colorScheme="brand.red"
                  variant="link"
                  aria-label="Delete access credential"
                  icon={<HiTrash />}
                  size="lg"
                  onClick={() => removePhysicalAccessCredentialField(index)}
                />
              </GridItem>
            ))}

            {errors[`physicalAccessCredentials`]?.message ? (
              <GridItem display="flex" width={["100%", "100%", "50%", "50%"]}>
                <FormControl isInvalid={true}>
                  <FormErrorMessage>
                    {errors[`physicalAccessCredentials`]?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            ) : null}

            {!physicalAccessCredentialFields.length ? (
              <GridItem
                textAlign="center"
                width={["100%", "100%", "50%", "50%"]}
              >
                <Text>This user doesn’t have any</Text>
                <Text marginBottom="8px">access credentials yet</Text>
                <Button
                  leftIcon={<HiOutlinePlus />}
                  variant="outline"
                  colorScheme="brand.blue"
                  onClick={() =>
                    appendPhysicalAccessCredentialField({
                      code: "",
                      propertyOrganizationId,
                    })
                  }
                >
                  Add Credential
                </Button>
              </GridItem>
            ) : null}

            {flags.accessControl &&
            hasAccessControl &&
            getAccessProfilesQuery?.data?.length ? (
              <GridItem>
                <Controller
                  name="accessProfileIds"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl isInvalid={!!fieldState.error}>
                        <FormLabel htmlFor="access-profiles">
                          Add Access Profiles
                        </FormLabel>
                        <CheckboxGroup defaultValue={[]}>
                          <Stack pl={1} mt={1} spacing={5}>
                            {getAccessProfilesQuery.data?.map((profile) => (
                              <Checkbox
                                key={profile.accessProfileId}
                                value={profile.accessProfileId}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  const value = e.target.value;
                                  const fieldValue = field.value;

                                  field.onChange(
                                    isChecked
                                      ? intersection(validAccessProfileIds, [
                                          ...fieldValue,
                                          value,
                                        ])
                                      : intersection(
                                          validAccessProfileIds,
                                          fieldValue.filter(
                                            (id) => id !== value
                                          )
                                        )
                                  );
                                }}
                                colorScheme="brand.blue"
                                fontWeight={400}
                              >
                                {profile.displayName}
                              </Checkbox>
                            ))}
                          </Stack>
                        </CheckboxGroup>
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />
              </GridItem>
            ) : null}

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link as={RouterLink} to={backUrl}>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Add Tenant &amp; Send Invite
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};
