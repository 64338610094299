import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useOutletContext } from "react-router-dom";

import { Loading } from "src/common/Loading";
import { Card } from "src/layout/Card";
import { useIntercomFacePlateLogsQuery } from "src/common/queries";
import { IntercomActivation } from "src/common/types";
import { formatDate } from "src/common/formatters";

export const IntercomFacePlateLogs = () => {
  const facePlateLogs = useIntercomFacePlateLogsQuery();
  const intercomActivation =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  if (facePlateLogs.isLoading) {
    return <Loading />;
  }

  if (!facePlateLogs.data || facePlateLogs.data?.length === 0) {
    return (
      <Card>
        <Text>No faceplate logs registered for this intercom</Text>
      </Card>
    );
  }

  return (
    <Table
      backgroundColor="white"
      borderBottomRadius="12px"
      shadow="base"
      variant="simple"
    >
      <Thead backgroundColor={"gray.50"}>
        <Tr>
          <Th>Intercom</Th>
          <Th>Status</Th>
          <Th>Date & Time</Th>
        </Tr>
      </Thead>
      <Tbody>
        {facePlateLogs.data.map((facePlateLog) => (
          <Tr key={facePlateLog.intercomFaceplateLogId}>
            <Td fontSize="14px" fontWeight="semibold">
              {intercomActivation.currentActivation.name}
            </Td>
            <Td fontSize="14px" fontWeight="semibold">
              {facePlateLog.status}
            </Td>
            <Td fontSize="14px" fontWeight="semibold">
              {formatDate(facePlateLog.createdAt, "long")}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
