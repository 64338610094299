export const trimTrailingSlash = (pathname: string) =>
  pathname.replace(/\/$/g, "");

export const pluralize = (
  count: number,
  singular: string,
  plural: string
): string => {
  if (count === 1) {
    return singular;
  }

  return plural;
};

export const downloadFile = async (response: Response, filename: string) => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

/**
 * Simple function that parses a string or number to a float. Will default to 0 if not a number.
 */
export const safeParse = (numberString: string | number | null): number => {
  const number =
    typeof numberString === "string" ? parseFloat(numberString) : numberString;
  return number === null || isNaN(number) ? 0 : number;
};

export const isSupportedValue = (value: string, obj: Record<string, string>) =>
  Object.values(obj).includes(value);

export const convertToBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const cleanSearchParams = (
  allowedKeys: string[],
  searchParams: URLSearchParams
) => {
  for (const key of Array.from(searchParams.keys())) {
    if (!allowedKeys.includes(key)) {
      searchParams.delete(key);
    }
  }

  return searchParams;
};
