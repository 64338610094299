import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IntercomGeneration } from "src/common/types";
import useIntercomDetails from "src/routes/IntercomUnits/ViewIntercomUnit/useIntercomDetails";

const useNavigateToIntercomEntryPointIfGeneration = (
  invalidGeneration: IntercomGeneration
) => {
  const navigate = useNavigate();
  const { currentActivation, generation } = useIntercomDetails();

  useEffect(() => {
    if (currentActivation && generation === invalidGeneration) {
      navigate(
        `/property-organizations/${currentActivation.propertyOrganizationId}/intercom-units/${currentActivation.intercomActivationId}`
      );
    }
  }, [currentActivation, generation]);
};

export default useNavigateToIntercomEntryPointIfGeneration;
