import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router";

import { Loading } from "src/common/Loading";
import { trimTrailingSlash } from "src/common/util";
import { SelectPropertyOrganization } from "src/routes/PropertyOrganizationSelector/SelectPropertyOrganization";
import { useMyPropertyOrganizationsQuery } from "src/common/queries";

export const PropertyOrganizationSelector = () => {
  const location = useLocation();
  const myPropertyOrganizations = useMyPropertyOrganizationsQuery();

  if (!myPropertyOrganizations.isSuccess) {
    return <Loading />;
  }

  if (trimTrailingSlash(location.pathname) === "/property-organizations") {
    return myPropertyOrganizations.data.length === 1 ? (
      <Navigate to={myPropertyOrganizations.data[0].propertyOrganizationId} />
    ) : (
      <SelectPropertyOrganization
        propertyOrganizations={myPropertyOrganizations.data}
      />
    );
  }

  return <Outlet />;
};
