import { useOutletContext } from "react-router-dom";

import { IntercomActivation } from "src/common/types";

const useIntercomDetails = () => {
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();

  if (!currentActivation) {
    return { generation: "Unknown", screenSize: "Unknown" };
  }

  const [generation, screenSize] = currentActivation.intercomModel.split(" ");

  return { generation, screenSize, currentActivation };
};

export default useIntercomDetails;
