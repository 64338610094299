import React from "react";
import { Button, ButtonGroup, Flex, Link } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

import { useIsAdmin } from "src/common/useIsAdmin";

export const UnitDetailFilterBar = () => {
  const { propertyOrganizationId, unitId } = useParams();
  const isAdmin = useIsAdmin();

  return (
    <Flex flexDirection="row">
      <ButtonGroup gap="8px">
        {isAdmin ? (
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units/${unitId}/edit`}
          >
            <Button colorScheme="brand.blueGreen">Edit Unit</Button>
          </Link>
        ) : null}
        <Link
          as={RouterLink}
          to={`/property-organizations/${propertyOrganizationId}/units/${unitId}/tenants/add`}
        >
          <Button colorScheme="brand.blue">Add Tenant</Button>
        </Link>
      </ButtonGroup>
    </Flex>
  );
};
