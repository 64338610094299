import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";

import { HTTPError, useKy } from "src/common/ky";

export const useDeleteUnit = () => {
  const ky = useKy();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [deletingUnitId, setDeletingUnitId] = useState<string | undefined>();

  const deleteUnit = useMutation<void, HTTPError>(
    async () => {
      await ky.delete(`units/${deletingUnitId}`);
    },
    {
      onSuccess: () => {
        toast({
          title: "Unit deleted",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries("UNITS");
        setDeletingUnitId(undefined);
      },
      onError: async (error) => {
        let message = error.message;

        if (error.response.status < 500) {
          const body: { message: string } = await error.response.json();
          message = body.message;
        }

        toast({
          title: "Failed to delete unit",
          description: message,
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  return {
    isDeletingUnit: !!deletingUnitId,
    deleteUnit,
    setDeletingUnitId,
  };
};
