import React, { useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { HTTPError, useKy } from "src/common/ky";
import {
  getUnitGroupQueryKey,
  useGetUnitGroupQuery,
} from "src/routes/UnitGroups/queries";
import { noop } from "src/common/util";
import { handleHookFormHTTPError } from "src/common/form";

interface UnitGroupValues {
  name: string;
}

const UnitGroupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Unit Group Name is required"),
});

export const EditUnitGroup = () => {
  const { propertyOrganizationId, unitGroupId } = useParams();
  const query = useGetUnitGroupQuery();
  const queryClient = useQueryClient();

  const ky = useKy();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    getValues,
  } = useForm<UnitGroupValues>({
    resolver: yupResolver(UnitGroupValidationSchema),
    defaultValues: {
      name: "",
    },
  });

  const editUnitGroup = useMutation<void, HTTPError, UnitGroupValues>(
    async (values) => {
      await ky.put(`unit-groups/${unitGroupId}`, { json: values });
    },
    {
      onSuccess: () => {
        toast({
          title: "Unit Group Updated",
          status: "success",
        });
        queryClient.invalidateQueries(getUnitGroupQueryKey(unitGroupId));
        navigate(`/property-organizations/${propertyOrganizationId}/units`);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  useEffect(() => {
    if (query.data) {
      reset({
        name: query.data.unitGroupName,
      });
    }
  }, [query.data, reset]);

  if (query.isLoading || !query.data) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader title="Edit Unit Group">
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Unit Group</PageHeaderTitle>
      </PageHeader>

      <PageContent>
        <form
          onSubmit={handleSubmit((data) => {
            editUnitGroup.mutateAsync(data).catch(noop);
          })}
        >
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem colSpan={1}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <FormControl isInvalid={!!errors.name} isRequired>
                    <FormLabel htmlFor="name">Unit Group Name</FormLabel>
                    <Input {...field} id="name" />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
          </Grid>

          <Flex justifyContent={"flex-end"}>
            <ButtonGroup>
              <Button
                type="submit"
                isLoading={isSubmitting}
                colorScheme="brand.blue"
              >
                Save
              </Button>
              <Link
                as={RouterLink}
                to={`/property-organizations/${propertyOrganizationId}/units`}
              >
                <Button>Cancel</Button>
              </Link>
            </ButtonGroup>
          </Flex>
        </form>
      </PageContent>
    </>
  );
};
