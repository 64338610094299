import { useGetPropertyAccessPanelQuery } from "src/routes/AccessControl/queries";
import { useCurrentPropertyOrganization } from "src/common/queries";

export const useHasAccessControl = (
  propertyOrganizationId: string | undefined
) => {
  if (!propertyOrganizationId) {
    return false;
  }

  const accessPanelQuery = useGetPropertyAccessPanelQuery(
    propertyOrganizationId,
    {
      kyOptions: {
        noLogoutOnForbidden: true,
      },
    }
  );

  const propertyOrganization = useCurrentPropertyOrganization(
    propertyOrganizationId,
    {
      kyOptions: {
        noLogoutOnForbidden: true,
      },
    }
  );

  return (
    !!accessPanelQuery.data && !!propertyOrganization?.accessControlOnboardedAt
  );
};
