import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import React, { ReactNode, useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  HiOutlineDotsVertical,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import { UnitGroupList } from "src/routes/UnitGroups/UnitGroupList";
import { useDeleteUnit } from "src/routes/Unit/hooks";
import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderSubtitle,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { SortableTh } from "src/common/SortableTh";
import { TabLink } from "src/layout/TabLink";
import { UnitListFilterBar } from "src/routes/Unit/UnitListFilterBar";
import { useUnitsQuery } from "src/routes/Unit/queries";
import { formatDate } from "src/common/formatters";
import { useIsAdmin } from "src/common/useIsAdmin";
import { Pagination } from "src/common/Pagination";
import { useFlags } from "src/common/useFlags";

const SectionHeading = ({ children }: { children: ReactNode }) => (
  <Heading as="h2" fontSize="20px" fontWeight="bold" mb={4} mt={6}>
    {children}
  </Heading>
);

const Section = ({
  children,
  title,
}: {
  children?: ReactNode;
  title: string;
  includeCard?: boolean;
}) => (
  <>
    <SectionHeading>{title}</SectionHeading>
    {children ? children : null}
  </>
);

export const UnitList = () => {
  const { propertyOrganizationId } = useParams();
  const query = useUnitsQuery();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const flags = useFlags();
  const { isDeletingUnit, deleteUnit, setDeletingUnitId } = useDeleteUnit();

  const onPageChange = useCallback(
    (nextPage: number) => {
      const newSp = new URLSearchParams(location.search);
      newSp.set("pageNumber", nextPage.toString());
      navigate(`?${newSp.toString()}`);
    },
    [location, navigate]
  );

  return (
    <>
      <PageHeader buttons={<UnitListFilterBar />}>
        <PageHeaderTitle>Units</PageHeaderTitle>
        <PageHeaderSubtitle>
          View Tenant Units and Unit Groups
        </PageHeaderSubtitle>
        <PageHeaderTabs>
          <TabLink
            key="units"
            to={`/property-organizations/${propertyOrganizationId}/units`}
            label="Units"
            end
          />

          <TabLink
            key="tenants"
            to={`/property-organizations/${propertyOrganizationId}/units/tenants`}
            label="Tenants"
            end
          />
          <TabLink
            key="tenant-guests"
            to={`/property-organizations/${propertyOrganizationId}/units/tenants/guests`}
            label="Guests"
            end
          />
        </PageHeaderTabs>
      </PageHeader>
      {flags.units && (
        <Box as="main" py="4">
          <Container maxW="7xl">
            <Section title="Unit Groups">
              <UnitGroupList />
            </Section>
          </Container>
        </Box>
      )}

      <Box as="main">
        <Container maxW="7xl">
          <Section title="Units" />
        </Container>
      </Box>
      <PageContent>
        {query.isLoading || !query.data ? (
          <Loading />
        ) : !query.data.result.length ? (
          <Flex>There are no units.</Flex>
        ) : (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <SortableTh sortKey="name">Unit Name</SortableTh>
                  <SortableTh sortKey="createdAt">Date Added</SortableTh>
                  <Th>Number of Tenants</Th>
                  <SortableTh sortKey="unitGroup">Unit Group</SortableTh>
                  <Th>{/*menu column}*/}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {query.data.result.map((unit) => (
                  <Tr
                    key={unit.unitId}
                    _hover={{ bg: "brand.lightGray.100", cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `/property-organizations/${propertyOrganizationId}/units/${unit.unitId}`
                      );
                    }}
                  >
                    <Td>
                      <Link
                        as={RouterLink}
                        to={`/property-organizations/${propertyOrganizationId}/units/${unit.unitId}`}
                      >
                        {unit.unitName}
                      </Link>
                    </Td>
                    <Td>{formatDate(unit.createdAt, "short")}</Td>
                    <Td>{unit.tenantCount}</Td>
                    <Td>{unit.unitGroupName}</Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Actions"
                          icon={<HiOutlineDotsVertical />}
                          variant="clear"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          Actions
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            justifyContent={"space-between"}
                            isDisabled={!isAdmin}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/property-organizations/${propertyOrganizationId}/units/${unit.unitId}/edit`
                              );
                            }}
                          >
                            Edit Unit
                            <HiOutlinePencil />
                          </MenuItem>
                          <MenuItem
                            justifyContent={"space-between"}
                            isDisabled={!isAdmin}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletingUnitId(unit.unitId);
                            }}
                          >
                            Delete Unit
                            <HiOutlineTrash />
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              unitName="unit"
              total={query.data.total}
              pageNumber={query.data.pageNumber}
              pageSize={query.data.pageSize}
              onPageChange={onPageChange}
            />
          </>
        )}
      </PageContent>

      <Modal
        isOpen={isDeletingUnit}
        onClose={() => {
          setDeletingUnitId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Unit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this unit? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingUnitId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              onClick={async () => {
                deleteUnit.mutate();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
