import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Link,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation, useQueryClient } from "react-query";
import { HiOutlinePlus, HiTrash } from "react-icons/hi";
import * as Yup from "yup";
import { difference, intersection } from "lodash";

import { HTTPError, useKy } from "src/common/ky";
import { AccessCredential, Tenant } from "src/common/types";
import { noop } from "src/common/util";
import { useInvalidateTenantsQuery } from "src/routes/TenantList/queries";
import { useGetAccessProfilesQuery } from "src/routes/AccessProfile/queries";
import { Loading } from "src/common/Loading";
import { useFlags } from "src/common/useFlags";
import { handleHookFormHTTPError } from "src/common/form";
import { useHasAccessControl } from "src/common/useHasAccessControl";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface EditTenantValues {
  firstName: string;
  lastName: string;
  phone: string;
  enableDirectoryListing: boolean;
  physicalAccessCredentials: Partial<AccessCredential>[];
  accessProfileIds: string[];
}

const EditTenantValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phone: YupPossiblePhoneNumber({ required: true }),
  enableDirectoryListing: Yup.boolean()
    .required()
    .label("Enable Directory Listing"),
  physicalAccessCredentials: Yup.array(
    Yup.object().shape({
      code: Yup.string()
        .length(5)
        .matches(/\d\d\d\d\d/, "${label} must be numeric")
        .test(
          "is-valid-code",
          "${label} must be between 00000 and 65535",
          (code) => Boolean(code && code >= "00000" && code <= "65535")
        )
        .required()
        .label("Access Code"),
    })
  ),
  accessProfileIds: Yup.array(Yup.string().required()).label("Access Profiles"),
});

export const EditTenantForm = ({ tenant }: { tenant: Tenant }) => {
  const { propertyOrganizationId, unitId, tenantId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const credentialsChangedDisclosure = useDisclosure();
  const [removeCredentialsConfirmed, setRemoveCredentialsConfirmed] =
    useState<boolean>(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const invalidateTenantsQuery = useInvalidateTenantsQuery();
  const ky = useKy();
  const flags = useFlags();
  const getAccessProfilesQuery = flags.accessControl
    ? useGetAccessProfilesQuery()
    : null;
  const hasAccessControl = useHasAccessControl(propertyOrganizationId);

  const backUrl = useMemo(
    () =>
      `/property-organizations/${propertyOrganizationId}/units${
        unitId ? `/${unitId}` : `/tenants`
      }`,
    [propertyOrganizationId, unitId]
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setError,
  } = useForm<EditTenantValues>({
    defaultValues: {
      firstName: tenant.user.firstName || "",
      lastName: tenant.user.lastName || "",
      phone: tenant.user.phone || "",
      enableDirectoryListing: tenant.enableDirectoryListing,
      physicalAccessCredentials: tenant.physicalAccessCredentials,
      accessProfileIds: tenant.accessProfileIds,
    },
    resolver: yupResolver(EditTenantValidationSchema),
  });

  const {
    fields: physicalAccessCredentialFields,
    append: appendPhysicalAccessCredentialField,
    remove: removePhysicalAccessCredentialField,
  } = useFieldArray({
    control,
    name: "physicalAccessCredentials",
  });

  const editTenant = useMutation<void, HTTPError, EditTenantValues>(
    async (values: EditTenantValues) => {
      await ky.patch(`tenants/${tenantId}`, { json: values });
    },
    {
      onSuccess: async () => {
        toast({
          title: "Tenant edited.",
          description: "The tenant was edited successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        await invalidateTenantsQuery();
        await queryClient.invalidateQueries(["TENANT", tenantId]);
        navigate(backUrl);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const onSubmit = useCallback(
    (data: EditTenantValues) => {
      setRemoveCredentialsConfirmed(false);
      return editTenant
        .mutateAsync({
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          enableDirectoryListing: data.enableDirectoryListing,
          physicalAccessCredentials: data.physicalAccessCredentials,
          accessProfileIds: data.accessProfileIds,
        })
        .catch(noop);
    },
    [editTenant]
  );

  const validAccessProfileIds = useMemo(
    () =>
      (getAccessProfilesQuery?.data ?? []).map(
        (profile) => profile.accessProfileId
      ),
    [getAccessProfilesQuery?.data]
  );

  useEffect(() => {
    if (removeCredentialsConfirmed) {
      onSubmit(getValues());
    }
  }, [removeCredentialsConfirmed]);

  if (
    flags.accessControl &&
    (getAccessProfilesQuery?.isLoading || !getAccessProfilesQuery?.isSuccess)
  ) {
    return <Loading />;
  }

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const credentialsChanged = difference(
          tenant.physicalAccessCredentials.map((pac) => pac.code),
          data.physicalAccessCredentials.map((pac) => pac.code)
        ).length;

        if (credentialsChanged) {
          credentialsChangedDisclosure.onOpen();
        } else {
          return onSubmit({
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            enableDirectoryListing: data.enableDirectoryListing,
            physicalAccessCredentials: data.physicalAccessCredentials,
            accessProfileIds: data.accessProfileIds,
          });
        }
      })}
    >
      <Grid gap={4}>
        <GridItem>
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="firstName">First Name</FormLabel>
                <Input
                  width={["100%", "100%", "50%", "50%"]}
                  {...field}
                  id="firstName"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="lastName">Last Name</FormLabel>
                <Input
                  width={["100%", "100%", "50%", "50%"]}
                  {...field}
                  id="lastName"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <Controller
            name="phone"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl isRequired isInvalid={!!fieldState.error}>
                <FormLabel htmlFor="phone">Phone Number</FormLabel>
                <PhoneNumberInput
                  width={["100%", "100%", "50%", "50%"]}
                  {...field}
                  id="phone"
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </GridItem>

        <GridItem>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            disabled
            width={["100%", "100%", "50%", "50%"]}
            value={tenant.user.email}
            id="email"
          />
        </GridItem>

        <Controller
          name="enableDirectoryListing"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="enableDirectoryListing" mb="0">
                Enable Directory Listing
              </FormLabel>
              <Switch
                id="enableDirectoryListing"
                isChecked={field.value}
                onChange={field.onChange}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />

        <GridItem
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width={["100%", "100%", "50%", "50%"]}
        >
          <FormLabel>Access Credentials</FormLabel>
          {physicalAccessCredentialFields.length ? (
            <Button
              leftIcon={<HiOutlinePlus />}
              variant="outline"
              colorScheme="brand.blue"
              onClick={() =>
                appendPhysicalAccessCredentialField({
                  code: "",
                  propertyOrganizationId,
                })
              }
            >
              Add Credential
            </Button>
          ) : null}
        </GridItem>

        {physicalAccessCredentialFields.map((item, index) => (
          <GridItem
            display="flex"
            key={item.id}
            width={["100%", "100%", "50%", "50%"]}
          >
            <Controller
              name={`physicalAccessCredentials.${index}.code`}
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  isInvalid={!!fieldState.error}
                  isDisabled={!!item.accessCredentialId}
                >
                  <Input
                    {...field}
                    maxLength={5}
                    isDisabled={!!item.accessCredentialId}
                  />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <IconButton
              colorScheme="brand.red"
              variant="link"
              aria-label="Delete access credential"
              icon={<HiTrash />}
              size="lg"
              onClick={() => removePhysicalAccessCredentialField(index)}
            />
          </GridItem>
        ))}

        {errors[`physicalAccessCredentials`]?.message ? (
          <GridItem display="flex" width={["100%", "100%", "50%", "50%"]}>
            <FormControl isInvalid={true}>
              <FormErrorMessage>
                {errors[`physicalAccessCredentials`]?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        ) : null}

        {!physicalAccessCredentialFields.length ? (
          <GridItem textAlign="center" width={["100%", "100%", "50%", "50%"]}>
            <Text>This user doesn’t have any</Text>
            <Text marginBottom="8px">access credentials yet</Text>
            <Button
              leftIcon={<HiOutlinePlus />}
              variant="outline"
              colorScheme="brand.blue"
              onClick={() =>
                appendPhysicalAccessCredentialField({
                  code: "",
                  propertyOrganizationId,
                })
              }
            >
              Add Credential
            </Button>
          </GridItem>
        ) : null}

        {flags.accessControl &&
        hasAccessControl &&
        getAccessProfilesQuery?.data?.length ? (
          <GridItem>
            <Controller
              name="accessProfileIds"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="access-profiles">
                      Add Access Profiles
                    </FormLabel>
                    <CheckboxGroup defaultValue={tenant.accessProfileIds}>
                      <Stack pl={1} mt={1} spacing={5}>
                        {getAccessProfilesQuery.data.map((profile) => (
                          <Checkbox
                            key={profile.accessProfileId}
                            value={profile.accessProfileId}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const value = e.target.value;
                              const fieldValue = field.value;

                              field.onChange(
                                isChecked
                                  ? intersection(validAccessProfileIds, [
                                      ...fieldValue,
                                      value,
                                    ])
                                  : intersection(
                                      validAccessProfileIds,
                                      fieldValue
                                    ).filter((id) => id !== value)
                              );
                            }}
                            colorScheme="brand.blue"
                            fontWeight={400}
                          >
                            {profile.displayName}
                          </Checkbox>
                        ))}
                      </Stack>
                    </CheckboxGroup>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </GridItem>
        ) : null}

        <GridItem>
          <Divider />
        </GridItem>

        <GridItem>
          <ButtonGroup display="flex" justifyContent="flex-end">
            <Link as={RouterLink} to={backUrl}>
              <Button>Cancel</Button>
            </Link>
            <Button
              isDisabled={isSubmitting}
              type="submit"
              colorScheme="brand.blue"
              isLoading={isSubmitting}
            >
              Save Tenant
            </Button>
          </ButtonGroup>
        </GridItem>
      </Grid>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={credentialsChangedDisclosure.onClose}
        isOpen={credentialsChangedDisclosure.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Changes to Access Credentials</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={credentialsChangedDisclosure.onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand.red"
              ml="3"
              onClick={() => setRemoveCredentialsConfirmed(true)}
            >
              Save User
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </form>
  );
};
