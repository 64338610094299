import { Box, Flex, HStack } from "@chakra-ui/react";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useFlags } from "src/common/useFlags";
import { NavItem } from "src/layout/NavItem";
import { useHasAccessControl } from "src/common/useHasAccessControl";

type MobileNavMenuProps = { isOpen?: boolean; closeMenu?: () => void };
const MobileNavMenu = ({ isOpen, closeMenu }: MobileNavMenuProps) => {
  const params = useParams();
  const flags = useFlags();
  const hasAccessControl = useHasAccessControl(params.propertyOrganizationId);

  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="brand.navy"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Box px="4">
        <NavItem.Mobile
          label="Profile"
          to={`/property-organizations/${params.propertyOrganizationId}/profile`}
          closeMenu={closeMenu}
        />
        <NavItem.Mobile
          label="Staff"
          to={`/property-organizations/${params.propertyOrganizationId}/staff`}
          closeMenu={closeMenu}
        />
        <NavItem.Mobile
          label={flags.units ? "Units" : "Tenants"}
          to={`/property-organizations/${params.propertyOrganizationId}/${
            flags.units ? "units" : "units/tenants"
          }`}
          closeMenu={closeMenu}
        />
        <NavItem.Mobile
          label="Access Logs"
          to={`/property-organizations/${params.propertyOrganizationId}/access-logs`}
          closeMenu={closeMenu}
        />
        <NavItem.Mobile
          label="Intercom Units"
          to={`/property-organizations/${params.propertyOrganizationId}/intercom-units`}
          closeMenu={closeMenu}
        />
        <NavItem.Mobile
          label="Dealer"
          to={`/property-organizations/${params.propertyOrganizationId}/dealer`}
          closeMenu={closeMenu}
        />
        {flags.accessControl && hasAccessControl ? (
          <NavItem.Mobile
            label="Access Control"
            to={`/property-organizations/${params.propertyOrganizationId}/access-control`}
            closeMenu={closeMenu}
          />
        ) : null}
        {flags.propertyGuests && (
          <NavItem.Mobile
            label={"Property Guests"}
            to={`/property-organizations/${params.propertyOrganizationId}/guests?tenantOrPropertyGuest=property`}
            closeMenu={closeMenu}
          />
        )}
      </Box>
    </Flex>
  );
};

const DesktopNavMenu = () => {
  const params = useParams();
  const flags = useFlags();
  const hasAccessControl = useHasAccessControl(params.propertyOrganizationId);

  return (
    <HStack spacing="3" flex="1" display={{ base: "none", lg: "flex" }}>
      <NavItem.Desktop
        label="Profile"
        to={`/property-organizations/${params.propertyOrganizationId}/profile`}
      />
      <NavItem.Desktop
        label="Staff"
        to={`/property-organizations/${params.propertyOrganizationId}/staff`}
      />
      <NavItem.Desktop
        label={flags.units ? "Units" : "Tenants"}
        to={`/property-organizations/${params.propertyOrganizationId}/${
          flags.units ? "units" : "units/tenants"
        }`}
      />
      <NavItem.Desktop
        label="Access Logs"
        to={`/property-organizations/${params.propertyOrganizationId}/access-logs`}
      />
      <NavItem.Desktop
        label="Intercom Units"
        to={`/property-organizations/${params.propertyOrganizationId}/intercom-units`}
      />
      <NavItem.Desktop
        label="Dealer"
        to={`/property-organizations/${params.propertyOrganizationId}/dealer`}
      />
      {flags.accessControl && hasAccessControl ? (
        <NavItem.Desktop
          label="Access Control"
          to={`/property-organizations/${params.propertyOrganizationId}/access-control`}
        />
      ) : null}
      {flags.propertyGuests && (
        <NavItem.Desktop
          label={"Property Guests"}
          to={`/property-organizations/${params.propertyOrganizationId}/guests?tenantOrPropertyGuest=property`}
        />
      )}
    </HStack>
  );
};

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};
