import { Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { ScreenBackground, ScreenSize } from "src/common/types";
import { config } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/previewConfig";

type PreviewContainerProps = {
  screenSize: ScreenSize;
  propertyNameOverride?: string | null;
  background: ScreenBackground;
  children: ReactNode;
};

export const PreviewContainer = ({
  screenSize,
  propertyNameOverride,
  background,
  children,
}: PreviewContainerProps) => {
  const { height, width } = config[screenSize];

  return (
    <Flex
      position="relative"
      height={height}
      width={width}
      direction="column"
      justifyContent={propertyNameOverride ? "start" : "center"}
      alignItems={propertyNameOverride ? "start" : "center"}
      padding="8"
      background="brand.gray"
      backgroundImage={require(`src/assets/BACKGROUND_${screenSize}_${background}.png`)}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="8"
    >
      {children}
    </Flex>
  );
};
