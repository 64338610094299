import React, { ReactNode } from "react";

type PreviewStrongInlineTextProps = {
  children: ReactNode;
  fontWeight?: "500" | "700";
};

export const PreviewStrongInlineText = ({
  children,
  fontWeight = "500",
}: PreviewStrongInlineTextProps) => (
  <span style={{ fontWeight }}>{children}</span>
);
