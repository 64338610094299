import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";

import { Loading } from "src/common/Loading";
import { useProductActivationComponentsQuery } from "src/common/queries";
import { COMPONENT_TYPE_DISPLAY_NAMES } from "src/common/constants";
import { Card } from "src/layout/Card";

export const IntercomComponentList = () => {
  const componentsQuery = useProductActivationComponentsQuery();

  if (componentsQuery.isLoading) {
    return <Loading />;
  }

  if (!componentsQuery.data || componentsQuery.data?.length === 0) {
    return (
      <Card>
        <Text>No components registered for this intercom</Text>
      </Card>
    );
  }

  const components = componentsQuery.data.sort((a, b) =>
    a.componentType.localeCompare(b.componentType)
  );

  return (
    <Table
      backgroundColor="white"
      borderBottomRadius="12px"
      shadow="base"
      variant="simple"
    >
      <Thead backgroundColor={"gray.50"}>
        <Tr>
          <Th>Component</Th>
          <Th>Serial Number</Th>
        </Tr>
      </Thead>
      <Tbody>
        {components.map((component) => (
          <Tr key={component.componentType}>
            <Td fontSize="14px" fontWeight="semibold">
              {COMPONENT_TYPE_DISPLAY_NAMES[component.componentType]}
            </Td>
            <Td fontSize="14px" fontWeight="semibold">
              {component.serialNumber || "-"}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
