import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { getTenantGuestsQueryKey } from "src/routes/TenantGuestsList/queries";
import { useKy } from "src/common/ky";
import { getPropertyGuestsQueryKey } from "src/routes/PropertyGuestsList/queries";

export const useDeleteTenantGuest = () => {
  const queryClient = useQueryClient();
  const ky = useKy();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { propertyOrganizationId } = useParams<string>();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  const [deletingGuestId, setDeletingGuestId] = useState<string | undefined>();
  const deleteGuestMutation = useMutation(
    () => ky.delete(`tenants/guests/${deletingGuestId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getTenantGuestsQueryKey(searchParams));
        setDeletingGuestId(undefined);
      },
    }
  );

  return {
    isDeletingGuest: !!deletingGuestId,
    deleteGuestMutation: deleteGuestMutation,
    setDeletingGuestId: setDeletingGuestId,
  };
};

export const useDeletePropertyGuest = () => {
  const queryClient = useQueryClient();
  const ky = useKy();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { propertyOrganizationId } = useParams<string>();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  const [deletingGuestId, setDeletingGuestId] = useState<string | undefined>();
  const deleteGuestMutation = useMutation(
    () => ky.delete(`guests/${deletingGuestId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPropertyGuestsQueryKey(searchParams));
        setDeletingGuestId(undefined);
      },
    }
  );

  return {
    isDeletingGuest: !!deletingGuestId,
    deleteGuestMutation: deleteGuestMutation,
    setDeletingGuestId: setDeletingGuestId,
  };
};
