import React from "react";
import { Tag } from "@chakra-ui/react";

import { IntercomActivation } from "src/common/types";

type ScheduleStatusTagProps = {
  intercomActivation: IntercomActivation;
};
export const ScheduleStatusTag = ({
  intercomActivation,
}: ScheduleStatusTagProps) => {
  if (intercomActivation.isForcedOpen) {
    return (
      <Tag color="white" backgroundColor="#FF595E">
        Forced Unlocked
      </Tag>
    );
  } else if (intercomActivation.scheduleDays.length > 0) {
    return (
      <Tag color="white" backgroundColor="#19C32A">
        Schedule Set
      </Tag>
    );
  } else {
    return (
      <Tag color="white" backgroundColor="#E8B10C">
        No Schedule Set
      </Tag>
    );
  }
};
