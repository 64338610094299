import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { AccessProfile } from "src/common/types";
import { useKy } from "src/common/ky";

const getAccessProfilesQueryKey = (searchParams: URLSearchParams) => {
  searchParams.sort();

  return ["ACCESS_PROFILES", searchParams.toString()];
};

export const useGetAccessProfilesQuery = () => {
  const ky = useKy();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { propertyOrganizationId } = useParams<string>();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  return useQuery(getAccessProfilesQueryKey(searchParams), () =>
    ky.get(`access-control/profile`, { searchParams }).json<AccessProfile[]>()
  );
};
