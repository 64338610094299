import { EntryScheduleDayOfWeek } from "src/common/types";

export const ENTRY_SCHEDULE_DAYS_OF_WEEK = [
  EntryScheduleDayOfWeek.Sunday,
  EntryScheduleDayOfWeek.Monday,
  EntryScheduleDayOfWeek.Tuesday,
  EntryScheduleDayOfWeek.Wednesday,
  EntryScheduleDayOfWeek.Thursday,
  EntryScheduleDayOfWeek.Friday,
  EntryScheduleDayOfWeek.Saturday,
];

export const ENTRY_SCHEDULE_DAYS_OF_WEEK_NAMES: Record<
  EntryScheduleDayOfWeek,
  string
> = {
  [EntryScheduleDayOfWeek.Sunday]: "Sunday",
  [EntryScheduleDayOfWeek.Monday]: "Monday",
  [EntryScheduleDayOfWeek.Tuesday]: "Tuesday",
  [EntryScheduleDayOfWeek.Wednesday]: "Wednesday",
  [EntryScheduleDayOfWeek.Thursday]: "Thursday",
  [EntryScheduleDayOfWeek.Friday]: "Friday",
  [EntryScheduleDayOfWeek.Saturday]: "Saturday",
};
