import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { Guest } from "src/common/types";
import { useKy } from "src/common/ky";

export const getTenantGuestsQueryKey = (searchParams: URLSearchParams) => {
  searchParams.sort();

  return ["TENANT_GUESTS", searchParams.toString()];
};

export const useTenantGuestsQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { propertyOrganizationId } = useParams<string>();
  const ky = useKy();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  return useQuery<Guest[]>(getTenantGuestsQueryKey(searchParams), () =>
    ky.get(`guests`, { searchParams }).json<Guest[]>()
  );
};
