import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";

import { useKy } from "src/common/ky";
import { useInvalidatePropertyGuestAccessCredentialQuery } from "src/routes/PropertyGuestsList/queries";

export const useResetPropertyGuestVirtualAccessCredential = () => {
  const toast = useToast();
  const ky = useKy();
  const invalidateVirtualAccessCredentialQuery =
    useInvalidatePropertyGuestAccessCredentialQuery();

  const [resettingForGuestId, setResettingForGuestId] = useState<
    string | undefined
  >();
  const resetPropertyGuestVirtualAccessCredentialMutation = useMutation(
    () =>
      ky.post(`guests/${resettingForGuestId}/reset-virtual-access-credential`),
    {
      onSuccess: async () => {
        await invalidateVirtualAccessCredentialQuery();
        setResettingForGuestId(undefined);
        toast({
          description: "Access Code Reset Successful!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return {
    isResettingVirtualAccessCredential: !!resettingForGuestId,
    resetPropertyGuestVirtualAccessCredentialMutation,
    setResettingForGuestId,
  };
};
