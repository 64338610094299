import React from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import DatePicker from "react-datepicker";
import { format, setHours, setMinutes, startOfToday } from "date-fns";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { noop } from "src/common/util";
import { useKy } from "src/common/ky";
import { AddEntryScheduleValidationSchema } from "src/routes/AccessControl/schema";
import { handleHookFormHTTPError } from "src/common/form";
import { EntrySchedule } from "src/common/types";
import { DayButton } from "src/routes/AccessControl/components/DayButton";
import {
  ENTRY_SCHEDULE_DAYS_OF_WEEK,
  ENTRY_SCHEDULE_DAYS_OF_WEEK_NAMES,
} from "src/routes/AccessControl/constants";

export interface AddEntryScheduleValues {
  displayName: string;
  propertyOrganizationId: string;
  schedule: EntrySchedule["schedule"];
}

export const AddEntrySchedule = () => {
  const { propertyOrganizationId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const ky = useKy();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<AddEntryScheduleValues>({
    defaultValues: {
      displayName: "",
      propertyOrganizationId,
      schedule: [],
    },
    resolver: yupResolver(AddEntryScheduleValidationSchema),
  });

  const {
    fields: scheduleFields,
    append: appendScheduleField,
    remove: removeScheduleField,
  } = useFieldArray({
    control,
    name: "schedule",
  });

  const addEntrySchedule = useMutation<void, HTTPError, AddEntryScheduleValues>(
    async (values: AddEntryScheduleValues) => {
      await ky.post(`access-control/entry-schedule/create`, {
        json: { ...values },
      });
    },
    {
      onSuccess: () => {
        toast({
          description: "Entry Schedule has been added to property",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate(
          `/property-organizations/${propertyOrganizationId}/access-control/entry-schedules`
        );
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/access-control/entry-schedules`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Add Entry Schedule</PageHeaderTitle>
        <PageHeaderSubtitle>
          Enter entry schedule details below
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((data) =>
            addEntrySchedule
              .mutateAsync({
                displayName: data.displayName,
                propertyOrganizationId: propertyOrganizationId || "",
                schedule: data.schedule,
              })
              .catch(noop)
          )}
        >
          <Grid gap={4} width={{ base: "100%", xl: "50%" }}>
            <GridItem>
              <Controller
                name="displayName"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel htmlFor="displayName">Display Name</FormLabel>
                    <Input
                      {...field}
                      id="displayName"
                      placeholder="Display Name"
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>

            <GridItem>
              <Divider />
            </GridItem>

            <GridItem>
              <Grid width={["100%", "100%", "500px", "500px", "500px"]} gap={4}>
                <GridItem>
                  <Text color="gray.500" fontSize="sm">
                    Select the days and times you want entry points to remain
                    open.
                  </Text>
                </GridItem>
                <GridItem>
                  <Wrap
                    justify="space-between"
                    p="10px 0"
                    justifyContent="space-between"
                  >
                    {ENTRY_SCHEDULE_DAYS_OF_WEEK.map((day) => (
                      <WrapItem key={day}>
                        <Controller
                          name={`schedule`}
                          control={control}
                          render={({ field }) => (
                            <DayButton
                              dayOfWeek={day}
                              isActive={field.value.some(
                                (d) => d.dayOfWeek === day
                              )}
                              onClick={() => {
                                if (
                                  !field.value.some((d) => d.dayOfWeek === day)
                                ) {
                                  appendScheduleField({
                                    dayOfWeek: day,
                                    startTime: format(
                                      setHours(startOfToday(), 8),
                                      "HHmm"
                                    ),
                                    endTime: format(
                                      setHours(startOfToday(), 9),
                                      "HHmm"
                                    ),
                                  });
                                } else {
                                  removeScheduleField(
                                    field.value.findIndex(
                                      (d) => d.dayOfWeek === day
                                    )
                                  );
                                }
                              }}
                            />
                          )}
                        />
                      </WrapItem>
                    ))}
                  </Wrap>
                </GridItem>
                <GridItem>
                  <Grid rowGap={"24px"}>
                    {scheduleFields.map((scheduleField, index) => (
                      <GridItem key={scheduleField.dayOfWeek}>
                        <Grid templateColumns="1fr 1fr" columnGap="16px">
                          <GridItem colSpan={2}>
                            <Text fontSize="m" fontWeight={600}>
                              {
                                ENTRY_SCHEDULE_DAYS_OF_WEEK_NAMES[
                                  scheduleField.dayOfWeek
                                ]
                              }
                            </Text>
                          </GridItem>
                          <GridItem>
                            <Controller
                              name={`schedule.${index}.startTime`}
                              control={control}
                              render={({ field, fieldState }) => (
                                <FormControl isInvalid={!!fieldState.error}>
                                  <FormLabel
                                    textTransform="uppercase"
                                    color="#7A8A99"
                                    fontSize="sm"
                                    fontWeight={600}
                                    htmlFor={`schedule.${index}.startTime`}
                                  >
                                    Open Entry Point
                                  </FormLabel>
                                  <DatePicker
                                    customInput={<Input />}
                                    selected={setMinutes(
                                      setHours(
                                        startOfToday(),
                                        +field.value.slice(0, 2)
                                      ),
                                      +field.value.slice(2, 4)
                                    )}
                                    onChange={(date) =>
                                      field.onChange(
                                        format(
                                          date || setHours(startOfToday(), 8),
                                          "HHmm"
                                        )
                                      )
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="hh:mm aa"
                                    timeFormat="hh:mm aa"
                                    showPopperArrow={false}
                                  />
                                  <FormErrorMessage>
                                    {fieldState.error?.message}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          </GridItem>
                          <GridItem>
                            <Controller
                              name={`schedule.${index}.endTime`}
                              control={control}
                              render={({ field, fieldState }) => (
                                <FormControl isInvalid={!!fieldState.error}>
                                  <FormLabel
                                    textTransform="uppercase"
                                    color="#7A8A99"
                                    fontSize="sm"
                                    fontWeight={600}
                                    htmlFor={`schedule.${index}.endTime`}
                                  >
                                    Close Entry Point
                                  </FormLabel>
                                  <DatePicker
                                    customInput={<Input />}
                                    selected={setMinutes(
                                      setHours(
                                        startOfToday(),
                                        +field.value.slice(0, 2)
                                      ),
                                      +field.value.slice(2, 4)
                                    )}
                                    onChange={(date) =>
                                      field.onChange(
                                        format(
                                          date || setHours(startOfToday(), 9),
                                          "HHmm"
                                        )
                                      )
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="hh:mm aa"
                                    timeFormat="hh:mm aa"
                                    showPopperArrow={false}
                                  />
                                  <FormErrorMessage>
                                    {fieldState.error?.message}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          </GridItem>
                        </Grid>
                      </GridItem>
                    ))}
                  </Grid>
                </GridItem>
              </Grid>
            </GridItem>

            {errors[`schedule`]?.message ? (
              <GridItem colSpan={2}>
                <FormControl isInvalid={true}>
                  <FormErrorMessage>
                    {errors[`schedule`]?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            ) : null}

            {Array.isArray(errors["schedule"])
              ? errors["schedule"]?.map((err, idx) =>
                  err?.message ? (
                    <GridItem key={idx} colSpan={2}>
                      <FormControl isInvalid={true}>
                        <FormErrorMessage>{err?.message}</FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  ) : null
                )
              : null}
          </Grid>
          <Grid gap={4} mt={4}>
            <GridItem>
              <Divider />
            </GridItem>
            <GridItem>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/property-organizations/${propertyOrganizationId}/access-control/entry-schedules`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};
