import { PropertyGuestScheduleType } from "src/routes/PropertyGuestsList/AddPropertyGuest";

export const DEALER_TYPES = ["Billable", "Internal"] as const;
export const enum DealerType {
  Billable = "Billable",
  Internal = "Internal",
}

type DealerOrganization = {
  dealerOrganizationId: string;
  displayName: string;
  phone: string;
  website: string;
  dealerType: DealerType;
};

export type Unit = {
  unitId: string;
  unitGroupId?: string;
  unitGroupName?: string;
  unitName: string;
  propertyOrganizationId: string;
  createdAt: string;
  tenantCount: number;
};

export type CompactIntercom = {
  intercomActivationId: string;
  intercomActivationName: string;
};

export type UnitGroup = {
  unitGroupId: string;
  unitGroupName: string;
  unitCount: number;
  propertyOrganizationId: string;
  createdAt: string;
};

export const PROPERTY_TYPES = ["Billable", "Demo", "Internal"] as const;
export const enum PropertyType {
  Billable = "Billable",
  Demo = "Demo",
  Internal = "Internal",
}

export type PropertyOrganization = {
  propertyOrganizationId: string;
  dealerOrganizationId: string;
  createdAt: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerAddress: string;
  ownerCity: string;
  ownerState: string;
  ownerZip: string;
  ownerPhone: string;
  ownerEmail: string;
  lat: number;
  lng: number;
  adminFirstName: string | null;
  adminLastName: string | null;
  adminPhone: string | null;
  adminEmail: string | null;
  propertyUser: PropertyUser | null;
  dealerOrganization: DealerOrganization | null;
  contactId: string | null;
  accessControlOnboardedAt: string | null;
  propertyType: PropertyType;
};

export type User = {
  userId: string;
  idpUserId: string;
  createdAt: string;
  updatedAt: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type PropertyUser = {
  propertyUserId: string;
  propertyOrganizationId: string;
  userId: string;
  user: User;
  createdAt: string;
  role: PropertyUserRole;
  eulaAcceptedAt: Date | null;
  physicalAccessCredentialIds: string[];
  physicalAccessCredentials: AccessCredential[];
  accessProfileIds: string[];
};

export enum PropertyUserRole {
  admin = "admin",
  staff = "staff",
}

export enum GateHawkUserRole {
  admin = "admin",
  staff = "staff",
}

export type GateHawkUser = {
  userId: string;
  staffUserId: string;
  createdAt: string;
  role: GateHawkUserRole;
};

export type Tenant = {
  tenantId: string;
  userId: string;
  user: User;
  propertyOrganizationId: string;
  createdAt: string;
  enableDirectoryListing: boolean;
  videoCallsEnabled: boolean;
  eulaAcceptedAt: string | null;
  privacyPolicyAcceptedAt: string | null;
  physicalAccessCredentialIds: string[];
  physicalAccessCredentials: AccessCredential[];
  accessProfileIds: string[];
};

export type Guest = {
  guest_id: string;
  tenant_id: string;
  created_at: Date;
  name: string;
  phone: string;
  email: string;
  access_code: string;
  schedule_type: PropertyGuestScheduleType;
  schedule_start_date: string;
  schedule_end_date: string;
  schedule_start_time: string;
  schedule_end_time: string;
  is_active_sun: boolean;
  is_active_mon: boolean;
  is_active_tue: boolean;
  is_active_wed: boolean;
  is_active_thu: boolean;
  is_active_fri: boolean;
  is_active_sat: boolean;
  access_code_delivery_method: "email" | "manual";
  tenant: {
    user: {
      first_name?: string;
      last_name?: string;
    };
  };
  property_organization_id?: string;
  company_name?: string;
  added_by: string;
  expired: boolean;
};

export type PaginatedResult<T> = {
  pageSize: number;
  pageNumber: number;
  total: number;
  result: T[];
};

export enum AccessRequestUserType {
  PropertyStaff = "PropertyStaff",
  Tenant = "Tenant",
  TenantGuest = "TenantGuest",
}

export enum AccessRequestSource {
  Intercom = "Intercom",
  MobileIntercom = "MobileIntercom",
}

export enum AccessRequestMedium {
  KEYPAD = "KEYPAD",
  QRCODE = "QRCODE",
  MAILKEY = "MAILKEY",
  FOB = "FOB",
  PHONE = "PHONE",
}

export enum ScreenBackground {
  DEFAULT = "DEFAULT",
  GATEHAWK_GREY = "GATEHAWK_GREY",
  GATEHAWK_BLUE = "GATEHAWK_BLUE",
}

export enum LogoBackground {
  BLACK = "BLACK",
  WHITE = "WHITE",
  TRANSPARENT = "TRANSPARENT",
}

export enum ScreenSize {
  TenInch = "10",
  TwelveInch = "12",
}

export type AccessRequest = {
  accessRequestId: string;
  intercomActivationId: string;
  propertyOrganizationId: string;
  accessGranted: boolean;
  source: AccessRequestSource;
  medium: AccessRequestMedium | null;
  accessCode: string | null;
  userName: string | null;
  userType: string | null;
  userInvitedByName: string | null;
  locationName: string;
  createdAt: string;
  thumbnailUrl: string | null;
};

export type DayOfWeek = "sun" | "mon" | "tue" | "wed" | "thu" | "fri" | "sat";
export type IntercomActivationScheduleDay = {
  intercomActivationScheduleDayId: string;
  intercomActivationId: string;
  dayOfWeek: DayOfWeek;
  openTime: string;
  closeTime: string;
  createdAt: string;
};
export type CellKitAssignmentInfo = {
  cellKitId: string;
  serialNumber: string;
  simNumber: string;
  modemId: string;
  notes: string;
  carrier: string;
  createdAt: string;
  activatedAt: string | null;
};
export type DealerContactInfo = {
  name: string;
  address: string;
  phoneNumber: string;
  website: string;
};
export type IntercomActivation = {
  intercomActivationId: string;
  propertyOrganizationId: string;
  intercomId: string;
  intercomModel: string;
  intercomModelSku: string;
  serialNumber: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  enableResidentDirectory: boolean;
  enableNumericCodes: boolean;
  enableQrCodes: boolean;
  enableCallManager: boolean;
  enableVideoCalls: boolean;
  enableDMI: boolean;
  enableDMIUA: boolean;
  lat: number | null;
  long: number | null;
  locationThresholdFeet: number;
  requireNfcTap: boolean;
  isForcedOpen: boolean;
  scheduleDays: IntercomActivationScheduleDay[];
  background: ScreenBackground;
  propertyNameOverride?: string | null;
  intercomNameOverride?: string | null;
  logoUrl?: string | null;
  logoBackground: LogoBackground;
  assignedCellKit?: string;
  cellKitAssignmentInfo?: CellKitAssignmentInfo;
  softwareVersion: string | null;
  dealerContactInfo?: DealerContactInfo;
  cameraPreviewUrl: string | null;
  cameraPreviewLastUpdated: string | null;
};

export type IntercomComponent = {
  componentId: string;
  intercomActivationId: string;
  componentType: IntercomComponentType;
  connectionStatus: string;
  serialNumber: string | null;
  activatedAt: string | null;
  disabled: boolean;
  metadata: Record<string, unknown>;
};

export type AccessCredential = {
  accessCredentialId: string;
  propertyOrganizationId: string;
  code: string;
  createdAt: string;
  type: string;
};

export interface ValidationError<T> {
  property: keyof T;
  messages: string[];
}

export type FeatureFlags = {
  defaultMobileIntercom?: boolean;
  accessControl?: boolean;
  units?: boolean;
  unitGroups?: boolean;
  propertyGuests?: boolean;
};

export enum IntercomComponentType {
  ACCELEROMETER = "ACCELEROMETER",
  CAMERA = "CAMERA",
  EEPROM = "EEPROM",
  ETHERNET = "ETHERNET",
  IO_BOARD = "IO_BOARD",
  KEYPAD = "KEYPAD",
  MAIN_BOARD = "MAIN_BOARD",
  MICROPHONE = "MICROPHONE",
  MODEM = "MODEM",
  READER = "READER",
  SCREEN = "SCREEN",
  SPEAKER = "SPEAKER",
  TEMP_SENSOR = "TEMP_SENSOR",
  TOUCH = "TOUCH",
}

export enum IntercomGeneration {
  GEN_3 = "Gen3",
  GEN_4 = "Gen4",
}

export enum IntercomComponentStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  UNKNOWN = "UNKNOWN",
}

export type ComponentReport = {
  componentType: IntercomComponentType;
  status: IntercomComponentStatus;
  details: string | null;
  errorCode: number | null;
};

export type IntercomHealth = {
  intercomReportId: string | null;
  intercomActivationId: string | null;
  createdAt: string | null;
  source: string | null;
  componentReports: ComponentReport[];
};

export type IntercomServiceLog = {
  intercomActivationId: string;
  serviceSessionId: string;
  technicianId: string;
  endedAt: string | null;
  intercomName: string;
  generatedReportLocation: string;
  startedAt: string;
  propertyStaffSummary: string;
  technicianName: string;
  technicianSummary: string;
};

export type IntercomFacePlateLog = {
  intercomFaceplateLogId: string;
  intercomActivationId: string;
  status: string;
  createdAt: string;
};

export type PaginationOptions = {
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string;
  sortDir?: "ASC" | "DESC";
};

export type AccessProfileDMPProperties = {
  sensorReset: boolean;
};

export type AccessProfile = {
  accessProfileId: string;
  displayName: string;
  propertyOrganizationId: string;
  entryGroupIds: string[];
  entryScheduleIds: string[];
  dmpProperties: AccessProfileDMPProperties | undefined;
};

export type EntryGroup = {
  entryGroupId: string;
  propertyOrganizationId: string;
  displayName: string;
};

export type EntryPoint = {
  entryPointId: string;
  propertyOrganizationId: string;
  displayName: string;
  entryGroupIds: string[];
  fireExitRelease: boolean;
  publicEntryPoint: boolean;
  notes: string | undefined;
};

export enum EntryScheduleDayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export type EntrySchedule = {
  entryScheduleId: string;
  propertyOrganizationId: string;
  displayName: string;
  schedule: EntryScheduleDay[];
};

export type EntryScheduleDay = {
  dayOfWeek: EntryScheduleDayOfWeek;
  startTime: string;
  endTime: string;
};

export type Panel = {
  panelId: string;
  serialNumber: string;
  panelType: string;
  propertyOrganizationId: string;
  dmpProperties?: {
    integratorPort?: {
      ipAddress: string;
      port: number;
    };
  };
  syncStatus: SyncStatus | undefined;
};

export type SyncStatus = {
  date: string;
  code: "Success" | "Error";
  message: string;
};
