import {
  EntryScheduleDayOfWeek,
  IntercomComponentType,
} from "src/common/types";

export const ENTRY_SCHEDULE_DAYS_OF_WEEK_NAMES: Record<
  EntryScheduleDayOfWeek,
  string
> = {
  [EntryScheduleDayOfWeek.Sunday]: "Sunday",
  [EntryScheduleDayOfWeek.Monday]: "Monday",
  [EntryScheduleDayOfWeek.Tuesday]: "Tuesday",
  [EntryScheduleDayOfWeek.Wednesday]: "Wednesday",
  [EntryScheduleDayOfWeek.Thursday]: "Thursday",
  [EntryScheduleDayOfWeek.Friday]: "Friday",
  [EntryScheduleDayOfWeek.Saturday]: "Saturday",
};
export const COMPONENT_TYPE_DISPLAY_NAMES: Record<
  IntercomComponentType,
  string
> = {
  [IntercomComponentType.ACCELEROMETER]: "Accelerometer",
  [IntercomComponentType.CAMERA]: "Camera",
  [IntercomComponentType.EEPROM]: "EEPROM",
  [IntercomComponentType.ETHERNET]: "Ethernet",
  [IntercomComponentType.IO_BOARD]: "IO Board",
  [IntercomComponentType.KEYPAD]: "Keypad",
  [IntercomComponentType.MAIN_BOARD]: "Main Board",
  [IntercomComponentType.MICROPHONE]: "Microphone",
  [IntercomComponentType.MODEM]: "Modem",
  [IntercomComponentType.READER]: "Reader Board",
  [IntercomComponentType.SCREEN]: "Screen",
  [IntercomComponentType.SPEAKER]: "Speaker",
  [IntercomComponentType.TEMP_SENSOR]: "Temperature Sensor",
  [IntercomComponentType.TOUCH]: "Touch",
};
