import { useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import { PaginatedResult, Tenant } from "src/common/types";
import { useKy } from "src/common/ky";

export const getTenantsQueryKey = (searchParams: URLSearchParams) => {
  searchParams.sort();

  return ["TENANTS", searchParams.toString()];
};
export const useInvalidateTenantsQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { propertyOrganizationId, unitId } = useParams<string>();
  const queryClient = useQueryClient();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", "1");
  }

  if (unitId) {
    searchParams.set("unitId", unitId);
  }

  return useCallback(() => {
    return queryClient.invalidateQueries(getTenantsQueryKey(searchParams));
  }, [queryClient, searchParams]);
};

export const useTenantsQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { propertyOrganizationId, unitId } = useParams<string>();
  const ky = useKy();

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", "1");
  }

  if (unitId) {
    searchParams.set("unitId", unitId);
  }

  return useQuery<PaginatedResult<Tenant>>(
    getTenantsQueryKey(searchParams),
    () => ky.get(`tenants`, { searchParams }).json<PaginatedResult<Tenant>>()
  );
};
