import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { HiOutlineDownload, HiOutlineSearch } from "react-icons/hi";
import { GoX } from "react-icons/go";

import { AccessRequest, PaginatedResult } from "src/common/types";
import { AccessLogsFilterPopover } from "src/routes/AccessLogs/AccessLogsFilterPopover";
import { downloadFile } from "src/common/util";
import { useKy } from "src/common/ky";

export const useAccessRequestsQuery = () => {
  const location = useLocation();
  const { propertyOrganizationId } = useParams();
  const ky = useKy();

  const searchParams = new URLSearchParams(location.search);

  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", "1");
  }

  return useQuery<PaginatedResult<AccessRequest>>(
    ["ACCESS_REQUESTS", propertyOrganizationId, searchParams.toString()],
    () =>
      ky
        .get(`access-requests/${propertyOrganizationId}`, { searchParams })
        .json<PaginatedResult<AccessRequest>>()
  );
};

export const AccessLogsFilterBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const ky = useKy();
  const { propertyOrganizationId } = useParams();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [nameOrCode, setNameOrCode] = useState<string>(
    searchParams.get("nameOrCode") || ""
  );

  const onSubmit = useCallback(
    (newSp: URLSearchParams) => {
      newSp.delete("pageNumber");
      searchParams.sort();
      newSp.sort();

      if (searchParams.toString() !== newSp.toString()) {
        navigate(`?${newSp.toString()}`);
      }
    },
    [searchParams]
  );

  const handleSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      const newSp = new URLSearchParams(location.search);

      if (nameOrCode) {
        newSp.set("nameOrCode", nameOrCode);
      } else {
        newSp.delete("nameOrCode");
      }

      onSubmit(newSp);
    },
    [location.search, nameOrCode, searchParams]
  );

  const clearSearch = () => {
    const newSp = new URLSearchParams(location.search);
    newSp.delete("nameOrCode");
    setNameOrCode("");

    onSubmit(newSp);
  };

  const downloadCsvQuery = useQuery(
    ["ACCESS_REQUESTS_CSV", propertyOrganizationId, searchParams.toString()],
    () =>
      ky.get(`access-requests/${propertyOrganizationId}/csv`, { searchParams }),
    {
      enabled: false,
      onSuccess: async (response) => {
        await downloadFile(response, "access-logs.csv");
        toast({
          description: "Access Logs CSV successfully downloaded",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <Flex flexDirection="row">
      <form onSubmit={handleSubmit}>
        <InputGroup width={503} marginRight="16px">
          <InputLeftElement pointerEvents="none" color="gray.300">
            <HiOutlineSearch />
          </InputLeftElement>
          <Input
            value={nameOrCode}
            onChange={({ currentTarget: { value } }) => setNameOrCode(value)}
            onBlur={handleSubmit}
            placeholder="Search by Tenant/Guest name or access code..."
          />
          <InputRightElement>
            <Button variant="ghost" onClick={clearSearch}>
              <GoX />
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
      <ButtonGroup gap="8px">
        <AccessLogsFilterPopover onSubmit={onSubmit} />
        <Button
          colorScheme="brand.blue"
          leftIcon={<HiOutlineDownload />}
          onClick={() => downloadCsvQuery.refetch()}
          isDisabled={downloadCsvQuery.isFetching}
        >
          Download Logs CSV
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
