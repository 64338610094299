import { Flex, HStack } from "@chakra-ui/react";
import * as React from "react";
import { Outlet } from "react-router-dom";

import { Logo } from "src/layout/Logo";
import { MobileHamburgerMenu } from "src/layout/MobileHamburgerMenu";
import { NavMenu } from "src/layout/NavMenu";
import { ProfileDropdown } from "src/layout/ProfileDropdown";
import { useMobileMenuState } from "src/layout/useMobileMenuState";
import { AppErrorBoundary } from "src/common/ErrorBoundary/AppErrorBoundary";

type TopBarProps = {
  hideNavigation?: boolean;
};
export const TopBar = ({ hideNavigation }: TopBarProps) => {
  const { isMenuOpen, toggle } = useMobileMenuState();

  return (
    <Flex align="center" bg="brand.navy" color="white" px="6" minH="16">
      <Flex justify="space-between" align="center" w="full">
        {!hideNavigation && (
          <>
            <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
            <NavMenu.Mobile isOpen={isMenuOpen} closeMenu={toggle} />
          </>
        )}

        {/* Desktop Logo placement */}
        <Logo
          display={{ base: "none", lg: "block" }}
          flexShrink={0}
          h="5"
          marginEnd="10"
        />

        {!hideNavigation && <NavMenu.Desktop />}

        {/* Mobile Logo placement */}
        <Logo
          flex={{ base: "1", lg: "0" }}
          display={{ lg: "none" }}
          flexShrink={0}
          h="5"
        />

        <HStack spacing="3">
          <ProfileDropdown hideNavigation={hideNavigation} />
        </HStack>
      </Flex>
    </Flex>
  );
};

export const PropertyOrganizationLayout = () => {
  return (
    <Flex direction="column" bg={"gray.50"} height="100vh">
      <TopBar />
      <AppErrorBoundary>
        <Outlet />
      </AppErrorBoundary>
    </Flex>
  );
};
