import React, { FC, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";

import { Upload } from "src/common/icons/Upload";
import {
  IntercomActivation,
  LogoBackground,
  ScreenBackground,
  ScreenSize,
} from "src/common/types";
import { IntercomSplashScreenPreview } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview";
import { logoBackgroundMap } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/previewConfig";
import useIntercomDetails from "src/routes/IntercomUnits/ViewIntercomUnit/useIntercomDetails";
import { HTTPError, useKy } from "src/common/ky";
import { convertToBase64, noop } from "src/common/util";
import { getProductActivationsQueryKey } from "src/common/queries";
import { handleHookFormHTTPError } from "src/common/form";

interface UpdateScreenConfigValues {
  background: ScreenBackground;
  propertyNameOverride?: string | null;
  imageData?: string | null;
  logoBackground: LogoBackground;
}

const UpdateScreenConfigValidationSchema = Yup.object().shape({
  background: Yup.string()
    .required()
    .oneOf(Object.values(ScreenBackground))
    .label("Background"),
  propertyNameOverride: Yup.string()
    .optional()
    .nullable()
    .max(255)
    .label("Property Name"),
  imageData: Yup.string().optional().nullable().label("Logo File"),
  logoBackground: Yup.string()
    .required()
    .oneOf(Object.values(LogoBackground))
    .label("Logo Background"),
});

type EditIntercomSplashScreenFormProps = {
  intercomActivation: IntercomActivation;
  onComplete: () => void;
};

const MAX_LOGO_SIZE = 5 * 1000000; // 5MB

/**
 * This component handles editing of the intercom's splash screen configuration.
 */
export const EditIntercomSplashScreenForm: FC<
  EditIntercomSplashScreenFormProps
> = ({ intercomActivation, onComplete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { screenSize } = useIntercomDetails();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();
  const ky = useKy();

  const {
    control,
    getFieldState,
    handleSubmit,
    watch,
    setError,
    getValues,
    setValue,
  } = useForm<UpdateScreenConfigValues>({
    defaultValues: {
      background: intercomActivation.background || ScreenBackground.DEFAULT,
      propertyNameOverride: intercomActivation.propertyNameOverride || "",
      imageData: undefined,
      logoBackground:
        intercomActivation.logoBackground || LogoBackground.TRANSPARENT,
    },
    resolver: yupResolver(UpdateScreenConfigValidationSchema),
  });

  const updateScreenConfig = useMutation<
    void,
    HTTPError,
    UpdateScreenConfigValues
  >(
    async (values: UpdateScreenConfigValues) => {
      await ky.patch(
        `product-unit-activations/${intercomActivation.propertyOrganizationId}/screen-config`,
        { json: values }
      );
    },
    {
      onSuccess: async () => {
        toast({
          description:
            "Intercom Splash Screens successfully updated for all intercoms",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        await queryClient.invalidateQueries(
          getProductActivationsQueryKey(
            intercomActivation.propertyOrganizationId
          )
        );
        onComplete();
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  const formData = watch();

  const logo =
    formData.imageData === null
      ? formData.imageData
      : formData.imageData || intercomActivation.logoUrl;

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (!file) {
      return;
    }

    if (file.size > MAX_LOGO_SIZE) {
      setError("imageData", {
        message: "Your file cannot be greater than 5MB in size.",
      });
    }

    let base64 = "";
    try {
      base64 = await convertToBase64(file);
    } catch (e) {
      setError("imageData", {
        message: e instanceof Error ? e.message : `${e}`,
      });
    }

    setValue("imageData", base64);
  };

  return (
    <>
      <Grid templateColumns="1fr 2fr" gap={2}>
        <GridItem>
          <Text fontWeight="bold" fontSize="20px">
            Screen Information
          </Text>
          <Spacer h="18px" />
          <form
            id="screen-config-form"
            onSubmit={handleSubmit((data) =>
              updateScreenConfig.mutateAsync(data).catch(noop)
            )}
          >
            <Grid gap={4}>
              <GridItem>
                <Checkbox
                  iconColor="brand.blue"
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setValue("background", ScreenBackground.DEFAULT);
                      setValue("propertyNameOverride", null);
                      setValue("imageData", null);
                      setValue("logoBackground", LogoBackground.TRANSPARENT);
                    } else {
                      setValue("background", intercomActivation.background);
                      setValue("imageData", undefined);
                      setValue(
                        "propertyNameOverride",
                        intercomActivation.propertyNameOverride
                      );
                      setValue(
                        "logoBackground",
                        intercomActivation.logoBackground
                      );
                    }
                  }}
                >
                  Revert to default settings
                </Checkbox>
                <Spacer h="10px" />
                <Text color="#a3a3a3" lineHeight="18px" fontSize="14px">
                  Revert to default: All changed settings are reverted to the
                  default. (grey background, no logo, property name, and
                  intercom name).
                </Text>
              </GridItem>

              <GridItem>
                <Controller
                  name="propertyNameOverride"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="propertyNameOverride">
                        <Flex gap={2}>
                          Property Name
                          <Text fontWeight="hairline" fontStyle="italic">
                            Optional
                          </Text>
                        </Flex>
                      </FormLabel>
                      <Input
                        width="90%"
                        {...field}
                        value={field.value || ""}
                        id="propertyNameOverride"
                        placeholder="Add Property name here"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>

              <GridItem>
                <Controller
                  name="imageData"
                  control={control}
                  render={({ fieldState }) => (
                    <FormControl>
                      <FormLabel>
                        <Flex gap={2}>
                          Logo
                          <Text fontWeight="hairline" fontStyle="italic">
                            Optional
                          </Text>
                        </Flex>
                      </FormLabel>
                      <Flex
                        width="90%"
                        justifyContent={logo ? "space-around" : "space-between"}
                        alignItems="center"
                        sx={{
                          backgroundColor: "white",
                          border: "1px #E2E8F0 solid",
                          boxShadow: "0px 2px 3px gray",
                          borderRadius: "6px",
                          py: 2,
                          px: 3,
                          _hover: logo
                            ? undefined
                            : {
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                cursor: "pointer",
                              },
                        }}
                        onClick={logo ? undefined : onOpen}
                      >
                        {logo ? (
                          <>
                            <Image
                              h="100px"
                              w="100px"
                              backgroundColor={
                                formData.logoBackground !==
                                LogoBackground.TRANSPARENT
                                  ? formData.logoBackground.toLowerCase()
                                  : undefined
                              }
                              border={
                                formData.logoBackground ===
                                LogoBackground.TRANSPARENT
                                  ? "2px dashed black"
                                  : undefined
                              }
                              src={logo || ""}
                            />
                            <Stack gap={0.5}>
                              <Button onClick={onOpen} variant="ghost">
                                Edit
                              </Button>
                              <Button
                                variant="ghost"
                                onClick={() => setValue("imageData", null)}
                              >
                                Clear
                              </Button>
                            </Stack>
                          </>
                        ) : (
                          <>
                            <Text>Upload a Logo</Text>
                            <Upload w={18} h={23} color="#192E33" />
                          </>
                        )}
                      </Flex>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>

              <GridItem>
                <Controller
                  name="background"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <FormLabel>Background</FormLabel>
                      <Stack
                        direction="row"
                        overflowX="scroll"
                        overflowY="hidden"
                        gap={2}
                      >
                        {Object.values(ScreenBackground).map((bg) => {
                          const isSelected = bg === field.value;

                          return (
                            <Image
                              key={bg}
                              src={require(`src/assets/BACKGROUND_${screenSize}_${bg}.png`)}
                              width="200px"
                              height="120px"
                              borderRadius="10"
                              sx={
                                isSelected
                                  ? {
                                      outline: "8px solid #319795",
                                      outlineOffset: "-8px",
                                    }
                                  : undefined
                              }
                              onClick={() => setValue("background", bg)}
                            />
                          );
                        })}
                      </Stack>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>
            </Grid>
          </form>
        </GridItem>

        <GridItem
          borderLeft="2px"
          borderColor="lightgray"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Text fontWeight="bold" fontSize="20px">
              Preview
            </Text>
            <Spacer h="18px" />
            <IntercomSplashScreenPreview
              screenSize={screenSize as ScreenSize}
              intercomActivation={{
                ...formData,
                logoUrl: intercomActivation.logoUrl,
              }}
            />
          </Box>
        </GridItem>
      </Grid>
      <Stack direction="row" sx={{ mt: "30px" }}>
        <Button onClick={onComplete} isDisabled={updateScreenConfig.isLoading}>
          Cancel
        </Button>
        <Button
          type="submit"
          form="screen-config-form"
          colorScheme="brand.blue"
          isDisabled={updateScreenConfig.isLoading}
        >
          Save to All Intercoms
        </Button>
      </Stack>

      <Modal isOpen={isOpen} size="2xl" onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Grid templateColumns="1fr 1fr" gap={10} my={5}>
              <GridItem justifySelf="center" alignSelf="center">
                <InputGroup onClick={() => fileInputRef.current?.click()}>
                  <input
                    hidden
                    type="file"
                    multiple={false}
                    accept=".png,.svg"
                    ref={(e) => (fileInputRef.current = e)}
                    onChange={handleFile}
                  />
                  {logo ? (
                    <Image
                      src={logo || ""}
                      _hover={{
                        cursor: "pointer",
                      }}
                      w="100px"
                      h="100px"
                    />
                  ) : (
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      w="300px"
                      h="300px"
                      background="rgba(0, 0, 0, 0.1)"
                      border="2px dashed #000000"
                      _hover={{
                        cursor: "pointer",
                        background: "rgba(0, 0, 0, 0.3)",
                      }}
                      gap={2}
                    >
                      <Text fontWeight="medium" fontSize="20px">
                        Upload a file
                      </Text>
                      <Upload w="31px" h="40px" />
                      <Text fontSize="18px">png. or svg.</Text>
                      <Text fontSize="18px">(100px by 100px)</Text>
                    </Flex>
                  )}
                  <FormErrorMessage>
                    {getFieldState("imageData").error?.message}
                  </FormErrorMessage>
                </InputGroup>
              </GridItem>
              <GridItem>
                <Stack gap={2}>
                  <Text fontWeight="bold" fontSize="23px">
                    Logo Upload
                  </Text>
                  <Controller
                    name="logoBackground"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl gap={2}>
                        <FormLabel fontSize="16px" fontWeight="medium">
                          Logo Background
                        </FormLabel>
                        <Text fontSize="14px" lineHeight="20px">
                          Select a background color or transparency to help
                          enhance your logo visibility on screen.
                        </Text>
                        <RadioGroup
                          {...field}
                          fontSize="16px"
                          fontWeight="medium"
                          id="logoBackground"
                          sx={{ my: 2 }}
                        >
                          <Stack>
                            {Object.values(LogoBackground).map((lbg) => (
                              <Radio key={lbg} value={lbg}>
                                {logoBackgroundMap[lbg]}
                              </Radio>
                            ))}
                          </Stack>
                        </RadioGroup>
                        <FormErrorMessage>
                          {fieldState.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Stack direction="row" gap={2}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onClose} colorScheme="brand.blue">
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
