import React from "react";
import { SimpleGrid, SimpleGridProps, Text } from "@chakra-ui/react";

import { ComponentReport, IntercomComponentStatus } from "src/common/types";
import { COMPONENT_TYPE_DISPLAY_NAMES } from "src/common/constants";

type ComponentListProps = SimpleGridProps & {
  components: ComponentReport[];
};

export const ComponentList = ({ components, ...props }: ComponentListProps) => {
  const unhealthyComponents = components
    .filter((c) => c.status !== IntercomComponentStatus.ONLINE)
    .sort((a, b) => a.componentType.localeCompare(b.componentType))
    .map((c) => COMPONENT_TYPE_DISPLAY_NAMES[c.componentType]);

  if (unhealthyComponents.length === 0) {
    return null;
  }

  return (
    <SimpleGrid columns={2} {...props}>
      {unhealthyComponents.map((component) => (
        <Text key={component}>{component}</Text>
      ))}
    </SimpleGrid>
  );
};
