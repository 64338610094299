import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React, { FC } from "react";

import { COMPONENT_TYPE_DISPLAY_NAMES } from "src/common/constants";
import { IntercomComponent } from "src/common/types";

type IntercomComponentsProps = {
  components: IntercomComponent[];
};

export const IntercomComponents: FC<IntercomComponentsProps> = ({
  components,
}) => (
  <Accordion
    border="1px solid"
    borderColor="brand.lightGray.500"
    borderRadius="6px"
    allowToggle
  >
    <AccordionItem border="none">
      <AccordionButton>
        <Text
          as="span"
          fontSize="lg"
          fontWeight="extrabold"
          flex={1}
          textAlign="left"
        >
          Intercom Components
        </Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        {components?.length ? (
          <Stack divider={<StackDivider borderColor="brand.lightGray.500" />}>
            {components.map((component) => (
              <Flex key={component.componentId} justifyContent="space-between">
                <Text fontWeight="bold">
                  {COMPONENT_TYPE_DISPLAY_NAMES[component.componentType]}
                </Text>
                <Text>{component.serialNumber ?? ""}</Text>
              </Flex>
            ))}
          </Stack>
        ) : (
          <Flex justifyContent="center">
            <Text>No components registered</Text>
          </Flex>
        )}
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
