import * as React from "react";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Link as UiLink,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  UseMenuButtonProps,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import {
  HiOutlineExternalLink,
  HiOutlineLogout,
  HiOutlineSwitchHorizontal,
} from "react-icons/hi";
import { useAuth0 } from "@auth0/auth0-react";

import { useMyPropertyOrganizationsQuery } from "src/common/queries";

const UserAvatar = () => <Avatar size="sm" />;

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      {...buttonProps}
      as="button"
      flexShrink={0}
      rounded="full"
      outline="0"
      _focus={{ shadow: "outline" }}
    >
      <Box srOnly>Open user menu</Box>
      <UserAvatar />
    </Flex>
  );
};

type ProfileDropdownProps = {
  hideNavigation?: boolean;
};
export const ProfileDropdown = ({ hideNavigation }: ProfileDropdownProps) => {
  const { user } = useAuth0();

  const email = user?.email;
  const myPropertyOrganizations = useMyPropertyOrganizationsQuery();
  const navigate = useNavigate();

  return (
    <Menu>
      <ProfileMenuButton />
      <MenuList rounded="md" shadow="lg" py="1" color="gray.600" fontSize="sm">
        <HStack px="3" py="4">
          <UserAvatar />
          <Box lineHeight="1">
            <Text mt="1" fontSize="xs" color="gray.500">
              {email}
            </Text>
          </Box>
        </HStack>
        <MenuItem
          fontWeight="medium"
          as={UiLink}
          href="http://property-support.gatehawk.com/"
          target="_blank"
          rel="noopener noreferrer"
          justifyContent="space-between"
        >
          GateHawk Support
          <HiOutlineExternalLink />
        </MenuItem>
        {!hideNavigation && (
          <>
            <MenuItem
              fontWeight="medium"
              as={UiLink}
              href="https://gatehawk.com/legal/end-user-license-agreement.html"
              target="_blank"
              rel="noopener noreferrer"
              justifyContent="space-between"
            >
              End User License Agreement
              <HiOutlineExternalLink />
            </MenuItem>
            <MenuItem
              fontWeight="medium"
              as={UiLink}
              href="https://gatehawk.com/legal/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              justifyContent="space-between"
            >
              Privacy Policy
              <HiOutlineExternalLink />
            </MenuItem>

            {myPropertyOrganizations.isSuccess &&
              !!myPropertyOrganizations?.data &&
              myPropertyOrganizations.data.length > 1 && (
                <MenuItem
                  fontWeight="medium"
                  as={UiLink}
                  justifyContent="space-between"
                  onClick={() => {
                    navigate(`/property-organizations`);
                  }}
                >
                  Switch Organizations
                  <HiOutlineSwitchHorizontal />
                </MenuItem>
              )}
          </>
        )}

        <MenuItem
          fontWeight="medium"
          color="red.500"
          as={Link}
          to={"/logout"}
          justifyContent="space-between"
        >
          Sign out
          <HiOutlineLogout />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
