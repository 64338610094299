import React from "react";
import { Outlet } from "react-router-dom";

import { useProductActivationsQuery } from "src/common/queries";
import { Loading } from "src/common/Loading";

export const IntercomUnitsLoader = () => {
  const query = useProductActivationsQuery();

  if (query.isLoading || !query.data) {
    return <Loading />;
  }

  return <Outlet context={{ intercomActivations: query.data }} />;
};
