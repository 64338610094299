import React from "react";
import { Button, ButtonGroup, Link } from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";

import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { useCurrentPropertyOrganization } from "src/common/queries";
import { useIsAdmin } from "src/common/useIsAdmin";
import { PropertyAdminContactDetails } from "src/routes/Profile/PropertyAdminContactDetails";
import { PropertyOwnerContactDetails } from "src/routes/Profile/PropertyOwnerContactDetails";
import { CompanyInformation } from "src/routes/Profile/CompanyInformation";

export const Profile = () => {
  const { propertyOrganizationId } = useParams();
  const currentPropertyOrganization = useCurrentPropertyOrganization(
    propertyOrganizationId as string
  );
  const isAdmin = useIsAdmin();

  if (!currentPropertyOrganization) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader
        buttons={
          <ButtonGroup>
            {isAdmin && (
              <Link
                as={RouterLink}
                to={`/property-organizations/${propertyOrganizationId}/profile/edit`}
              >
                <Button colorScheme="brand.blue">Edit Property Profile</Button>
              </Link>
            )}
          </ButtonGroup>
        }
      >
        <PageHeaderTitle>My Property Details</PageHeaderTitle>
      </PageHeader>

      <CompanyInformation propertyOrganization={currentPropertyOrganization} />
      <PropertyOwnerContactDetails
        propertyOrganization={currentPropertyOrganization}
      />
      <PropertyAdminContactDetails
        propertyOrganization={currentPropertyOrganization}
      />
    </>
  );
};
