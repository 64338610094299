import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { AccessCredential, Tenant } from "src/common/types";
import { useKy } from "src/common/ky";

export const useGetTenantQuery = () => {
  const { tenantId } = useParams();
  const ky = useKy();

  return useQuery<Tenant>(
    ["TENANT", tenantId],
    () => ky.get(`tenants/${tenantId}`).json<Tenant>(),
    {
      staleTime: 1000 * 60,
    }
  );
};

const getVirtualAccessCredentialQueryKey = (tenantId: string) => [
  "TENANT_VIRTUAL_ACCESS_CREDENTIAL",
  tenantId,
];
export const useInvalidateVirtualAccessCredentialQuery = () => {
  const { tenantId } = useParams() as { tenantId: string };
  const queryClient = useQueryClient();

  return useCallback(() => {
    return queryClient.invalidateQueries(
      getVirtualAccessCredentialQueryKey(tenantId)
    );
  }, [queryClient, tenantId]);
};
export const useGetTenantVirtualAccessCredentialQuery = (enabled = false) => {
  const { tenantId } = useParams() as { tenantId: string };
  const ky = useKy();

  return useQuery<AccessCredential>(
    getVirtualAccessCredentialQueryKey(tenantId),
    () =>
      ky
        .get(`tenants/${tenantId}/virtual-access-credential`)
        .json<AccessCredential>(),
    {
      staleTime: 1000 * 60,
      enabled,
    }
  );
};
