import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { PaginatedResult, Unit, UnitGroup } from "src/common/types";
import { HTTPError, useKy } from "src/common/ky";
import { ALLOWED_PAGINATION_SEARCH_PARAM_KEYS } from "src/common/queries";
import { cleanSearchParams } from "src/common/util";

export const getUnitsQueryKey = (searchParams: URLSearchParams) => {
  searchParams.sort();

  return ["UNITS", searchParams.toString()];
};

export const useUnitsQuery = () => {
  const ky = useKy();
  const { propertyOrganizationId, unitGroupId } = useParams<string>();
  const searchParams = new URLSearchParams(location.search);

  cleanSearchParams(ALLOWED_PAGINATION_SEARCH_PARAM_KEYS, searchParams);

  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", "1");
  }
  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  if (unitGroupId) {
    searchParams.set("unitGroupId", unitGroupId);
  }

  return useQuery<PaginatedResult<Unit>>(getUnitsQueryKey(searchParams), () =>
    ky.get(`units`, { searchParams }).json<PaginatedResult<Unit>>()
  );
};

export const getUnitQueryKey = (unitId?: string) => ["UNIT", unitId];

export const useGetUnitQuery = () => {
  const ky = useKy();
  const { unitId } = useParams() as { unitId: string };

  return useQuery<Unit>(getUnitQueryKey(unitId), () =>
    ky.get(`units/${unitId}`).json<Unit>()
  );
};

export const getGetUnitUnitGroupKey = (unitId?: string) => [
  "UNIT",
  unitId,
  "UNIT_GROUP",
];
export const useGetUnitUnitGroupQuery = () => {
  const { unitId } = useParams();
  const ky = useKy();
  return useQuery<{ unitGroup: UnitGroup | null }, HTTPError>(
    getGetUnitUnitGroupKey(unitId),
    () =>
      ky
        .get(`units/${unitId}/unit-group`)
        .json<{ unitGroup: UnitGroup | null }>()
  );
};
