import React, { useCallback, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HiEye, HiOutlineDotsVertical, HiPencil } from "react-icons/hi";
import { HTTPError } from "ky";
import { useMutation, useQueryClient } from "react-query";
import { useFlags } from "launchdarkly-react-client-sdk";

import { IntercomActivation } from "src/common/types";
import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { GateOpen } from "src/common/icons/GateOpen";
import { GateClosed } from "src/common/icons/GateClosed";
import { getProductActivationsQueryKey } from "src/common/queries";
import { ScheduleStatusTag } from "src/routes/IntercomUnits/ScheduleStatusTag";
import { noop } from "src/common/util";
import { useKy } from "src/common/ky";

type UpdateForcedOpenStatusMutationVariables = {
  intercomActivation: IntercomActivation;
  isForcedOpen: boolean;
};

export const IntercomUnits = () => {
  const navigate = useNavigate();
  const { propertyOrganizationId } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const ky = useKy();
  const { intercomActivations } =
    useOutletContext<{ intercomActivations: IntercomActivation[] }>();
  const [overridingActivation, setOverridingActivation] =
    useState<IntercomActivation | null>(null);

  const {
    isOpen: overrideModalIsOpen,
    onOpen: onOpenOverrideModal,
    onClose: onCloseOverrideModal,
  } = useDisclosure();

  const navigateToIntercomActivation = useCallback(
    (id: string, route = "") =>
      navigate(
        `/property-organizations/${propertyOrganizationId}/intercom-units/${id}${route}`
      ),
    [propertyOrganizationId]
  );

  const updateForcedOpenStatus = useMutation<
    void,
    HTTPError,
    UpdateForcedOpenStatusMutationVariables
  >(
    async ({ intercomActivation, isForcedOpen }) => {
      await ky.post(
        `product-unit-activations/${intercomActivation.intercomActivationId}/override-status`,
        {
          json: {
            isForcedOpen,
          },
        }
      );
    },
    {
      onSuccess: () => {
        onCloseOverrideModal();
        queryClient.invalidateQueries(
          getProductActivationsQueryKey(propertyOrganizationId)
        );
        toast({
          description: `${overridingActivation?.name || "Intercom Unit"} is ${
            overridingActivation?.isForcedOpen ? "no longer" : "now"
          } being forced open.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setOverridingActivation(null);
      },
    }
  );

  const flags = useFlags();

  const content = !intercomActivations.length ? (
    <Flex>There are no intercom units active on the property.</Flex>
  ) : (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Product Model</Th>
          <Th>Serial Number</Th>
          {flags.gateSchedules && <Th>Schedule Status</Th>}
          <Th>{/* Menu */}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {intercomActivations.map((intercomActivation) => (
          <Tr
            key={intercomActivation.intercomActivationId}
            _hover={{ bg: "brand.lightGray.100", cursor: "pointer" }}
            onClick={() => {
              navigateToIntercomActivation(
                intercomActivation.intercomActivationId
              );
            }}
          >
            <Td>{intercomActivation.name}</Td>
            <Td>
              {intercomActivation.intercomModel} -{" "}
              {intercomActivation.intercomModelSku}
            </Td>
            <Td>{intercomActivation.serialNumber}</Td>
            {flags.gateSchedules && (
              <Td>
                <ScheduleStatusTag intercomActivation={intercomActivation} />
              </Td>
            )}
            <Td>
              {flags.gateSchedules && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Actions"
                    icon={<HiOutlineDotsVertical />}
                    variant="clear"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      justifyContent="space-between"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToIntercomActivation(
                          intercomActivation.intercomActivationId
                        );
                      }}
                    >
                      View Intercom
                      <Spacer w={10} />
                      <HiEye />
                    </MenuItem>
                    <MenuItem
                      justifyContent="space-between"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateToIntercomActivation(
                          intercomActivation.intercomActivationId,
                          "/schedule"
                        );
                      }}
                    >
                      Set Schedule
                      <HiPencil />
                    </MenuItem>
                    <MenuItem
                      color="red.500"
                      justifyContent="space-between"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOverridingActivation(intercomActivation);
                        onOpenOverrideModal();
                      }}
                    >
                      {intercomActivation.isForcedOpen
                        ? "End Force Unlock"
                        : "Force Unlock Entry Point"}
                      <Spacer w={10} />
                      {intercomActivation.isForcedOpen ? (
                        <GateClosed />
                      ) : (
                        <GateOpen />
                      )}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <>
      <PageHeader>
        <PageHeaderTitle>Intercom Units</PageHeaderTitle>
      </PageHeader>
      <PageContent>{content}</PageContent>
      <Modal
        isOpen={overrideModalIsOpen}
        onClose={() => {
          onCloseOverrideModal();
          setOverridingActivation(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {overridingActivation?.isForcedOpen
              ? `End Force Unlock on ${overridingActivation?.name}`
              : `Force Unlock ${overridingActivation?.name}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {overridingActivation?.isForcedOpen
              ? `This will return ${overridingActivation?.name} to its schedule if it has one set up.`
              : `Force Unlocking ${overridingActivation?.name} will force its connected entry point to be unlocked indefinitely until the override is removed.`}
          </ModalBody>

          <ModalFooter>
            <Button
              color="black"
              colorScheme="brand.lightGray"
              mr={3}
              onClick={() => {
                onCloseOverrideModal();
                setOverridingActivation(null);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => {
                if (overridingActivation) {
                  return updateForcedOpenStatus
                    .mutateAsync({
                      intercomActivation: overridingActivation,
                      isForcedOpen: !overridingActivation.isForcedOpen,
                    })
                    .catch(noop);
                }
              }}
              isDisabled={updateForcedOpenStatus.isLoading}
            >
              {overridingActivation?.isForcedOpen
                ? "Stop Forcing Unlock"
                : "Force Unlock"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
