import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

type PreviewWarningProps = {
  title?: string;
  message: string;
};

export const PreviewWarning = ({ title, message }: PreviewWarningProps) => (
  <Alert status="warning">
    <AlertIcon />
    <AlertTitle>{title}</AlertTitle>
    <AlertDescription>{message}</AlertDescription>
  </Alert>
);
