import React, { FC } from "react";

import {
  IntercomActivation,
  LogoBackground,
  ScreenBackground,
  ScreenSize,
} from "src/common/types";
import { isSupportedValue } from "src/common/util";
import { PreviewContainer } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/PreviewContainer";
import { PreviewLogo } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/PreviewLogo";
import { PreviewStrongInlineText } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/PreviewStrongInlineText";
import { PreviewText } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/PreviewText";
import { PreviewTitleText } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/PreviewTitleText";
import { PreviewWarning } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/PreviewWarning";

type IntercomSplashScreenPreviewProps = {
  screenSize: ScreenSize;
  intercomActivation: Pick<
    IntercomActivation,
    "background" | "propertyNameOverride" | "logoUrl" | "logoBackground"
  > & { imageData?: string | null };
};

export const IntercomSplashScreenPreview: FC<
  IntercomSplashScreenPreviewProps
> = ({
  screenSize,
  intercomActivation: {
    background,
    propertyNameOverride,
    logoUrl,
    logoBackground,
    imageData,
  },
}) => {
  if (!isSupportedValue(screenSize, ScreenSize)) {
    return (
      <PreviewWarning
        title="Screen size not supported!"
        message={`The detected screen size for this intercom is ${screenSize} inches,
        which is not supported in this preview.`}
      />
    );
  }

  if (!isSupportedValue(background, ScreenBackground)) {
    return (
      <PreviewWarning
        title="Background not supported!"
        message={`The detected background for this intercom is "${background}",
          which is not supported in this preview.`}
      />
    );
  }

  if (!isSupportedValue(logoBackground, LogoBackground)) {
    return (
      <PreviewWarning
        title="Logo Background not supported!"
        message={`The detected logo background for this intercom is "${logoBackground}",
          which is not supported in this preview.`}
      />
    );
  }

  const title = propertyNameOverride || "Welcome!";
  return (
    <PreviewContainer
      screenSize={screenSize}
      background={background}
      propertyNameOverride={propertyNameOverride}
    >
      {propertyNameOverride ? (
        <PreviewText screenSize={screenSize}>Welcome to</PreviewText>
      ) : null}

      <PreviewTitleText
        screenSize={screenSize}
        propertyNameOverride={propertyNameOverride}
      >
        {title}
      </PreviewTitleText>

      <PreviewText
        screenSize={screenSize}
        sx={{
          pb: propertyNameOverride ? undefined : "50px",
          mt: propertyNameOverride ? "40px" : undefined,
        }}
      >
        <PreviewStrongInlineText fontWeight="700">
          Press any button
        </PreviewStrongInlineText>{" "}
        to view directory
      </PreviewText>

      <PreviewLogo
        screenSize={screenSize}
        logoUrl={imageData === null ? imageData : imageData || logoUrl}
        logoBackground={logoBackground}
      />
    </PreviewContainer>
  );
};
