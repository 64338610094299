import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

import { queryStringify } from "src/common/formatters";
import { HTTPError, KyOptions, useKy } from "src/common/ky";
import {
  IntercomActivation,
  IntercomComponent,
  IntercomFacePlateLog,
  IntercomHealth,
  IntercomServiceLog,
  PaginatedResult,
  PaginationOptions,
  PropertyOrganization,
  UnitGroup,
} from "src/common/types";

export const ALLOWED_PAGINATION_SEARCH_PARAM_KEYS = [
  "propertyOrganizationId",
  "name",
  "sortBy",
  "sortDir",
  "pageNumber",
  "pageSize",
];

export const MY_PROPERTY_ORGANIZATIONS_QUERY_KEY = "MY_PROPERTY_ORGANIZATIONS";
export const useMyPropertyOrganizationsQuery = (options?: {
  kyOptions?: KyOptions;
}) => {
  const ky = useKy(options?.kyOptions);
  return useQuery<PropertyOrganization[]>(
    MY_PROPERTY_ORGANIZATIONS_QUERY_KEY,
    () =>
      ky
        .get("property-organizations/my-property-organizations")
        .json<PropertyOrganization[]>(),
    {
      staleTime: 1000 * 60,
    }
  );
};

export const useCurrentPropertyOrganization = (
  propertyOrganizationId: string,
  options?: {
    kyOptions?: KyOptions;
  }
) => {
  const myPropertyOrganizations = useMyPropertyOrganizationsQuery(options);

  return useMemo(() => {
    if (myPropertyOrganizations.isSuccess) {
      return myPropertyOrganizations.data.find(
        (propertyOrganization) =>
          propertyOrganization.propertyOrganizationId === propertyOrganizationId
      );
    }

    return null;
  }, [myPropertyOrganizations, propertyOrganizationId]);
};

export const useCurrentPropertyUser = (propertyOrganizationId: string) => {
  const propertyOrganization = useCurrentPropertyOrganization(
    propertyOrganizationId
  );

  return useMemo(() => {
    if (propertyOrganization) {
      return propertyOrganization.propertyUser;
    }

    return null;
  }, [propertyOrganization]);
};

export const usePropertyUserRole = (propertyOrganizationId: string) => {
  const propertyUser = useCurrentPropertyUser(propertyOrganizationId);

  return useMemo(() => {
    if (propertyUser) {
      return propertyUser.role;
    }

    return null;
  }, [propertyUser]);
};

export const getProductActivationsQueryKey = (
  propertyOrganizationId?: string
) => ["INTERCOM_ACTIVATIONS", propertyOrganizationId];
export const useProductActivationsQuery = () => {
  const { propertyOrganizationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomActivation[], HTTPError>(
    getProductActivationsQueryKey(propertyOrganizationId),
    () =>
      ky
        .get(
          `product-unit-activations/property-organization/${propertyOrganizationId}`
        )
        .json<IntercomActivation[]>()
  );
};

export const getProductActivationUnitGroupKey = (
  intercomActivationId?: string
) => ["INTERCOM_ACTIVATION", intercomActivationId, "UNIT_GROUP"];
export const useProductActivationUnitGroupQuery = () => {
  const { activationId } = useParams();
  const ky = useKy();
  return useQuery<{ unitGroup: UnitGroup | null }, HTTPError>(
    getProductActivationUnitGroupKey(activationId),
    () =>
      ky
        .get(`product-unit-activations/${activationId}/unit-group`)
        .json<{ unitGroup: UnitGroup | null }>()
  );
};

export const getProductActivationComponentsKey = (
  intercomActivationId?: string
) => ["INTERCOM_ACTIVATION", intercomActivationId, "COMPONENTS"];
export const useProductActivationComponentsQuery = () => {
  const { activationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomComponent[], HTTPError>(
    getProductActivationComponentsKey(activationId),
    () =>
      ky
        .get(`product-unit-activations/${activationId}/components`)
        .json<IntercomComponent[]>()
  );
};

export const getIntercomHealthQueryKey = (intercomActivationId?: string) => [
  "INTERCOM_ACTIVATIONS",
  intercomActivationId,
  "HEALTH",
];
export const useIntercomHealthQuery = () => {
  const { activationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomHealth, HTTPError>(
    getIntercomHealthQueryKey(activationId),
    () =>
      ky
        .get(`product-unit-activations/${activationId}/health`)
        .json<IntercomHealth>()
  );
};

export const getIntercomServiceLogsQueryKey = (
  intercomActivationId?: string
) => ["INTERCOM_ACTIVATIONS", intercomActivationId, "SERVICE_LOGS"];
export const useIntercomServiceLogsQuery = (
  params: PaginationOptions = {
    pageSize: 3,
    sortBy: "startedAt",
    sortDir: "DESC",
  }
) => {
  const { activationId } = useParams();
  const ky = useKy();
  return useQuery<PaginatedResult<IntercomServiceLog>, HTTPError>(
    getIntercomServiceLogsQueryKey(activationId),
    () =>
      ky
        .get(
          `product-unit-activations/${activationId}/sessions${queryStringify(
            params
          )}`
        )
        .json<PaginatedResult<IntercomServiceLog>>()
  );
};

export const getIntercomFacePlateLogsQueryKey = (
  intercomActivationId?: string
) => ["INTERCOM_ACTIVATIONS", intercomActivationId, "FACEPLATE_LOGS"];
export const useIntercomFacePlateLogsQuery = () => {
  const { activationId } = useParams();
  const ky = useKy();
  return useQuery<IntercomFacePlateLog[], HTTPError>(
    getIntercomFacePlateLogsQueryKey(activationId),
    () =>
      ky
        .get(`product-unit-activations/${activationId}/faceplate-logs`)
        .json<IntercomFacePlateLog[]>()
  );
};
