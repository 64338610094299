import React from "react";
import {
  Box,
  Container,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { PageContent } from "src/layout/PageContent";
import { TopBar } from "src/layout/PropertyOrganizationLayout";
import { PropertyOrganization } from "src/common/types";
import {
  PageHeader,
  PageHeaderSubtitle,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { AppErrorBoundary } from "src/common/ErrorBoundary/AppErrorBoundary";
import { ErrorOutline } from "src/common/icons/ErrorOutline";

const NoPropertyOrganizationsFound = () => (
  <Container maxW="7xl" h="calc(80vh)" centerContent={true}>
    <Flex
      h="100%"
      w="50%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ErrorOutline w={53} h={53} color="#FF595E" mb={30} />
      <Box>
        <Text fontSize="30px" fontWeight={600} textAlign="center">
          It seems you don&apos;t have permission to log in to this portal.
        </Text>
      </Box>
    </Flex>
  </Container>
);

type PropertyOrganizationPickerProps = {
  propertyOrganizations: PropertyOrganization[];
};
export const SelectPropertyOrganization = ({
  propertyOrganizations,
}: PropertyOrganizationPickerProps) => {
  const contents = propertyOrganizations.length ? (
    <>
      <PageHeader>
        <PageHeaderTitle>Select Property Organization</PageHeaderTitle>
        <PageHeaderSubtitle>
          Select the Property Organization you wish to view:
        </PageHeaderSubtitle>
      </PageHeader>
      <PageContent>
        <Table variant="simple">
          <Tbody>
            {propertyOrganizations.map((organization) => (
              <Tr key={organization.propertyOrganizationId}>
                <Td>
                  <Link
                    as={RouterLink}
                    to={`${organization.propertyOrganizationId}`}
                  >
                    <Text>{organization.name}</Text>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageContent>
    </>
  ) : (
    <NoPropertyOrganizationsFound />
  );

  return (
    <>
      <TopBar hideNavigation />
      <AppErrorBoundary>{contents}</AppErrorBoundary>
    </>
  );
};
