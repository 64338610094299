import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

import { ScreenSize } from "src/common/types";
import { config } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomScreenPreview/previewConfig";

type PreviewTitleTextProps = TextProps & {
  screenSize: ScreenSize;
  propertyNameOverride?: string | null;
};

export const PreviewTitleText = ({
  children,
  screenSize,
  propertyNameOverride,
  ...props
}: PreviewTitleTextProps) => {
  const { fontSize, lineHeight } = config[screenSize];

  return (
    <Text
      fontWeight="extrabold"
      fontSize={propertyNameOverride ? fontSize.titleAlternate : fontSize.title}
      lineHeight={lineHeight.title}
      letterSpacing="tight"
      color="white"
      fontFamily="Montserrat"
      {...props}
    >
      {children}
    </Text>
  );
};
