import React, { ReactNode } from "react";
import { Box, useRadio, UseRadioProps } from "@chakra-ui/react";

interface PropertyGuestScheduleTypeOptionProps extends UseRadioProps {
  children: ReactNode;
}

export const PropertyGuestScheduleTypeOption = (
  props: PropertyGuestScheduleTypeOptionProps
) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as={"label"} w={"30%"} h={"100%"}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        textAlign="center"
      >
        {props.children}
      </Box>
    </Box>
  );
};
