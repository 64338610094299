import { LogoBackground, ScreenSize } from "src/common/types";

export type ConfigByScreenSize = {
  height: number;
  width: number;
  fontSize: {
    title: string;
    titleAlternate: string;
    default: string;
  };
  lineHeight: {
    title: string;
    default: string;
  };
  logoHeight: number;
  logoWidth: number;
  logoMargin: number;
};

export const config: Record<ScreenSize, ConfigByScreenSize> = {
  [ScreenSize.TenInch]: {
    height: 565,
    width: 350,
    fontSize: { title: "40px", titleAlternate: "40px", default: "14px" },
    lineHeight: { title: "54px", default: "17px" },
    logoHeight: 75,
    logoWidth: 75,
    logoMargin: 5,
  },
  [ScreenSize.TwelveInch]: {
    height: 425,
    width: 730,
    fontSize: { title: "70px", titleAlternate: "45px", default: "18px" },
    lineHeight: { title: "85px", default: "22px" },
    logoHeight: 100,
    logoWidth: 100,
    logoMargin: 8,
  },
};

export const logoBackgroundMap: Record<LogoBackground, string> = {
  [LogoBackground.TRANSPARENT]: "Transparent",
  [LogoBackground.BLACK]: "Black",
  [LogoBackground.WHITE]: "White",
};
