import { useState } from "react";
import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";

import { useKy } from "src/common/ky";
import { useInvalidateVirtualAccessCredentialQuery } from "src/routes/ViewTenant/queries";

export const useResetTenantVirtualAccessCredential = () => {
  const toast = useToast();
  const ky = useKy();
  const invalidateVirtualAccessCredentialQuery =
    useInvalidateVirtualAccessCredentialQuery();

  const [resettingForTenantId, setResettingForTenantId] = useState<
    string | undefined
  >();
  const resetTenantVirtualAccessCredentialMutation = useMutation(
    () =>
      ky.post(
        `tenants/${resettingForTenantId}/reset-virtual-access-credential`
      ),
    {
      onSuccess: async () => {
        await invalidateVirtualAccessCredentialQuery();
        setResettingForTenantId(undefined);
        toast({
          description: "Access Code Reset Successful!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return {
    isResettingVirtualAccessCredential: !!resettingForTenantId,
    resetTenantVirtualAccessCredentialMutation,
    setResettingForTenantId,
  };
};
