import { Container } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Link } from "@chakra-ui/react";

import { IntercomGeneration } from "src/common/types";
import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderTabs,
  PageHeaderSubtitle,
  PageHeaderBackLink,
} from "src/layout/PageHeader";
import { TabLink } from "src/layout/TabLink";
import useIntercomDetails from "src/routes/IntercomUnits/ViewIntercomUnit/useIntercomDetails";

type ViewIntercomUnitLayoutProps = {
  children: React.ReactNode;
  title: string;
  additionalContent?: React.ReactNode;
  includeCard?: boolean;
  subtitle?: string;
};

export const ViewIntercomUnitLayout = ({
  children,
  title,
  additionalContent,
  includeCard = true,
  subtitle,
}: ViewIntercomUnitLayoutProps) => {
  const { propertyOrganizationId, activationId } = useParams();
  const { generation } = useIntercomDetails();
  const isGen4Plus = generation !== IntercomGeneration.GEN_3;

  const Content = includeCard
    ? PageContent
    : ({ children }: { children: ReactNode }) => (
        <Container as="main" maxW="7xl" pb={20}>
          {children}
        </Container>
      );

  const basePath = `/property-organizations/${propertyOrganizationId}/intercom-units/${activationId}`;
  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/intercom-units`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>{title}</PageHeaderTitle>
        {subtitle ? <PageHeaderSubtitle>{subtitle}</PageHeaderSubtitle> : null}
        <PageHeaderTabs>
          <TabLink
            key="details"
            to={`${basePath}/details`}
            label="Details"
            end
          />
          {isGen4Plus ? (
            <TabLink
              key="system"
              to={`${basePath}/system`}
              label="System"
              end
            />
          ) : null}
          <TabLink
            key="cell-kits"
            to={`${basePath}/cell-kits`}
            label="Cell Upgrade Kit"
            end
          />
          {isGen4Plus ? (
            <TabLink
              key="splash-screen"
              to={`${basePath}/splash-screen`}
              label="Splash Screen"
              end
            />
          ) : null}
          <TabLink
            key="dmi"
            to={`${basePath}/dmi`}
            label="Virtual Intercom"
            end
          />
        </PageHeaderTabs>
      </PageHeader>
      {additionalContent ? additionalContent : null}
      <Content>{children}</Content>
    </>
  );
};
