import React from "react";
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";

import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { PageContent } from "src/layout/PageContent";
import { HTTPError, useKy } from "src/common/ky";
import { states } from "src/common/us-states";
import { Loading } from "src/common/Loading";
import {
  MY_PROPERTY_ORGANIZATIONS_QUERY_KEY,
  useCurrentPropertyOrganization,
} from "src/common/queries";
import { noop } from "src/common/util";
import { handleHookFormHTTPError } from "src/common/form";
import { PhoneNumberInput } from "src/common/PhoneNumbers";
import { YupPossiblePhoneNumber } from "src/common/phone-numbers";

interface EditPropertyOrganizationValues {
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerAddress: string;
  ownerCity: string;
  ownerState: string;
  ownerZip: string;
  ownerPhone: string;
  ownerEmail: string;
  dealerOrganizationId: string;
}

const EditPropertyOrganizationValidationSchema = Yup.object().shape({
  name: Yup.string().required().label("Property Name"),
  phone: YupPossiblePhoneNumber({
    label: "Property Phone Number",
    required: true,
  }),
  address: Yup.string().required().label("Property Address"),
  city: Yup.string().required().label("Property City"),
  state: Yup.string().required().label("Property State"),
  zip: Yup.string().min(5).required().label("Property Zip Code"),
  email: Yup.string().email().required().label("Property Email"),
  ownerFirstName: Yup.string().required().label("Property Owner First Name"),
  ownerLastName: Yup.string().required().label("Property Owner Last Name"),
  ownerAddress: Yup.string().required().label("Property Owner Address"),
  ownerCity: Yup.string().required().label("Property Owner City"),
  ownerState: Yup.string().required().label("Property Owner State"),
  ownerZip: Yup.string().min(5).required().label("Property Owner Zip Code"),
  ownerPhone: YupPossiblePhoneNumber({
    label: "Property Owner Phone",
    required: true,
  }),
  ownerEmail: Yup.string().email().required().label("Property Owner Email"),
});

export const ProfileEdit = () => {
  const { propertyOrganizationId } = useParams();
  const queryClient = useQueryClient();
  const currentPropertyOrganization = useCurrentPropertyOrganization(
    propertyOrganizationId as string
  );
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitting },
  } = useForm<EditPropertyOrganizationValues>({
    defaultValues: {
      name: currentPropertyOrganization?.name,
      phone: currentPropertyOrganization?.phone,
      address: currentPropertyOrganization?.address,
      city: currentPropertyOrganization?.city,
      state: currentPropertyOrganization?.state,
      zip: currentPropertyOrganization?.zip,
      email: currentPropertyOrganization?.email,
      ownerFirstName: currentPropertyOrganization?.ownerFirstName,
      ownerLastName: currentPropertyOrganization?.ownerLastName,
      ownerAddress: currentPropertyOrganization?.ownerAddress,
      ownerCity: currentPropertyOrganization?.ownerCity,
      ownerState: currentPropertyOrganization?.ownerState,
      ownerZip: currentPropertyOrganization?.ownerZip,
      ownerPhone: currentPropertyOrganization?.ownerPhone,
      ownerEmail: currentPropertyOrganization?.ownerEmail,
    },
    resolver: yupResolver(EditPropertyOrganizationValidationSchema),
  });

  const toast = useToast();
  const ky = useKy();

  const updatePropertyOrganization = useMutation<
    void,
    HTTPError,
    EditPropertyOrganizationValues
  >(
    async (values: EditPropertyOrganizationValues) => {
      await ky.patch(`property-organizations/${propertyOrganizationId}`, {
        json: values,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MY_PROPERTY_ORGANIZATIONS_QUERY_KEY);
        navigate(`/property-organizations/${propertyOrganizationId}/profile`);
        toast({
          description: "Property Successfully Updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  if (!currentPropertyOrganization) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader>
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/profile`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Property Profile</PageHeaderTitle>
      </PageHeader>
      <PageContent>
        <form
          onSubmit={handleSubmit((values) =>
            updatePropertyOrganization.mutateAsync(values).catch(noop)
          )}
        >
          <Grid gap={6} templateColumns="repeat(4 1fr)">
            <GridItem colSpan={2}>
              <Heading as={"h3"} fontSize="24px" mt={4}>
                Company Information
              </Heading>
            </GridItem>

            <GridItem colSpan={2}>
              <Divider />
            </GridItem>

            <GridItem>
              <Stack gap={4}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="name">Property Name</FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="name"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="phone">Property Phone</FormLabel>
                      <PhoneNumberInput
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="phone"
                      />
                      <FormHelperText width={["100%", "100%", "50%", "50%"]}>
                        If the “Call a Manager” feature is enabled on your
                        intercom, incoming calls will be routed to this number.
                      </FormHelperText>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="address">Property Address</FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="address"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="city">Property City</FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="city"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="state"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="state">Property State</FormLabel>
                      <Select
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="state"
                      >
                        <option value="" />
                        {states.map((state) => (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="zip"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="zip">Property ZIP Code</FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="zip"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="email">Property Email</FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="email"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Stack>
            </GridItem>

            <GridItem colSpan={2}>
              <Heading as={"h3"} fontSize="24px">
                Property Owner Contact Information
              </Heading>
            </GridItem>

            <GridItem colSpan={2}>
              <Divider />
            </GridItem>

            <GridItem>
              <Stack gap={4}>
                <Controller
                  name="ownerFirstName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerFirstName">
                        Property Owner First Name
                      </FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerFirstName"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="ownerLastName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerLastName">
                        Property Owner Last Name
                      </FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerLastName"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="ownerAddress"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerAddress">
                        Property Owner Billing Address
                      </FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerAddress"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="ownerCity"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerCity">
                        Property Owner City
                      </FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerCity"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="ownerState"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerState">
                        Property Owner State
                      </FormLabel>
                      <Select
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerState"
                      >
                        <option value="" />
                        {states.map((state) => (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="ownerZip"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerZip">
                        Property Owner ZIP Code
                      </FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerZip"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="ownerEmail"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerEmail">
                        Property Owner Email
                      </FormLabel>
                      <Input
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerEmail"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  name="ownerPhone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      isRequired
                      isInvalid={fieldState.invalid && fieldState.isTouched}
                    >
                      <FormLabel htmlFor="ownerPhone">
                        Property Owner Phone Number
                      </FormLabel>
                      <PhoneNumberInput
                        width={["100%", "100%", "50%", "50%"]}
                        {...field}
                        id="ownerPhone"
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Stack>
            </GridItem>

            {updatePropertyOrganization.error && (
              <GridItem colSpan={2}>
                <Alert status="error">
                  <AlertIcon />
                  {updatePropertyOrganization.error.message}
                </Alert>
              </GridItem>
            )}

            <GridItem colSpan={2}>
              <Divider />
            </GridItem>

            <GridItem colSpan={2}>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Link
                  as={RouterLink}
                  to={`/property-organizations/${propertyOrganizationId}`}
                >
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  colorScheme="brand.blue"
                  isLoading={isSubmitting}
                >
                  Save Property Profile
                </Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};
