import * as Yup from "yup";

export const IntercomActivationUpdateDefaultMobileIntercomSchema = {
  enableDMI: Yup.boolean()
    .required()
    .default(false)
    .label("Enable Virtual Intercom"),
  enableDMIUA: Yup.boolean()
    .required()
    .default(true)
    .label("Enable Mobile Device Check"),
  lat: Yup.number()
    .min(-90)
    .max(90)
    .nullable()
    .default(null)
    .transform((value) => {
      return isNaN(value) ? null : value;
    })
    .label("Latitude"),
  long: Yup.number()
    .min(-180)
    .max(180)
    .nullable()
    .default(null)
    .transform((value) => {
      return isNaN(value) ? null : value;
    })
    .label("Longitude"),
  locationThresholdFeet: Yup.number()
    .required()
    .min(30)
    .max(1000)
    .default(30)
    .transform((value) => {
      return isNaN(value) ? 30 : value;
    })
    .label("Maximum Distance from Intercom"),
};
