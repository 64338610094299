import { Heading, Stack, StackDivider } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";

import { IntercomFacePlateLogs } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomSystem/IntercomFacePlateLogs";
import { Loading } from "src/common/Loading";
import { Card } from "src/layout/Card";
import { ViewIntercomUnitLayout } from "src/routes/IntercomUnits/ViewIntercomUnit/ViewIntercomUnitLayout";
import useIntercomDetails from "src/routes/IntercomUnits/ViewIntercomUnit/useIntercomDetails";
import { IntercomComponentList } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomSystem/IntercomComponentList";
import { IntercomHealthStatus } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomSystem/IntercomHealthStatus";
import { IntercomServiceLogs } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomSystem/IntercomServiceLogs";

const SectionHeading = ({ children }: { children: ReactNode }) => (
  <Heading as="h2" fontSize="20px" fontWeight="bold" mb={4} mt={6}>
    {children}
  </Heading>
);

const Section = ({
  children,
  title,
  includeCard = true,
}: {
  children: ReactNode;
  title: string;
  includeCard?: boolean;
}) => (
  <>
    <SectionHeading>{title}</SectionHeading>
    {includeCard ? <Card>{children}</Card> : children}
  </>
);

/**
 * This component handles viewing intercom system information.
 */
export const IntercomSystem: FC = () => {
  const { currentActivation } = useIntercomDetails();

  if (!currentActivation) {
    return <Loading />;
  }

  return (
    <ViewIntercomUnitLayout
      title="Intercom System"
      subtitle="View intercom health, service log, and intercom components."
      includeCard={false}
    >
      <Stack divider={<StackDivider />} spacing={8}>
        <Section title="Intercom Health">
          <IntercomHealthStatus />
        </Section>
        <Section title="Service Log" includeCard={false}>
          <IntercomServiceLogs />
        </Section>
        <Section title="Faceplate Logs" includeCard={false}>
          <IntercomFacePlateLogs />
        </Section>
        <Section title="Components" includeCard={false}>
          <IntercomComponentList />
        </Section>
      </Stack>
    </ViewIntercomUnitLayout>
  );
};
