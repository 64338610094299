import React, { useCallback } from "react";
import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import {
  HiOutlineDotsVertical,
  HiOutlineTrash,
  HiPencil,
} from "react-icons/hi";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderTabs,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import {
  useDeleteTenant,
  useResetTenantPassword,
} from "src/routes/TenantList/hooks";
import { ResetPassword } from "src/common/icons/ResetPassword";
import { TenantListFilterBar } from "src/routes/TenantList/TenantListFilterBar";
import { SortableTh } from "src/common/SortableTh";
import { useTenantsQuery } from "src/routes/TenantList/queries";
import { TabLink } from "src/layout/TabLink";
import { useFlags } from "src/common/useFlags";
import { Pagination } from "src/common/Pagination";
import { formatPhoneNumber } from "src/common/phone-numbers";

export const TenantList = () => {
  const { propertyOrganizationId } = useParams();
  const navigate = useNavigate();
  const query = useTenantsQuery();
  const flags = useFlags();

  const { isDeletingTenant, deleteTenantMutation, setDeletingTenantId } =
    useDeleteTenant();
  const {
    isResettingTenant,
    resetTenantPasswordMutation,
    setResettingTenantId,
  } = useResetTenantPassword();

  const onPageChange = useCallback(
    (nextPage: number) => {
      const newSp = new URLSearchParams(location.search);
      newSp.set("pageNumber", nextPage.toString());
      navigate(`?${newSp.toString()}`);
    },
    [location, navigate]
  );

  let contents;
  if (query.isLoading || !query.data) {
    contents = <Loading />;
  } else if (!query.data.result.length) {
    contents = <Flex>There are no tenants.</Flex>;
  } else {
    contents = (
      <>
        <Table variant="simple">
          <Thead>
            <Tr>
              <SortableTh sortKey="name">Tenant Name</SortableTh>
              <SortableTh isNumeric={true} sortKey="phone">
                Phone
              </SortableTh>
              <SortableTh sortKey="email">Email</SortableTh>
              <Th>Viewable?</Th>
              <SortableTh sortKey="createdAt">Date Added</SortableTh>
              <Th>{/* menu column */}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {query.data.result.map((tenant) => (
              <Tr
                key={tenant.tenantId}
                _hover={{ bg: "brand.lightGray.100", cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/property-organizations/${propertyOrganizationId}/units/tenants/${tenant.tenantId}`
                  );
                }}
              >
                <Td>
                  <Link
                    as={RouterLink}
                    to={`/property-organizations/${propertyOrganizationId}/units/tenants/${tenant.tenantId}`}
                  >{`${tenant.user.firstName} ${tenant.user.lastName}`}</Link>
                </Td>
                <Td>{formatPhoneNumber(tenant.user.phone)}</Td>
                <Td>{tenant.user.email}</Td>
                <Td>
                  {tenant.enableDirectoryListing ? (
                    <Checkbox isDisabled defaultChecked />
                  ) : (
                    <Checkbox isDisabled />
                  )}
                </Td>
                <Td>{format(new Date(tenant.createdAt), "P")}</Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Actions"
                      icon={<HiOutlineDotsVertical />}
                      variant="clear"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      Actions
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/property-organizations/${propertyOrganizationId}/units/tenants/${tenant.tenantId}/edit`
                          );
                        }}
                      >
                        Edit Tenant
                        <HiPencil />
                      </MenuItem>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={(e) => {
                          e.stopPropagation();
                          setResettingTenantId(tenant.tenantId);
                        }}
                      >
                        Reset Password
                        <ResetPassword />
                      </MenuItem>
                      <MenuItem
                        justifyContent="space-between"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeletingTenantId(tenant.tenantId);
                        }}
                      >
                        Remove Tenant
                        <HiOutlineTrash />
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          unitName="tenant"
          total={query.data.total}
          pageNumber={query.data.pageNumber}
          pageSize={query.data.pageSize}
          onPageChange={onPageChange}
        />
      </>
    );
  }

  return (
    <>
      <PageHeader buttons={<TenantListFilterBar />}>
        <PageHeaderTitle>Tenants</PageHeaderTitle>
        <PageHeaderTabs>
          {flags.units && (
            <TabLink
              key="units"
              to={`/property-organizations/${propertyOrganizationId}/units`}
              label="Units"
              end
            />
          )}
          <TabLink
            key="tenants"
            to={`/property-organizations/${propertyOrganizationId}/units/tenants`}
            label="Tenants"
            end
          />
          <TabLink
            key="tenant-guests"
            to={`/property-organizations/${propertyOrganizationId}/units/tenants/guests`}
            label="Guests"
            end
          />
        </PageHeaderTabs>
      </PageHeader>
      <PageContent>{contents}</PageContent>

      <Modal
        isOpen={isDeletingTenant}
        onClose={() => {
          setDeletingTenantId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Tenant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingTenantId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteTenantMutation.mutate()}
              isDisabled={deleteTenantMutation.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isResettingTenant}
        onClose={() => {
          setResettingTenantId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Tenant Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? This will immediately email the user a link to change
            their password.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setResettingTenantId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => resetTenantPasswordMutation.mutate()}
              isDisabled={resetTenantPasswordMutation.isLoading}
            >
              {resetTenantPasswordMutation.isLoading
                ? "Resetting..."
                : "Accept"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
