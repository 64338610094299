import React from "react";
import { Text, Wrap, WrapItem } from "@chakra-ui/react";

import { useUnitGroupsQuery } from "src/routes/UnitGroups/queries";
import { Loading } from "src/common/Loading";
import { UnitGroupCard } from "src/routes/UnitGroups/UnitGroupCard";

export const UnitGroupList = () => {
  const query = useUnitGroupsQuery();

  if (query.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Wrap spacing={3}>
        {query.data?.result.map((unitGroup) => (
          <WrapItem key={unitGroup.unitGroupId} alignItems={"stretch"}>
            <UnitGroupCard
              unitGroup={unitGroup}
              minWidth={300}
              maxWidth={400}
            />
          </WrapItem>
        ))}
        {!query.isLoading && !query.data?.result.length ? (
          <WrapItem>
            <Text>No Unit Groups</Text>
          </WrapItem>
        ) : null}
      </Wrap>
    </>
  );
};
