import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { UnitList } from "src/routes/Unit/UnitList";
import { EditUnit } from "src/routes/Unit/EditUnit";
import { AuthGate } from "src/common/AuthGate";
import { Logout } from "src/routes/Logout";
import { AppLayout } from "src/layout/AppLayout";
import { PropertyOrganizationSelector } from "src/routes/PropertyOrganizationSelector";
import { PropertyOrganizationLayout } from "src/layout/PropertyOrganizationLayout";
import { PropertyStaffList } from "src/routes/PropertyStaffList";
import { TenantList } from "src/routes/TenantList";
import { AddTenant } from "src/routes/AddTenant";
import { EditTenant } from "src/routes/EditTenant";
import { AddPropertyStaffMember } from "src/routes/AddPropertyStaffMember";
import { Profile } from "src/routes/Profile";
import { ProfileEdit } from "src/routes/ProfileEdit";
import { EulaAgreementWall } from "src/routes/EulaAgreementWall";
import { EditPropertyUser } from "src/routes/EditPropertyUser";
import { AccessLogs } from "src/routes/AccessLogs";
import { IntercomUnits } from "src/routes/IntercomUnits";
import { IntercomUnitsLoader } from "src/routes/IntercomUnits/IntercomUnitsLoader";
import { EditSchedule } from "src/routes/IntercomUnits/EditSchedule";
import { EditDefaultMobileIntercom } from "src/routes/IntercomUnits/ViewIntercomUnit/EditDefaultMobileIntercom/EditDefaultMobileIntercom";
import { GlobalErrorBoundary } from "src/common/ErrorBoundary/GlobalErrorBoundary";
import { usePageTracking } from "src/common/usePageTracking";
import "react-datepicker/dist/react-datepicker.css";
import { GateHawkUserLoader } from "src/common/GateHawkUserLoader";
import { AdminRoleGate } from "src/common/AdminRoleGate";
import { Dealer } from "src/routes/Dealer";
import { ViewTenant } from "src/routes/ViewTenant";
import { TenantGuestsList } from "src/routes/TenantGuestsList";
import { CellUpgradeKit } from "src/routes/IntercomUnits/ViewIntercomUnit/CellUpgradeKit";
import { EditIntercomSplashScreen } from "src/routes/IntercomUnits/ViewIntercomUnit/EditIntercomSplashScreen";
import { IntercomActivationLoader } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomActivationLoader";
import { IntercomDetails } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomDetails";
import { IntercomSystem } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomSystem";
import { AddUnitForm } from "src/routes/Unit/AddUnitForm";
import { UnitDetail } from "src/routes/Unit/UnitDetail";
import { useFlags } from "src/common/useFlags";
import { AddUnitGroupForm } from "src/routes/UnitGroups/AddUnitGroupForm";
import { EditUnitGroup } from "src/routes/UnitGroups/EditUnitGroup";
import { UnitGroupDetails } from "src/routes/UnitGroups/UnitGroupDetails";
import { AddProfile } from "src/routes/AccessControl/AddProfile";
import { EditProfile } from "src/routes/AccessControl/EditProfile";
import { AddEntrySchedule } from "src/routes/AccessControl/AddEntrySchedule";
import { EditEntrySchedule } from "src/routes/AccessControl/EditEntrySchedule";
import { AccessControl } from "src/routes/AccessControl";
import { PropertyGuestsList } from "src/routes/PropertyGuestsList";
import { AddPropertyGuest } from "src/routes/PropertyGuestsList/AddPropertyGuest";
import { EditPropertyGuest } from "src/routes/PropertyGuestsList/EditPropertyGuest";
import { ViewPropertyGuest } from "src/routes/PropertyGuestsList/ViewPropertyGuest";

function App() {
  usePageTracking();

  const { user } = useAuth0();
  const ldClient = useLDClient();

  if (user) {
    ldClient?.identify({
      kind: "user",
      key: user.sub,
    });
  }

  const flags = useFlags();

  return (
    <GlobalErrorBoundary>
      <Routes>
        <Route element={<AuthGate />}>
          <Route
            index
            element={<Navigate to="/property-organizations" replace />}
          />
          <Route element={<GateHawkUserLoader />}>
            <Route
              path="/property-organizations"
              element={<PropertyOrganizationSelector />}
            >
              <Route element={<EulaAgreementWall />}>
                <Route element={<PropertyOrganizationLayout />}>
                  <Route path=":propertyOrganizationId">
                    <Route index element={<Navigate to="profile" replace />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="profile/edit" element={<ProfileEdit />} />
                    <Route path="staff">
                      <Route index element={<PropertyStaffList />} />
                      <Route element={<AdminRoleGate />}>
                        <Route
                          path=":propertyUserId/edit"
                          element={<EditPropertyUser />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="staff/add"
                      element={<AddPropertyStaffMember />}
                    />
                    <Route path={"guests"}>
                      <Route index element={<PropertyGuestsList />} />
                      <Route path="add" element={<AddPropertyGuest />} />
                      <Route
                        path=":guestId/edit"
                        element={<EditPropertyGuest />}
                      />
                      <Route path=":guestId" element={<ViewPropertyGuest />} />
                    </Route>
                    <Route path="units">
                      <Route index element={<UnitList />} />
                      <Route path="add" element={<AddUnitForm />} />
                      <Route path=":unitId">
                        <Route index element={<UnitDetail />} />
                        <Route path="edit" element={<EditUnit />} />
                        <Route path="tenants">
                          <Route path="add" element={<AddTenant />} />
                          <Route path=":tenantId" element={<ViewTenant />} />
                          <Route
                            path=":tenantId/edit"
                            element={<EditTenant />}
                          />
                        </Route>
                      </Route>
                      <Route path="tenants">
                        <Route index element={<TenantList />} />
                        {!flags.units ? (
                          <Route path="add" element={<AddTenant />} />
                        ) : null}
                        <Route path=":tenantId" element={<ViewTenant />} />
                        <Route path=":tenantId/edit" element={<EditTenant />} />
                        <Route path="guests">
                          <Route index element={<TenantGuestsList />} />
                        </Route>
                      </Route>
                      <Route path="unit-groups">
                        <Route path="add" element={<AddUnitGroupForm />} />
                        <Route path=":unitGroupId">
                          <Route index element={<UnitGroupDetails />} />
                          <Route path="edit" element={<EditUnitGroup />} />
                        </Route>
                      </Route>
                    </Route>
                    {flags.accessControl ? (
                      <Route path="access-control">
                        <Route index element={<Navigate to={"profiles"} />} />
                        <Route path={"profiles"}>
                          <Route
                            index
                            element={<AccessControl tab={"profiles"} />}
                          />
                          <Route path="add" element={<AddProfile />} />
                          <Route
                            path=":accessProfileId/edit"
                            element={<EditProfile />}
                          />
                        </Route>
                        <Route path={"entry-points"}>
                          <Route
                            index
                            element={<AccessControl tab={"entry-points"} />}
                          />
                        </Route>
                        <Route path={"entry-schedules"}>
                          <Route
                            index
                            element={<AccessControl tab={"entry-schedules"} />}
                          />
                          <Route path="add" element={<AddEntrySchedule />} />
                          <Route
                            path=":entryScheduleId/edit"
                            element={<EditEntrySchedule />}
                          />
                        </Route>
                        <Route path={"access-panel"}>
                          <Route
                            index
                            element={<AccessControl tab={"access-panel"} />}
                          />
                        </Route>
                        <Route path={"settings"}>
                          <Route
                            index
                            element={<AccessControl tab={"settings"} />}
                          />
                        </Route>
                      </Route>
                    ) : null}

                    <Route path="access-logs" element={<AccessLogs />} />
                    <Route
                      path="intercom-units"
                      element={<IntercomUnitsLoader />}
                    >
                      <Route index element={<IntercomUnits />} />
                      <Route
                        path=":activationId"
                        element={<IntercomActivationLoader />}
                      >
                        <Route path="details" element={<IntercomDetails />} />
                        <Route path="cell-kits" element={<CellUpgradeKit />} />
                        <Route
                          path="splash-screen"
                          element={<EditIntercomSplashScreen />}
                        />
                        <Route path="system" element={<IntercomSystem />} />
                        <Route
                          path="dmi"
                          element={<EditDefaultMobileIntercom />}
                        />
                        <Route path="schedule" element={<EditSchedule />} />
                      </Route>
                    </Route>
                    <Route path="dealer" element={<Dealer />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<AppLayout />}>
            <Route path="/logout" element={<Logout />} />
          </Route>
        </Route>
      </Routes>
    </GlobalErrorBoundary>
  );
}

export default App;
