import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { PropertyUser } from "src/common/types";
import { useKy } from "src/common/ky";

export const getPropertyUsersQueryKey = (searchParams: URLSearchParams) => {
  searchParams.sort();

  return ["GET_PROPERTY_USERS", searchParams.toString()];
};
export const useGetPropertyUsersQuery = () => {
  const location = useLocation();
  const { propertyOrganizationId } = useParams();
  const ky = useKy();
  const searchParams = new URLSearchParams(location.search);

  if (propertyOrganizationId) {
    searchParams.set("propertyOrganizationId", propertyOrganizationId);
  }

  searchParams.sort();

  return useQuery<PropertyUser[]>(getPropertyUsersQueryKey(searchParams), () =>
    ky
      .get(`property-users`, {
        searchParams,
      })
      .json<PropertyUser[]>()
  );
};
