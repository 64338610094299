import React, { useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Select,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";

import { PageContent } from "src/layout/PageContent";
import {
  PageHeader,
  PageHeaderBackLink,
  PageHeaderTitle,
} from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { HTTPError, useKy } from "src/common/ky";
import { getUnitQueryKey, useGetUnitQuery } from "src/routes/Unit/queries";
import { noop } from "src/common/util";
import { useUnitGroupsQuery } from "src/routes/UnitGroups/queries";
import { handleHookFormHTTPError } from "src/common/form";

interface UnitValues {
  name: string;
  unitGroupId: string;
}

type UnitMutationValues = {
  name: string;
  unitGroupId: string | null;
};

const UnitValidationSchema = Yup.object().shape({
  name: Yup.string().required("Unit Name is required"),
});

export const EditUnit = () => {
  const { propertyOrganizationId, unitId } = useParams();
  const query = useGetUnitQuery();
  const unitGroupsQuery = useUnitGroupsQuery({ pageSize: 999 });
  const queryClient = useQueryClient();

  const ky = useKy();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
    getValues,
  } = useForm<UnitValues>({
    resolver: yupResolver(UnitValidationSchema),
    defaultValues: {
      name: "",
      unitGroupId: "",
    },
  });

  const editUnit = useMutation<void, HTTPError, UnitMutationValues>(
    async (values: UnitMutationValues) => {
      await ky.put(`units/${unitId}`, {
        json: { ...values },
      });
    },
    {
      onSuccess: () => {
        toast({
          title: "Unit updated",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries(getUnitQueryKey(unitId));
        navigate(`/property-organizations/${propertyOrganizationId}/units`);
      },
      onError: handleHookFormHTTPError(setError, getValues, toast),
    }
  );

  useEffect(() => {
    if (query.data) {
      reset({
        name: query.data.unitName,
        unitGroupId: query.data.unitGroupId,
      });
    }
  }, [query.data, reset]);

  if (query.isLoading || !query.data) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader title="Edit Unit">
        <PageHeaderBackLink>
          <Link
            as={RouterLink}
            to={`/property-organizations/${propertyOrganizationId}/units`}
          >
            &#8249; Back to list
          </Link>
        </PageHeaderBackLink>
        <PageHeaderTitle>Edit Unit</PageHeaderTitle>
      </PageHeader>

      <PageContent>
        <form
          onSubmit={handleSubmit((data) => {
            editUnit
              .mutateAsync({
                ...data,
                unitGroupId: data.unitGroupId ? data.unitGroupId : null,
              })
              .catch(noop);
          })}
        >
          <Grid gap={6} width={["100%", "100%", "50%", "50%"]}>
            <GridItem>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <FormControl isInvalid={!!errors.name} isRequired>
                    <FormLabel htmlFor="name">Unit Name</FormLabel>
                    <Input {...field} id="name" />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
            <GridItem>
              <Controller
                name="unitGroupId"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!errors.unitGroupId}>
                    <FormLabel htmlFor="unitGroupId">
                      Select a Unit Group
                    </FormLabel>
                    <Select {...field} id="unitGroupId">
                      <option value={""}></option>
                      {unitGroupsQuery.data?.result.map((ug) => (
                        <option key={ug.unitGroupId} value={ug.unitGroupId}>
                          {ug.unitGroupName}
                          {ug.unitCount != null
                            ? ` (${ug.unitCount} Unit${
                                ug.unitCount === 1 ? "" : "s"
                              })`
                            : ""}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </GridItem>
          </Grid>

          <Flex justifyContent={"flex-end"}>
            <ButtonGroup>
              <Button
                type="submit"
                colorScheme="brand.blue"
                isLoading={isSubmitting}
              >
                Save
              </Button>
              <Link
                as={RouterLink}
                to={`/property-organizations/${propertyOrganizationId}/units`}
              >
                <Button>Cancel</Button>
              </Link>
            </ButtonGroup>
          </Flex>
        </form>
      </PageContent>
    </>
  );
};
