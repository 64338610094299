import React, { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineDotsVertical,
  HiOutlineEye,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import { PageContent } from "src/layout/PageContent";
import { PageHeader, PageHeaderTitle } from "src/layout/PageHeader";
import { Loading } from "src/common/Loading";
import { useDeletePropertyGuest } from "src/routes/TenantGuestsList/hooks";
import { formatDate } from "src/common/formatters";
import { PropertyGuestsListFilterBar } from "src/routes/PropertyGuestsList/PropertyGuestsListFilterBar";
import { usePropertyGuestsQuery } from "src/routes/PropertyGuestsList/queries";

export const PropertyGuestsList = () => {
  const { propertyOrganizationId } = useParams();
  const query = usePropertyGuestsQuery();
  const navigate = useNavigate();

  const { isDeletingGuest, deleteGuestMutation, setDeletingGuestId } =
    useDeletePropertyGuest();

  useEffect(() => {
    const newSp = new URLSearchParams(location.search);
    newSp.set("tenantOrPropertyGuest", "property");
    newSp.sort();

    if (newSp.toString()) {
      navigate(`?${newSp.toString()}`);
    }
  }, []);

  let contents;
  if (query.isLoading || !query.data) {
    contents = <Loading />;
  } else if (!query.data.length) {
    contents = <Flex>There are no guests.</Flex>;
  } else {
    contents = (
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Company</Th>
            <Th>Added By</Th>
            <Th>Access Date Range</Th>
            <Th>Schedule Type</Th>
            <Th>Status</Th>
            <Th>{/*menu column*/}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {query.data.map((guest) => (
            <Tr key={guest.guest_id}>
              <Td>{guest.name}</Td>
              <Td>{guest.company_name}</Td>
              <Td>{guest.added_by}</Td>
              <Td>{`${formatDate(
                guest.schedule_start_date,
                "short"
              )} -  ${formatDate(guest.schedule_end_date, "short")}`}</Td>
              <Td style={{ textTransform: "capitalize" }}>
                {guest.schedule_type}
              </Td>
              <Td>{guest.expired ? "Expired" : "Active"}</Td>
              <Td>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Actions"
                    icon={<HiOutlineDotsVertical />}
                    variant="clear"
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      justifyContent={"space-between"}
                      onClick={() =>
                        navigate(
                          `/property-organizations/${propertyOrganizationId}/guests/${guest.guest_id}`
                        )
                      }
                    >
                      View Guest
                      <HiOutlineEye />
                    </MenuItem>

                    <MenuItem
                      justifyContent={"space-between"}
                      onClick={() =>
                        navigate(
                          `/property-organizations/${propertyOrganizationId}/guests/${guest.guest_id}/edit`
                        )
                      }
                    >
                      Edit Guest
                      <HiOutlinePencil />
                    </MenuItem>
                    <MenuItem
                      justifyContent="space-between"
                      onClick={() => {
                        setDeletingGuestId(guest.guest_id);
                      }}
                    >
                      Delete Guest
                      <HiOutlineTrash />
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  return (
    <>
      <PageHeader
        buttons={
          <ButtonGroup>
            <PropertyGuestsListFilterBar />

            <Button
              colorScheme={"brand.blue"}
              onClick={() =>
                navigate(
                  `/property-organizations/${propertyOrganizationId}/guests/add`
                )
              }
            >
              Add Property Guest
            </Button>
          </ButtonGroup>
        }
      >
        <PageHeaderTitle>Property Guests</PageHeaderTitle>
      </PageHeader>
      <PageContent>{contents}</PageContent>

      <Modal
        isOpen={isDeletingGuest}
        onClose={() => {
          setDeletingGuestId(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Guest</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand.lightGray"
              color="gray.700"
              mr={3}
              onClick={() => {
                setDeletingGuestId(undefined);
              }}
            >
              Close
            </Button>
            <Button
              colorScheme="brand.red"
              onClick={() => deleteGuestMutation.mutate()}
              isDisabled={deleteGuestMutation.isLoading}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
