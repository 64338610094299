import {
  Grid,
  GridItem,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";
import { useOutletContext } from "react-router-dom";

import { Loading } from "src/common/Loading";
import { useProductActivationComponentsQuery } from "src/common/queries";
import { IntercomActivation, IntercomGeneration } from "src/common/types";
import { formatPhoneNumber } from "src/common/phone-numbers";
import { IntercomComponents } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomDetails/IntercomComponents";
import { ViewIntercomUnitLayout } from "src/routes/IntercomUnits/ViewIntercomUnit/ViewIntercomUnitLayout";
import useIntercomDetails from "src/routes/IntercomUnits/ViewIntercomUnit/useIntercomDetails";
import { IntercomSupportButtons } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomDetails/IntercomSupportButtons";
import { UnitGroupAssignmentCard } from "src/routes/IntercomUnits/ViewIntercomUnit/IntercomDetails/components/UnitGroupAssignmentCard";

/**
 * This component handles viewing basic intercom details.
 */
export const IntercomDetails: FC = () => {
  const { currentActivation } =
    useOutletContext<{ currentActivation: IntercomActivation }>();
  const componentsQuery = useProductActivationComponentsQuery();

  const { generation } = useIntercomDetails();
  const isGen4Plus =
    currentActivation && generation !== IntercomGeneration.GEN_3;

  if (
    !currentActivation ||
    componentsQuery.isLoading ||
    !componentsQuery.data
  ) {
    return <Loading />;
  }

  const {
    cameraPreviewLastUpdated,
    cameraPreviewUrl,
    dealerContactInfo,
    intercomModel,
    lat,
    long,
    name,
    serialNumber,
    softwareVersion,
  } = currentActivation;

  return (
    <ViewIntercomUnitLayout
      title={name}
      subtitle="View product model information, intercom coordinates, and contact a dealer"
    >
      <Grid gridTemplateColumns="1fr 1.5fr" gap={2}>
        <GridItem>
          <Stack>
            <Heading>Unit Group</Heading>
            <UnitGroupAssignmentCard
              intercomActivationId={currentActivation.intercomActivationId}
            />
          </Stack>

          <Spacer h={30} />

          <Stack>
            <Heading>Product Model</Heading>
            <Text>{intercomModel}</Text>
            <Text>SN: {serialNumber}</Text>
            {isGen4Plus && (
              <Text>
                Intercom software release: {softwareVersion ?? "Unknown"}
              </Text>
            )}
          </Stack>

          <Spacer h={30} />

          {isGen4Plus && (
            <>
              <Stack>
                <Heading>Intercom Coordinates</Heading>
                <Text>{lat && long ? `${lat}, ${long}` : "Unknown"}</Text>
              </Stack>
              <Spacer h={30} />
            </>
          )}

          <Stack>
            <Heading>Dealer Contact Information</Heading>
            {!dealerContactInfo ? (
              <Text>Unknown</Text>
            ) : (
              <>
                <Text>{dealerContactInfo.name}</Text>
                <Text>{dealerContactInfo.address}</Text>
                <Text>{formatPhoneNumber(dealerContactInfo.phoneNumber)}</Text>
                <Link href={dealerContactInfo.website} isExternal>
                  {dealerContactInfo.website}
                </Link>
              </>
            )}
          </Stack>

          <Spacer h={30} />

          {isGen4Plus && (
            <>
              <IntercomComponents components={componentsQuery.data} />
              <Spacer h={43} />
              <IntercomSupportButtons intercomActivation={currentActivation} />
            </>
          )}
        </GridItem>
        {isGen4Plus && (
          <GridItem>
            <Stack mx={4}>
              <Heading>Camera Preview</Heading>
              {cameraPreviewUrl ? (
                <>
                  <Image
                    src={cameraPreviewUrl}
                    width={600}
                    height={350}
                    sx={{ borderRadius: 6 }}
                    alt="Camera Preview"
                  />
                  <Text as="i" fontSize="sm" color="brand.gray.500">
                    Last Updated:{" "}
                    {cameraPreviewLastUpdated
                      ? new Date(cameraPreviewLastUpdated).toString()
                      : new Date().toString()}
                  </Text>
                </>
              ) : (
                <Text>No camera preview available</Text>
              )}
            </Stack>
          </GridItem>
        )}
      </Grid>
    </ViewIntercomUnitLayout>
  );
};

const Heading = ({ children }: { children: ReactNode }) => (
  <Text fontSize="xl" fontWeight="extrabold">
    {children}
  </Text>
);
